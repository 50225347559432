<template>
    <div :class="`${mainClass} w-full flex ${isVert ? 'flex-col' : 'flex-row'} items-center justify-center`">
        <div :class="`${opacity}${isVert ? 'w-full mb-2' : ''} max-w-screen-lg flex ${isVert ? 'flex-col' : 'flex-row'} items-center justify-start w-full p-2`">
            <div :class="`${isVert ? 'mb-2 w-full' : ''} flex flex-row items-center`">
                <div @click="clickNotice()" :class="`${mainClass}-alt ${isVert ? 'w-full' : ''}  ${url ? 'cursor-pointer' : 'cursor-default'}  p-1 uppercase text-xl whitespace-nowrap rounded-sm m-1 flex flex-row items-center`">
                    <Icon :id="iconUse" class="h-6 w-6 mr-1 flex-shrink-0" />
                    <span class="font-bold tracking-tighter mr-1">{{title}}</span>
                    <Icon id="arrow-top-right-on-square" v-if="url" class="h-4 w-4 ml-0 mr-0" />
                </div>
                <Icon v-if="showClose" @click="this.$emit('close')" 
                    class="base md:hidden h-8 w-8 cursor-pointer opacity-90 hover:opacity-100 duration-100" id="x" />
            </div>
            <div :class="`${opacity} leading-4 ml-2`">{{notice}}</div>
        </div>    
        <Icon v-if="!isVert && showClose" @click="this.$emit('close')" 
            class="base h-8 w-8 cursor-pointer opacity-90 hover:opacity-100 duration100" id="x" />
    </div>
</template>

<script>
import { ref } from 'vue'

import Icon from "@/components/Icon.vue";

export default {
    name: "Notice",   
    components: {
        Icon
    },
    computed: {
        iconUse(){
            if(this.$props.icon) return this.$props.icon;

            switch(this.$props.level){
                case 'news':
                default:
                    return 'newspaper';

                case 'alert':
                    return 'exclamation';

                case 'notice':
                    return 'exclamation';
            }            
        },
        mainClass(){
            switch(this.$props.level){
                case 'news':
                default:
                    return 'mn3-approved';

                case 'alert':
                    return 'mn3-alert';

                case 'notice':
                    return 'mn3-highlight';
            }
        },
        opacity(){
            if(this.$props.url)
                return 'opacity-90 hover:opacity-100 duration-100'

            return '';
        }
    },
    methods:{
        clickNotice(){
            if(this.$props.url)
                window.open(this.$props.url);
        }
    },
    props:{
        title: String,
        url: String,
        icon: String,
        isVert: Boolean,
        level: String,
        notice: String,
        showClose: Boolean
    },
    setup(){      
        
        return{
        }
    }
}

</script>
