<template>
    <div>
        <div v-for="msg in messages" :key="msg" 
            @click="messages = []" class="bg-white border-gray-300 border rounded p-3 text-sm flex items-center mb-4 cursor-pointer">
            <Icon id="exclamation" class="w-6 h-6 mr-1 flex-shrink-0" />
            <p>{{msg.message}}</p>
        </div>
        <Form v-if="step == 'code'"
            ref="refFormCode"
            submitText="Confirm"
            :showCancel="true"
            @submit="submitConfirm"
            @cancel="step = 'credentials'"
            title="Login Confirmation">
            <div class="flex flex-col text-sm">
                <div class="flex items-center border-b p-3"><div class="w-16 mr-3"><Icon id="lock" /></div><p>It looks like you are logging in from a new browser or a new computer.</p></div>
                <p class="mt-2 p-3">As an additional security measure you'll need to enter a code that has been sent to your email on file.</p>
                <p class="text-center m-1 p-1 rounded-sm mn3-content-alt">This email will come from noreply@cucinc.net</p>
            </div>
            <Field type="text" 
                ref="refConfirmCode"
                class="text-xl"
                label="Confirmation Code" 
                textFormat="number"
                @enter="refFormCode.onSubmit()"
                :formValue="fv.confirmCode"></Field>
        </Form>
        
        <p v-if="step == 'code'" class="mx-0 mn3-button text-xs text-center cursor-pointer mb-2" 
            @click="resendConfirmation">Didn't receive your code?  Try resending the email.</p>
        <Form v-else-if="step == 'register'"
            :showCancel="true"
            cancelText="Do Not Register"
            submitText="Register Device"
            @submit="submitRegisterDevice(true)"
            @cancel="submitRegisterDevice(false)"
            title="Register Device?">      
            
            <div class="flex flex-col text-sm">
                <div class="flex items-center border-b p-3">
                    <div class="w-16 mr-3"><Icon id="lock-open" /></div>
                    <p>Registered devices don't require you to enter a confirmation code on every login.</p>
                </div>
            </div>
        </Form>
        <Form v-else-if="step == 'update_password'"
            :showCancel="false"
            submitText="Update Password"
            @submit="submitUpdatePassword(true)"
            title="Update Password">     
            <div v-if="passError != ''" class="p-2 mn3-alert text-center">{{passError}}</div>
                <div class="flex flex-col text-sm">
                <div class="flex items-center border-b p-3"><div class="w-16 mr-3"><Icon id="lock" /></div>
                <p>As an additional security measure you'll need to update your password</p></div>
            </div> 
            <Field 
                label="Password" 
                type="password" 
                :formValue="fv.pass1"/>
            <Field 
                label="Confirm Password" 
                type="password" 
                :formValue="fv.pass2"/>
            <PasswordChecker 
                ref="passwordChecker" 
                :pass1="fv.pass1.value" 
                :pass2="fv.pass2.value" 
                :minLength="reqs.minLength"
                :minUpper="reqs.minUpper"
                :minLower="reqs.minLower"
                :minDigits="reqs.minDigits"
                :minSpecial="reqs.minSpecial"
                :specialChars="reqs.specialChars"
                class="mx-2" /> 
        </Form>
        <Form 
            v-else-if="showForgotName"
            title="Forgot Login Name"
            submitText="Send Reminder"
            :showReset="false"
            :showCancel="true"
            @cancel="showForgotName = false"
            @submit="forgotName">
            <Field type="text" label="Email" @enter="forgotName" :formValue="fv.forgotName_email"></Field>
        </Form>
        <Form 
            v-else-if="showForgotPass"
            title="Forgot Password"
            submitText="Send Reminder"
            :showReset="false"
            :showCancel="true"
            @cancel="showForgotPass = false"
            @submit="forgotPass">
            <Field type="text" label="Username" @enter="forgotPass" :formValue="fv.forgotPass_name"></Field>
        </Form>
        <Form 
            ref="refFormCredentials"
            v-else-if="step == 'credentials'"
            :title="formTitle"
            submitText="Login"
            :showCancel="false"
            :showBio="bioEnabled"
            :bioMode="bioMode"
            @bio="bioLogin"
            @reset="resetLogin"
            @submit="submitCreds">
            <div v-if="bioError != ''" class="p-2 mn3-alert text-center">{{bioError}}</div>
            <Field type="text" 
                label="Username" 
                :formValue="fv.loginName"
                @enter="refLoginPass.focus()">
                <div @click="showForgotName = true;setAlert('')" class="hidden p-1 text-xs mn3-required cursor-pointer rounded hover:bg-white w-max">Forgot Username</div>
            </Field>
            <Field 
                ref="refLoginPass"
                type="password" 
                label="Password" 
                :formValue="fv.loginPass" 
                @enter="refFormCredentials.onSubmit()">
                <div @click="startForgotPass" 
                    class="p-1 text-xs mn3-required cursor-pointer rounded hover:bg-white w-max">Forgot Password</div>
            </Field>
        </Form>
        <div v-if="bioSaved && !bioConfirmDelete"
            class="w-full p-1 mb-5">
            <div 
            :class="`mn3-cancel rounded cursor-pointer w-full p-2 text-sm text-center flex flex-row justify-center items-center`"
            @click="bioConfirmDelete = true"><Icon id="fingerprint" class="h-6 w-6 mr-2" /><p>Delete Saved Login Credentials</p></div>
        </div>
        <div v-if="bioConfirmDelete"
            class="w-full p-1 mb-5">

            <div class="flex flex-col mn3-content p-2 w-full text-sm text-center">
                <p class="mb-1 font-bold">Delete any credentials saved to fingerprint login?</p>
                <div class="flex flex-row w-full justify-around">
                    <Button 
                    size="large"
                    type="cancel"
                    @click="bioDelete()"
                    label="Delete" />
                <Button 
                    size="large"
                    @click="bioConfirmDelete = false"
                    label="Do Not Delete" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
        
    import { BiometryType, NativeBiometric } from "@capgo/capacitor-native-biometric";
    
    import Form from '@/components/Form.vue'
    import Field from '@/components/Field.vue'
    import Button from '@/components/Button.vue'
    import Icon from '@/components/Icon.vue'
    import PasswordChecker from '@/components/PasswordChecker.vue'
    
    export default {
      name: 'LoginComp',
      components: {
            Button,
          Form,
          Field,
          Icon,
          PasswordChecker
        },
        created(){
            document.title="Login";

            //check if device is bio enabled
            this.checkBioLoginStatus();
        },
    props:{   
        formTitle:{
            type: String,
            default: "Member Login"
        },
      mode: {                       
          type: String,
          default: "members"
      },
    },
     methods: {
        checkBioLoginStatus(){

            NativeBiometric.isAvailable().then(
                (result) =>{
                    
                    let isAvailable = result.isAvailable;
                    if(isAvailable){
                        this.bioEnabled = true
                        if(result.biometryType == BiometryType.FACE_ID || result.biometryType == BiometryType.FACE_AUTHENTICATION || result.biometryType == BiometryType.MULTIPLE){
                            this.bioMode = 'camera';
                        }else{
                            this.bioMode = 'fingerprint';
                        }

                        //Check if there are saved credentials
                        NativeBiometric.getCredentials({
                            server: this.bioServer,
                        }).then((credentials) => {
                                this.bioSaved = true;
                        }).catch((error) => {
                                this.bioSaved = false;
                    });


                    }else{
                        this.bioEnabled = false
                        this.bioMode = 'disabled';
                        this.bioSaved = false
                    }
                    
                },
                (error) => {
                    this.bioEnabled = false
                    this.bioMode = 'disabled';
                    this.bioSaved = false
                }
            );
        },
        bioLogin(){

            this.bioError = '';
            NativeBiometric.isAvailable().then(
                (result) =>{
                    let isAvailable = result.isAvailable;
                    let isFaceId = result.biometryType == BiometryType.FACE_ID;

                    if(isAvailable){

                            //get users creds
                            NativeBiometric.getCredentials({
                                server: this.bioServer,
                            }).then((credentials) => {
                                //auth using bio before logging in user
                                NativeBiometric.verifyIdentity({
                                    reson: "Easier Login",
                                    title: "Member Login"
                                }).then(
                                    () => {
                                        //auth good
                                        this.fv.loginName.value = credentials.username
                                        this.fv.loginPass.value = credentials.password
                                        this.submitCreds();
                                    },
                                    (error) =>{
                                        //auth failed
                                        console.log('Bio Auth Failed: ', error)
                                        this.bioError = error;
                                    }
                                );

                            }).catch((error) => {
                            // No details saved, check if details entered to save to bio
                                        console.log('error: ', error)
                                        
                                if(this.fv.loginName.value != '' && this.fv.loginPass.value != ''){
                                    this.saveBio = true;
                                    this.submitCreds();
                                }else{
                                    this.bioError = 'No credentials saved.  Enter username/password and hit the fingerprint button to allow logging in with your fingerprint in the future.';
                                }
                        });
                    }
                },
                (error) =>{
                    console.log('Bio error: ', error);
                }
            )

        },
        async forgotPass(){
            let data = {data: { attributes: {
                username: this.fv.forgotPass_name.value
            }}};
            try{
                let resp = await this.$authapi.post(window.mn3_config.fiPath + 'members/forgot-password', data);
                this.messages = [{message: 'Instructions to reset your password will be sent to the email on that account.'}]
                this.showForgotPass = false
            }catch(resp){
                this.messages = [{message: 'Instructions to reset your password will be sent to the email on that account.'}]
                this.showForgotPass = false
            }
        },
        resendConfirmation(){
            let data = {
                    state: this.state,
                    step: this.step,
                    data: {
                        code: '',
                        resend: true
                    }
            };                        
            this.$authapi.authStep(this.mode, data).then(this.messages.push({message:'Code has been resent to your email address on file.'})).catch(this.onError);       
        },
        startForgotPass(){
            if(this.externalPasswordReset){
                this.openForgotPass()
            }else{
                this.showForgotPass = true
            }
        },
        async submitCreds(){            

            this.bioError = '';  
            this.fv.confirmCode.value = ''          
						//check for staging/production change
						let username = this.fv.loginName.value;
						let password = this.fv.loginPass.value;
                        if(username == "__staging" && password == "__staging"){
                            localStorage.setItem('use_staging', 1);
                            localStorage.setItem('update_app_expire', 0);
                            console.log('Set Staging: ', localStorage.getItem('use_staging'), localStorage.getItem('use_staging_time'));
                            
                            location.reload();
                            return;
                        }else if(username == "__production" && password == "__production"){
                            localStorage.setItem('use_staging', 0);
                            localStorage.setItem('update_app_expire', 0);
                            console.log('Set Production: ', localStorage.getItem('use_staging'), localStorage.getItem('use_staging_time'));
                            location.reload();
                            return;
                        }
            let data = {
                    data: {
                        username: this.fv.loginName.value,
                        password: this.fv.loginPass.value
                        }
            };                      
            
            try{
                let resp = await this.$authapi.authStep(this.mode, data);
                this.onResponse(resp);
            }catch(er){                
                this.messages = [{message: `${this.$store.state.fi.title} Online Banking is currently unavailable, please try again later. If the problem persists, contact us at ${this.$store.state.fi.phone} .`}]
                this.onError(er);
            }

        },
        resetCooldown(){
            this.onCooldown = false;
        },
        submitConfirm(){
            let data = {
                    state: this.state,
                    step: this.step,
                    data: {
                        code: this.fv.confirmCode.value,
                    }
            };                        
            this.$authapi.authStep(this.mode, data).then(this.onResponse).catch(this.onError);            
        },
        submitRegisterDevice(register){

            console.log('submitRegisterDevice');

            if(!this.onCooldown){
                this.emit("cancel");        
                this.onCooldown = true;
                clearTimeout(this.cooldown);
                this.cooldown = setTimeout(this.resetCooldown, this.cooldownTime * 1000);
                    
                let data = {
                        state: this.state,
                        step: this.step,
                        data: {
                            register: register 
                            }
                };            
                this.$authapi.authStep(this.mode, data).then(this.onResponse).catch(this.onError);  
            }else{
                console.log('submitRegisterDevice on cooldown');
            }
                      
        },
        submitUpdatePassword(){
            console.log('submitUpdatePassword');
            if(!this.passwordChecker.isValidPass()){
                this.passError="A valid password is required"
            }else{
                this.passError = '';
                let data = {
                        state: this.state,
                        step: this.step,
                        data: {
                            password: this.fv.pass1.value
                        }
                }; 
                this.$authapi.authStep(this.mode, data).then(this.onResponse).catch(this.onError);     
            }
        },
        bioDelete(){
            NativeBiometric.deleteCredentials({
            server: this.bioServer,
            }).then(
                () =>{       
					location.reload();  
                }
            );
        },
        async onResponse(r){
            let redirectTo = '';

            if(this.mode == 'members'){
                redirectTo = '/accounts';
            }else if(this.mode == 'admin'){
                //If user only has permission boardpacket-read, redirect to viewing packets
                if(this.$store.state.permissions['boardpacket-read'] && Object.keys(this.$store.state.permissions).length == 1){
                    redirectTo = '/admin/boarddocs';
                }
                //Otherwise go to dash
                else{
                    redirectTo = '/admin/dash';
                }
            }
            
            if(r.data.status == "authenticated"){

                //Clear focused related account
                localStorage.removeItem('membership_id');

                if(this.saveBio){

                    NativeBiometric.setCredentials({
                    username: this.fv.loginName.value,
                    password: this.fv.loginPass.value,
                    server: this.bioServer,
                    }).then(
                        () =>{       
                            if(redirectTo != '')
                                this.$router.push(redirectTo)       
                        }
                    );
                }
                else{
                    if(redirectTo != '')
                        this.$router.push(redirectTo)
                }
                return;
            }
            this.step = r.data.step;
            if(this.step == 'update_password'){
                this.reqs = r.data.requirements
            }
            this.state = r.data.state;
            this.messages = r.data.messages;
            //If confirmation code, focus that input
            //Short delay so input has time to be rendered
            await new Promise(resolve => setTimeout(resolve, 5));  
            if(this.step == 'code'){
                console.log('refConformCode.focus');
                this.refConfirmCode.focus();
            }

            //this.emit(this.step)
        },
        onError(r){
                console.log('onError', r);
        },
        logout(){            
    	this.$router.push('/logout');
        }      
    },
      setup( props, { emit }){
    
          let store = useStore();

          const passwordChecker = ref(null)

          const refConfirmCode = ref(null)
          const refLoginPass = ref(null)
          const refFormCredentials = ref(null)
          const refFormCode = ref(null)
          const bioEnabled = ref(true);
          const bioSaved = ref(false)
          const bioMode = ref('disabled');
          const saveBio = ref(false)                          //saveBio: If credentials should be saved to bio after successful login
          const bioConfirmDelete = ref (false)
          const passError = ref('')
          const externalPasswordReset = ref(store.state.fi.forgotPassUrl != undefined)

          let fv = {}                                         //fv: Array of refs used to store input
          fv["confirmCode"] = ref("")                         //fv.confirmCode: Entered 2F confirmation code
          fv["loginName"] = ref("")                           //fv.loginName: Login name entered
          fv["loginPass"] = ref("")                           //fv.loginPass: Login pass entered
          fv["forgotName_email"] = ref("")                    //fv.forgotName_email: Email entered on forgot-username form
          fv["forgotPass_name"] = ref("")                     //fv.forgotPass_name: Name enetered on forgot-pass form
          fv["deviceName"] = ref("")                          //fv.deviceName: Optional name to save device by
          fv["bioSave"] = ref("")
          fv["pass1"] = ref("")
          fv["pass2"] = ref("")

            const req = ref({})
            let messages = ref([])
            let step = ref("credentials")                 
            let showConfirmCode = ref(false)                    //showConfirmCode: Show 2D confirm code entry
            let showForgotName = ref(false)                     //showForgotName: Show forgot name form
            let showForgotPass = ref(false)                     //showForgotPass: Show forgot pass form
            let bioError = ref("");
            const bioServer = ref('com.mnolb.id' + store.state.fi.id);

            function openForgotPass(){
                window.open(store.state.fi.forgotPassUrl);
            }

            const cooldownTime = ref(0.5); //in seconds
            const onCooldown = ref(false)
            const cooldown = ref()

            return {
                bioConfirmDelete,
                bioEnabled,
                bioMode,
                bioError,
                bioSaved,
                bioServer,
                cooldownTime,
                onCooldown,
                cooldown,
              emit,
              externalPasswordReset,
              fv,
              messages,
              openForgotPass,
              passError,
              passwordChecker,
              refConfirmCode,
              refLoginPass,
              refFormCode,
              refFormCredentials,
              req,
              saveBio,
              showConfirmCode,
              showForgotName,
              showForgotPass,
              step
            }
      }
    }
</script>