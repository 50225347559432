<!-- Button: Generic styled button used in forms -->
<template>
    <Form :showSubmit="false" title="Board Packets">
        <div class="mn3-content-secondary flex flex-row justify-start p-2 items-center">
            <Icon id="folder" class="h-6 w-6 mr-2" title="View Documents by Folder" />
            <select v-model="viewFolder" class="mn3-input p-1">
                <option value="-1">Viewing All Files</option>
                <option value="">No Folder</option>
                <option v-for="folder in folders" :key="folder" :value="folder">{{folder}}</option>
            </select>
            <Button title="View All Folders" icon="x" v-if="viewFolder != -1" @click="viewFolder=-1" />
        </div>

        <div v-for="folder in displayFolders" :key="folder">
            <div v-if="viewFolder == -1 && folderFiles(folder).length > 0" @click="folder == 'No Folder' ? viewFolder = '' : viewFolder = folder" 
                class="opacity-80 hover:opacity-100 duration-150 cursor-pointer w-full mn3-content-alt flex flex-row items-center gap-x-2 p-2 mt-5"><Icon id="folder" class="h-6 w-6" /><span class="font-bold">{{folder}}</span></div>
            <div v-for="(file, i) in folderFiles(folder)" :key="i" 
                :class="`${deleteId != -1 && deleteId != file.id ? 'hidden' : ''} flex flex-col p-2 mn3-content ${viewFolder != '-1' && viewFolder != file.folder ? 'hidden' : ''}`">

                <Notice v-if="deleteId == file.id" icon="trash" level="alert" title="Delete File" 
                    notice="Are you sure you want to permanently delete this file?" 
                    class="mb-2" />
                <div class="w-full flex flex-row items-center justify-between gap-x-2 leading-4">
                    <input v-if="deleteId == -1 && control" @change="toggleDeleteCheck(file, $event)" type="checkbox" class="h-8 w-8" />
                    <Button v-if="deleteId == -1 && control" icon="trash" style="cancel" title="Delete File" @click="deleteId = file.id" />
                    <Button v-if="!control && file.attributes.scanStatus == 'OK'" icon="arrow-down-tray" title="Download File"  @click="downloadPacket(file)" />
                    <div class="flex flex-col items-start flex-grow">
                        <div title="View Packet" @click="openPacket(file)" 
                            :class="`${file.attributes.scanStatus == 'OK' ? 'cursor-pointer' : 'cursor-default'} leading-5 flex-grow flex flex-row text-lg items-center font-bold text-left opacity-80 hover:opacity-100`">                            
                        <div v-if="file.attributes.scanStatus != 'OK'" :class="`${file.attributes.scanStatus == 'FOUND' ? 'mn3-alert' : 'mn3-content-alt'} text-xs rounded-sm px-1 mr-1 mt-1`">{{scanStatusDisplay(file.attributes.scanStatus)}}</div><span>{{file.attributes.name}}</span>
                            <Icon v-if="file.attributes.status == 'OK'" id="arrow-top-right-on-square" class="h-4 w-4 ml-1 opacity-50  " />
                        </div>
                    </div>
                    <div class="flex flex-col items-end">
                        <div :title="`Uploaded ${formatTime(file.attributes.uploaded)}`" class="flex flex-row items-center">
                            <Icon id="arrow-up-tray" class="h-4 w-4 mr-1" />
                            <span class="cursor-default">{{formatTime(file.attributes.uploaded)}}</span>
                        </div>
                        <div :title="`Packet will be deleted on ${formatDate(file.attributes.uploaded)}`" class="flex flex-row items-center mn3-content-alt rounded-sm px-1 text-sm">
                            <span class="cursor-default italic">Expires {{formatExpDate(file.attributes.uploaded)}}</span>
                        </div>
                    </div>
                </div> 
                <div v-if="deleteId == file.id" class="w-full flex flex-col mt-2">
                    <div class="w-full flex flex-row items-center justify-center">
                        <Button icon="trash" label="Delete File" class="w-1/2" @click="this.$emit('deleteFile', file);deleteId = -1"/>
                        <Button label="Cancel" class="w-1/2" @click="deleteId = -1"/>
                    </div>
                </div>               
            </div>
        </div>
        <div v-if="Object.keys(deleteChecked).length > 0" class="mn3-cancel p-2">
           <div class="w-full flex flex-row items-center justify-center text-lg">
                <Icon id="trash" class="h-6 w-6" />
                <span>Delete {{Object.keys(deleteChecked).length}} selected files?</span>
            </div>
            <div class="w-full flex flex-row items-center justify-center">
                <Button label="Delete Files" class="w-1/2" @click="deleteSelected" />
            </div>
        </div>
    </Form>	
</template>

<script>
	import Icon from "@/components/Icon.vue";
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Notice from "@/components/Notice.vue";

    import { ref } from 'vue'

export default{
    components:{
        Button,
        Form,
        Icon,
        Notice
    },
    props:{   
        control: Boolean,
        files: Object,
        folders: Object
    },
        computed:{
            displayFolders(){
                let folders = ['No Folder'];
                folders.push(...this.$props.folders);
                return folders;
            },
        },
        methods: {
            deleteSelected(){
                this.$emit('deleteFiles', this.deleteChecked);
                this.deleteChecked = {}
            },
            downloadPacket(file){
                window.open(file.links.self);
            },
            folderFiles(folder){
                let files = [];
                for(var ii = 0; ii < this.files.length; ii++){
                    if((this.files[ii].folder == folder || (folder == 'No Folder' && this.files[ii].folder == '')) 
                     && (this.$props.control || this.files[ii].attributes.scanStatus == 'OK'))//If not control, only display files with 'ok' status
                        files.push(this.files[ii]);
                }
                return files;
            },
            formatDate(raw){
                let date = new Date(raw).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit"                
                });
                return date;
            },
            formatExpDate(raw){
                let date = new Date(raw);

                return new Date(date.setMonth(date.getMonth() + 6)).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit"           
                });                
            },
            formatTime(raw){
                let date = new Date(raw).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit"                
                });
                let time = new Date(raw).toLocaleTimeString("en-US", {   
                    hour: '2-digit',
                    minute: '2-digit'          
                });
                return date + ' ' + time;
            },
            openPacket(file){
                if(file.attributes.scanStatus == 'OK')
                    window.open(file.links.self);
            },
            scanStatusDisplay(status){
                switch(status){
                    default:
                    case 'PENDING':
                        return 'PENDING';

                    case 'OK':
                        return 'OK';

                    case 'FOUND':
                        return 'VIRUS/MALWARE FOUND';
                }
            },
            toggleDeleteCheck(file, evt){
                let id = file.folder + '_' + file.attributes.name;
                if(evt.target.checked){
                    this.deleteChecked[id] = file;
                }else{
                    if(this.deleteChecked[id]){
                       delete this.deleteChecked[id] 
                    }
                }
            }
        },  
    setup( props ){

            const deleteChecked = ref({})
            const deleteId = ref(-1)
            const viewFolder = ref('-1');

            return { 
                deleteChecked,
                deleteId,
                viewFolder
            }
    }
}

</script>
