<!-- InputTextarea: A textarea field input -->
<template>
    <textarea :id="id"   
        ref="refInput"
        :tabindex="index"   
        v-model="input"                       
        :class="`border w-full h-full text-left p-1 rounded-sm mn3-input`">
    </textarea>
</template>
<script>

import { ref, computed } from "vue";

export default{
    props:{
        /* Properties without defaults */
        id: String,                     //id: ID assigned to the input
        index: Number,                  //index: TabIndex assigned to the input
        
        /* Properties with defaults */
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
    },
    methods:{        
        focus(){
            this.refInput.focus();
        },        
    },
    setup(props, { emit }){
        
        const refInput = ref(null);
        /* --Variables-- */        
        const val = ref(props.formValue);   //val: Used in computed input property

        /* --Computed-- */        
        const input                         //input: holds input value, emits updated event on change
            = computed({
            get: () => val.value,
            set: (v) => (val.value = v,emit('updated', formValue => Object.keys(formValue)[0], v))
        })

        /* --Functions-- */        
        
        return {
            input,
            refInput
        }
    }
}

</script>