import { createRouter, createWebHashHistory } from 'vue-router'


/* App Admin Screens */
import AppDash from '../views/adminmn/MnDash.vue'
import AppFiList from '../views/adminmn/FiList.vue'
import AppFiColor from '../views/adminmn/FiColor.vue'
import AppFiSettings from '../views/adminmn/FiSettings.vue'
import AppSettings from '../views/adminmn/MnSettings.vue'

/* FI Admin Screens */
import FinDash from '../views/adminfi/Dash.vue'
import FinMembers from '../views/adminfi/Members.vue'
import FinBoardControl from '../views/adminfi/BoardControl.vue'
import FinBoardDocs from '../views/adminfi/BoardDocs.vue'
import FinChat from '../views/adminfi/Chat.vue'
import FinEvents from '../views/adminfi/Events.vue'
import FinLinks from '../views/adminfi/Links.vue'
import FinSettings from '../views/adminfi/Settings.vue'
import FinLoanApps from '../views/adminfi/LoanApps.vue'
import FinCardPayments from '../views/adminfi/CardPayments.vue'
import FinUnlock from '../views/adminfi/Unlock.vue'
import FinUserAccess from '../views/adminfi/UserAccess.vue'

/* MembNet Screens */
import MemAccount from '../views/membnet/Account.vue'
import MemAccounts from '../views/membnet/Accounts.vue'
import MemAllied from '../views/membnet/Allied.vue'
import MemIpay from '../views/membnet/Ipay.vue'
import MemNeuralpay from '../views/membnet/Neuralpay.vue'
import MemBudget from '../views/membnet/Budget.vue'
import MemBlank from '../views/membnet/Blank.vue'
import MemCapture from '../views/membnet/Capture.vue'
import MemChat from '../views/membnet/Chat.vue'
import MemDevices from '../views/membnet/Devices.vue'
import MemLinks from '../views/membnet/Links.vue'
import MemLogin from '../views/membnet/Login.vue'
import MemLogout from '../views/membnet/Logout.vue'
import MemNotices from '../views/membnet/Notices.vue'
import MemSettings from '../views/membnet/Settings.vue'
import MemStatements from '../views/membnet/Statements.vue'
import MemTransfer from '../views/membnet/Transfer.vue'
import MemInputTest from '../views/membnet/InputTest.vue'

/* Public Screens */
import PubNotFound from '../views/public/NotFound.vue'
import PubLoanApp from '../views/public/LoanApp.vue'
import PubRegister from '../views/public/Register.vue'

/* Demo Menu */
import PubDemo from '../views/Demo.vue'
 
const routes = [

   {
      path: '/:pathMatch(.*)*',
      name: 'PubNotFound',
      component: PubNotFound
   }, 
   {
      path: '/',
      redirect: '/accounts'
   },

   {
      path: '/404',
      name: 'PubNotFoundDirect',
      component: PubNotFound
   },
   {
      path: '/pub/demo',
      name: 'PubDemo',
      component: PubDemo
   },

   /* MemberNet App Admin Screens */
    {
        path: '/adminmn',
        redirect: '/adminmn/dash'
    },
    {
        path: '/adminmn/dash',
        name: 'AppDash',
        component: AppDash
    },
    {
        path: '/adminmn/filist',
        name: 'AppFiList',
        component: AppFiList
    },
    {
        path: '/adminmn/settings',
        name: 'AppSettings',
        component: AppSettings
    },
    {
        path: '/adminmn/ficolor',
        name: 'AppFiColor',
        component: AppFiColor
    },
    {
        path: '/adminmn/fisettings',
        name: 'AppFiSettings',
        component: AppFiSettings
    },

   /* FI Admin Screens */
    {
        path: '/admin',
        redirect: '/admin/dash'
    },
    {
        path: '/admin/dash',
        name: 'FinDash',
        component: FinDash
    },
    {
        path: '/admin/events',
        name: 'FinEvents',
        component: FinEvents
    },
    {
        path: '/admin/links',
        name: 'FinLinks',
        component: FinLinks
    },
    {
        path: '/admin/members',
        name: 'FinMembers',
        component: FinMembers
    },
    {
        path: '/admin/boardcontrol',
        name: 'FinBoardControl',
        component: FinBoardControl
    },
    {
        path: '/admin/boarddocs',
        name: 'FinBoardDocs',
        component: FinBoardDocs
    },
    {
        path: '/admin/chat',
        name: 'FinChat',
        component: FinChat
    },
    {
        path: '/admin/settings',
        name: 'FinSettings',
        component: FinSettings
    },
    {
        path: '/admin/cardpayments',
        name: 'FinCardPayments',
        component: FinCardPayments
    },
    {
        path: '/admin/loanapps',
        name: 'FinLoanApps',
        component: FinLoanApps
    },
    {
        path: '/admin/unlock',
        name: 'FinUnlock',
        component: FinUnlock
    },
    {
        path: '/admin/uaccess',
        name: 'FinUserAccess',
        component: FinUserAccess
    },
   /* Membnet Screens */
   {
      path: '/inputtest',
      name: 'MemInputTest',
      component: MemInputTest
   },
   {
      path: '/',
      name: 'MemLogin',
      component: MemLogin
   },
   {
      path: '/login',
      name: 'MemLogin',
      component: MemLogin
   },
   {
      path: '/logout',
      name: 'MemLogout',
      component: MemLogout
   },
   {
      path: '/account/:accountId',
      name: 'MemAccount',
      component: MemAccount
   },
   {
      path: '/accounts',
      name: 'MemAccounts',
      component: MemAccounts
   },
   {
      path: '/allied',
      name: 'MemAllied',
      component: MemAllied,
      props: {
        mode: 'allied'
      }
   },
   {
      path: '/allied2',
      name: 'MemAllied2',
      component: MemAllied,
      props: {
        mode: 'allied2'
      }
   },
   {
      path: '/ipay',
      name: 'MemIpay',
      component: MemIpay
   },
   {
      path: '/neuralpay',
      name: 'MemNeuralpay',
      component: MemNeuralpay
   },
   {
      path: '/budget',
      name: 'MemBudget',
      component: MemBudget
   },
   {
      path: '/blank',
      name: 'MemBlank',
      component: MemBlank
   },
   {
      path: '/capture',
      name: 'MemCapture',
      component: MemCapture
   },
   {
      path: '/capture/:stage',
      name: 'MemCaptureStage',
      component: MemCapture
   },
   {
      path: '/notices',
      name: 'MemNotices',
      component: MemNotices
   },
   {
      path: '/settings',
      name: 'MemSettings',
      component: MemSettings
   },
   {
      path: '/settings/:sub_menu',
      name: 'MemSettingsSub',
      component: MemSettings
   },
   {
      path: '/transfer',
      name: 'MemTransfer',
      component: MemTransfer
   },
   {
      path: '/statements',
      name: 'MemStatements',
      component: MemStatements
   },
   {
      path: '/messages',
      name: 'MemChat',
      component: MemChat
   },
   {
      path: '/devices',
      name: 'MemDevices',
      component: MemDevices
   },
   {
      path: '/links',
      name: 'MemLinks',
      component: MemLinks
   },

   /* Public Screens */   
   {
      path: '/pub/loanapp',
      name: 'PubLoanApp',
      component: PubLoanApp
   },
   {
      path: '/pub/register',
      name: 'PubRegister',
      component: PubRegister
   },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
