<template>
    <Table 
        title="Authorized Devices" 
        class="w-full max-w-screen-md"
        :useRecords="false"
        :hasQuickFilter="false">     
            <div v-if="devices.length == 0" class="w-full text-center mn3-content p-2">[No saved devices found]</div> 
            <div v-if="showDevices" class="mn3-content border-t border-l border-r rounded overflow-hidden">
                <div v-for="device in devices" :key="device.ip"
                    :class="`mn3-content flex flex-col border-b`">     
                    <div v-if="confirmDelete == device.id"
                        class="w-full text-center font-bold mn3-cancel">Are you sure you want to remove this device?
                    </div>
                    <div :class="`flex justify-between items-center items-center px-5 py-2
                            ${confirmDelete == device.id ? ' mn3-cancel' : ''}`">
                        <div class="flex flex-col items-center w-16">
                            <Icon :id="device.type" class="w-10 h-10" />
                            <p class="uppercase">{{device.device}}</p>
                        </div>
                        <div  class="flex flex-col px-10">
                            <div>Last Login: {{device.lastLogin}}</div>
                            <div>Browser: {{device.browser}}</div>
                            <div>OS: {{device.os}}</div>
                            <div>IP: {{device.lastIp}}</div>
                        </div>
                        <div class="flex">
                            <button v-if="confirmDelete != device.id" class="mn3-button mn3-cancel" @click="confirmDelete = device.id">Remove Device</button>
                            <Button v-if="confirmDelete == device.id" type="cancel" :padding="1" label="Remove" @click="removeDevice(device.id)" />
                            <Button v-if="confirmDelete == device.id" :padding="1" label="Cancel" @click="confirmDelete = ''" />
                        </div>
                    </div>
                </div>
            </div>            
    </Table>
</template>

<script>
    import { ref } from 'vue'
    import { UAParser } from 'ua-parser-js'

    import Button from '@/components/Button.vue'
    import Icon from '@/components/Icon.vue'
    import Table from '@/components/Table.vue'

    export default {
        name: 'User Devices',
        components: {
            Button,
            Icon,
            Table
        },
        async created(){
            document.title="User Devices";

            // load account devices
            const deviceData = await this.$mapi.get("devices")
            console.log('deviceData: ', deviceData.data)
            this.devices = this.formatFetchedData(deviceData)
        },
        methods: {
            /* Format fetched device data records for display */
            formatFetchedData(raw) {
                //Records holder
                let records = []
                var parser = new UAParser();
            
                for (var ii = 0; ii < raw["data"]["data"].length; ii++) {
                    let rec = raw["data"]["data"][ii]["attributes"]
                    let id = raw["data"]["data"][ii]["id"]
                
                    // parse UA info
                    parser.setUA(rec["lastUserAgent"])
                    let ua = parser.getResult()

                    let device = (ua.os.name == 'Windows' || ua.os.name == 'Mac OS' || ua.os.name == 'Linux') ? 'pc' : 'mobile'

                    records.push({
                        id: id,
                        lastIp: rec["lastIp"],
                        lastLogin: this.formatLastLogin(rec["lastLogin"]),
                        lastUserAgent: rec["lastUserAgent"],
                        os: ua.os.name,
                        browser: ua.browser.name,
                        type: device
                    })
                }                           
                return records
            },
            /* Format fetched login data */
            formatLastLogin(raw){                     
                let utc = new Date(raw);
                
                const offset = utc.getTimezoneOffset();
                utc.setHours(utc.getHours() - (offset / 60))

                return utc.toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short"
                });     
            },
            /* Reload devices */
            async reloadDevices(){
                const deviceData = await this.$mapi.get("devices")
                this.devices =  this.formatFetchedData(deviceData)
                    this.showDevices = true                
            },
            /* Remove a device and reload */
            async removeDevice(id){                
                //split id from string
                let idSplit = id.split('/')
                let id2 = idSplit[idSplit.length-1]
                this.showDevices = false                
                this.$mapi.delete('devices/' + id2).then(r=>{
                    this.reloadDevices();
                });                
            }
        },
        setup(){
            /* Variables */
            const showDevices = ref(true)
            const devices = ref([]);            
            const confirmDelete = ref("")

            return{
                confirmDelete,
                devices,
                showDevices
            }
        }
    }
</script>