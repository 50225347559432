<!-- InputText: A textbox field input -->
<template>
    <div :class="`${type == 'date' ? 'flex-col items-start' : 'flex-row'} flex relative z-0 w-full items-center`">
        <input
            ref="refInput"
            :type="revealPass && type == 'password' ? 'text' : type"
            :id="id"   
            :tabindex="index"  
            :placeholder="placeholderText()"
            v-model="input"
            :value="formValue.value"
            @focus="inputFocus"
            @blur="inputBlur"
            @keyup="keyUp"
            @keydown="keydown"
            @change="change"
            autocorrect="off" 
            :maxlength="maxLength"
            autocapitalize="none"
            :class="`pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black mn3-input-text
                ${(uppercase ? ' uppercase' : ' ')}
                text-${align}`"
        />
        <label for="name" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{{label}}</label>
        <div v-if="type == 'password'" @click="revealPass = !revealPass" 
            class="m3-input-border h-10 w-10 p-2 text-center cursor-pointer border-b-2 relative mt-1" style="top: 1px"><Icon :id="revealPass ? 'eye-slash' : 'eye'" class="h-7 w-7" /></div>
        <!--Date input for mobiles-->
        <div v-if="type == 'date'" class="w-full flex flex-row justify-start">
            <div class="block md:hidden flex flex-row justify-start border-l border-r border-b rounded-b">
                <select class="mn3-input py-2 px-2" v-model="dateSelectMonth" @change="updateDateValue()">
                    <option value="0">MM</option>
                    <option v-for="i in 12" :key="i">{{(i < 10 ? '0' : '') + i}}</option>
                </select>
                <select class="mn3-input py-2 px-2" v-model="dateSelectDay" @change="updateDateValue()">
                    <option value="0">DD</option>
                    <option v-for="i in 31" :key="i">{{(i < 10 ? '0' : '') + i}}</option>
                </select>
                <select class="mn3-input py-2 px-2" v-model="dateSelectYear" @change="updateDateValue()">
                    <option value="0">YYYY</option>
                    <option v-for="i in 110" :key="i">{{2025-i}}</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>

import { ref, watch, computed } from "vue";

import Icon from '@/components/Icon.vue'

export default{
    name:'InputText',
        components: {
            Icon
        },              
    props:{
        id: String,                     //id: ID assigned to the input
        index: Number,                  //index: TabIndex assigned to the input
        label: String,                  //label: Label displayed for the input
        placeholder: String,
        textFormat: String,             //textFormat: Formatting applied to text. email, number, rate, date, money
        type: String,                   //type: Input type. text, password, or file

        align: {                        //align: Text alignment of the field
            type: String,
            default: 'left'
        },
        allowNegative: {                //allowNegative: If input value can be negative
            type: Boolean,
            default: false
        },
        defaultYear: {
            type: String,
            default: '2000'
        },
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
        maxLength: {                    //maxLength: Max length of input
            type: Number,
            default: null
        },
        uppercase: {                    //uppercase: Force uppercase on input
            type: Boolean,
            default: false
        },
        placeholderWhenFocused: {       //placeholderWhenFocused: Hide placeholder when not focused
            type: Boolean,
            default: true
        }
    },
    methods: {        
        change(evt){
            if(this.$props.type == "date"){
                this.updateDateSelectValues();
                this.$emit('blur');
            }
        },
        focus(){
            this.refInput.focus();
        },        
        // inputBlur: Emit blur and do any blur-processing required based on text format
        inputBlur(evt){
            this.focused = false;

            //Check for applying a mask
            switch(this.textFormat){
                case 'ssn':
                    this.mask(evt.target, this.mssn);
                    break;

                case 'phone':
                    this.mask(evt.target, this.mphone);
                    break;
            }

            this.emit('blur');
        },
        keyUp(evt){
            //If a character was just deleted, don't run mask
            if(evt.code != "Backspace" && evt.key != "Backspace" && evt.keyCode != 8){
                switch(this.textFormat){
                    case 'ssn':
                        this.mask(evt.target, this.mssn);
                        break;

                    case 'phone':
                        this.mask(evt.target, this.mphone);
                        break;
                }
            }    
            
            //If over max length, display an error and truncate
            if(this.input.length > this.$props.maxLength && this.$props.maxLength != null){
                this.error = `Max field length is ${this.$props.maxLength} characters`;
                this.input = this.input.substring(0, this.$props.maxLength);
                this.$emit('onError', this.error)
            }else{
                //Clear the error
                this.error = '';
                this.$emit('onError', this.error)
            }
        },
        mask(o, f) {
            setTimeout(function () {
                var v = f(o.value);
                if (v != o.value) {
                    o.value = v;
                }
            }, 1);
        },
        /* Phone# Formatter */
        mphone(v) {
            var r = v.replace(/\D/g,"");
            r = r.replace(/^0/,"");
            if (r.length > 10) {
                // 11+ digits. Format as 5+4.
                //r = r.replace(/^(\d\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3");
                r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/,"$1-$2-$3");
                return r;
            }
            else if (r.length > 5) {
                // 6..10 digits. Format as 4+4
                r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/,"$1-$2-$3");
            }
            else if (r.length > 2) {
                // 3..5 digits. Add (0XX..)
                r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
            }
            else {
                // 0..2 digits. Just add (0XX
                r = r.replace(/^(\d*)/, "$1");
            }
            return r;
        },
        /* SSN Formatter */
        mssn(v) {
            var r = v.replace(/\D/g,"");
            if (r.length > 9) {
                r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
                return r;
            }
            else if (r.length > 4) {
                r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
            }
            else if (r.length > 2) {
                r = r.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
            }
            else {
                r = r.replace(/^(\d*)/, "$1");
            }
            return r;
        },
        // Update the input's value to the dropdown selected date
        updateDateValue(){
            //Set unset day/month to 01 so display updates 
            if(this.dateSelectMonth == '0') this.dateSelectMonth = '1';
            if(this.dateSelectDay == '0') this.dateSelectDay = '1';
            if(this.dateSelectYear == '0') this.dateSelectYear = this.$props.defaultYear;
            //Update values
            this.input = this.dateSelectYear + '-' 
                + (this.dateSelectMonth.length == 1 ? '0' : '') + this.dateSelectMonth + '-' 
                + (this.dateSelectDay.length == 1 ? '0' : '')  + this.dateSelectDay;
            this.$props.formValue.value = this.input;
        },
        // Update date dropdowns to match input value
        updateDateSelectValues(){
            let split = this.formValue.value.split('-');
            console.log('split: ', split);
            if(split.length == 3){
                this.dateSelectYear = split[0];
                this.dateSelectMonth = split[1];
                this.dateSelectDay = split[2];
            }
        }
    },
    mounted(){
        //If date, make sure dropdowns match current value
        if(this.$props.type == 'date'){
            this.updateDateSelectValues()
        }
    },
    setup(props, { emit }){

        const refInput = ref(null);
        
        /* --Variables-- */
        const error = ref("");              //error: Current error on the input    
        const focused = ref(false);         //focused: If input is currently focused on
        const val = ref(props.formValue);   //val: Used in computed input property
        const revealPass = ref(false)       //revealPass: Display password instead of ***
        
        /* --Computed-- */
        const input = computed({              //input: holds InputText value, emits updated event on change
                get: () => val.value,
                set: (v) => (val.value = v,emit('updated', formValue => Object.keys(formValue)[0], v))
            })
        
        /* --Watch-- */    
        //Watch for and validate changes to input  
        watch(input, (currentValue, oldValue) =>{   
            error.value = "";
            let len = currentValue.length;

            if(props.uppercase)
                input.value = currentValue.toUpperCase();
                
            else if(props.lowercase)
                input.value = currentValue.toLowerCase();

            switch(props.textFormat){                
                case 'email':
                    if(!validateEmail(currentValue))
                        error.value = 'Invalid email entered.';
                    break;

                case 'money':
                case 'amount':
                    input.value = currentValue.replace(/[^-^.\d]/g,'');
                    if(len > input.value.length)
                        error.value = 'Invalid character entered.';


                    validateNegative(input, currentValue, oldValue);
                    if(error.value =='') validateDigits(input, currentValue, oldValue);
                    if(error.value =='') validateDecimals(input, currentValue, oldValue);
                    break;
                    
                case 'rate':
                    input.value = currentValue.replace(/[^-^.\d]/g,'');
                    if(len > input.value.length)
                        error.value = 'Invalid character entered.';
                    
                    validateNegative(input, currentValue, oldValue);
                    if(error.value =='') validateDigits(input, currentValue, oldValue);                    
                    if(error.value =='') validateDecimals(input, currentValue, oldValue);
                    break;

                case 'date':
                    /*
                    input.value = currentValue.replace(/[^/\d]/g,'');
                    if(len > input.value.length)
                        error.value = 'Invalid character entered.';

                    //Check for adding or removing a '/'
                    if(currentValue.length > oldValue.length){                                            
                        if(currentValue.length == 2 || currentValue.length == 5){
                            input.value = currentValue + '/';
                        }
                        else if((currentValue.length == 4 || currentValue.length == 7) &&
                            currentValue.substring(currentValue.length-1) == '/'){
                            input.value = oldValue                            
                        }
                    }        */             
                    break;
                    
                case 'number':
                    input.value = currentValue.replace(/[^-^.\d]/g,'');
                    if(len > input.value.length)
                        error.value = 'Invalid character entered.';
                        
                    validateNegative(input, currentValue, oldValue);
                    validateDigits(input, currentValue, oldValue);
                    validateDecimals(input, currentValue, oldValue);
                    break;
            }
        });

        /* --Functions-- */
        
        // keydown: Check for emitting an even based on key pressed
        //  evt: Keypress event
        function keydown(evt){
            if(evt.key == 'Escape'){
                emit('esc')        
            }
            else if(evt.key == 'Enter'){
                emit('enter')      
            }
        }

        // validateDecimals: Make sure field doesn't have too many values in decimals place
        //  input: input element holding value
        //  currentValue: What input was just changed to
        //  oldValue: What input was just changed from
        function validateDecimals(input, currentValue, oldValue){
            let split = currentValue.toString().split('.');            

            let decimals = props.decimals
            if(props.textFormat == 'money' || props.textFormat == 'amount')
                decimals = 2
            else if(props.textFormat == 'rate')
                decimals = 3

            //Check for too many deciamls
            if(split.length > 2){
                error.value = 'Too many decimals entered.';
                    input.value = oldValue;
            }
            //Check for too many decimal places
            if(split.length == 2){
                if(split[1].length > decimals){
                   error.value = 'This value may contain up to ' + decimals + ' decimal places.';
                    input.value = oldValue;
                }
            }
        }

        // validateDigits: Make sure field doesn't have too many numbers before decimal
        //  input: input element holding value
        //  currentValue: What input was just changed to
        //  oldValue: What input was just changed from
        function validateDigits(input, currentValue, oldValue){
            let split = currentValue.toString().split('.');    
            let length = split[0].length - (split[0].split('-').length - 1);

            if(length > props.digits){
                error.value = 'This value may contain up to ' + props.digits + ' digits.';
                input.value = oldValue;
            }                    
        }

        // validateEmail: Make sure a somewhat valid email has been enetered
        //  email: Email address entered
        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        // validateNegative: Make sure field isn't negative if not allowed, and make sure 
        //      an invalid '-' character isn't input
        //  input: input element holding value
        //  currentValue: What input was just changed to
        //  oldValue: What input was just changed from
        function validateNegative(input, currentValue, oldValue){
            if(currentValue.indexOf('-') != -1){                
                if(!props.allowNegative){
                    input.value = oldValue;
                    error.value = 'Negative numbers are not allowed for this value.';
                }else{
                    if(currentValue.split('-').length > 2 || 
                        currentValue[0] != '-'){
                        input.value = oldValue;
                        error.value = 'Invalid - entered.';
                    }
                }
            }
        }

        // placeholderText: Return the text to be used as a placeholder.
        function placeholderText(){
            if(!props.placeholderWhenFocused || focused.value){
                return props.placeholder;
            }else return " ";
        }

        function inputFocus(){
            focused.value = true;
        }


        const dateSelectDay = ref('0');
        const dateSelectMonth = ref('0');
        const dateSelectYear = ref('0');

        return {
            dateSelectMonth,
            dateSelectDay,
            dateSelectYear,
            emit,
            input,
            inputFocus,
            keydown,
            placeholderText,
            refInput,
            revealPass
        }

    }
}

</script>