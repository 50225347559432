<template>
    <div class="flex flex-col text-xl">
        <div class="uppercase font-bold w-full text-center text-2xl">Demo Systems</div>
        <router-link to="/accounts" target="_blank" title="m1/test"><div :class="buttonClasses">Member Demo</div></router-link>
        <router-link to="/ami/dash" target="_blank" title="admin/test"><div :class="buttonClasses">FI Admin Demo</div></router-link>
        <router-link to="/admin/filist" target="_blank" title="root/root"><div :class="buttonClasses">AMI Admin Demo</div></router-link>    
        <router-link to="/pub/register?external=true" target="_blank" title=""><div :class="buttonClasses">User Registration (Linked Terms)</div></router-link> 
        <router-link to="/pub/register?external=false" target="_blank" title=""><div :class="buttonClasses">User Registration (Embedded Terms)</div></router-link>  
    </div>

</template>

<script>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    export default{
        name: 'AMIMembNet Demo',
        components: {
        },        
        created(){
            document.title = 'AMIMembNet Demo'
        },
        setup(){

const picked = ref(new Date())
            const router = useRouter();

            const buttonClasses = 'mn3-button cursor-pointer p-2 rounded-sm mb-2'

            return{
                buttonClasses,
                router,
                picked
            }

        }
    }
</script>