<!-- InputSelect: A dropdown selection field input -->
<template>
    <div style="position: relative; top: -2px">
        <!-- Select if options are grouped -->
        <select 
            ref="refInput"
            v-if="hasGroups" 
            :id="id"   
            :tabindex="index"   
            v-model="input"                       
            :class="`mn3-input border w-full p-1 rounded-sm`">
            <optgroup v-for="group in optionGroups" :label="group" :key="group">
                <option v-for="option in optionsInGroup(group)" :key="option.value" :value="option.value" style="bold">{{(option.label == undefined ? option.value : option.label)}}</option>
            </optgroup>
        </select>
        <!-- Select if options are not grouped -->
        <select 
            ref="refInput"
            v-else
            :id="id"   
            :tabindex="index"   
            v-model="input"                       
            :class="`mn3-input border w-full p-1 rounded-sm`">
                <option v-for="option in optionsToShow" :key="option.value" :value="option.value" style="bold">{{(option.label == undefined ? option.value : option.label)}}</option>
        </select>
    </div>
</template>
<script>

import { ref, computed } from "vue";

export default{
    props:{
        /* Properties without defaults */
        id: String,                     //id: ID assigned to the input
        index: Number,                  //index: TabIndex assigned to the input
        options: Object,                //options: Options for the dropdown selector

        /* Properties with defaults */
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
    },
    methods:{        
        focus(){
            this.refInput.focus();
        },        
    },
    setup(props, { emit }){
        
        const refInput = ref(null);
        /* --Variables-- */
        const val = ref(props.formValue);       //val: Used in computed input property

        /* --Computed-- */    
        const hasGroups                         //hasGroups: Returns if this select has grouped options
            = computed(() => {
            let ret = false;
            if(props.options == undefined) return false;
            
            for(var ii = 0; ii < props.options.length; ii++){
                if(props.options[ii].group != null)
                    ret = true;
            }
            return ret;
        });

        const input                               //input: Holds value for this input, emits updated event on change
            = computed({                 
            get: () => val.value,
            set: (v) => (val.value = v,emit('updated', formValue => Object.keys(formValue)[0], v))
        })

        const optionGroups                      //optionGroups: Returns [] of all option group keys
            = computed(() => {
            let groups = [];
            for(var ii = 0; ii < props.options.length; ii++){
                if(!groups.includes(props.options[ii].group))
                    groups.push(props.options[ii].group);
            }            
            return groups;
        });

        const optionsToShow                     //optionsToShow: Dropdown options to be displayed, used if no grouped options
            = computed(() => {    
            return props.options
        });

        /* --Functions-- */
        // optionsInGroup: Returns all options in an option group
        //  group: Option group key
        function optionsInGroup(group){
            let options = [];
            for(var key in props.options){
                let opt = props.options[key];
                if(opt.group == group){
                    options.push({ label: opt.label, value: opt.value});
                }
            }
            return options;
        }

        
        return {
            hasGroups,
            input,
            optionGroups,
            optionsInGroup,
            optionsToShow,
            refInput
        }

    }
}

</script>