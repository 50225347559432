import { defineCustomElements } from '@ionic/pwa-elements/loader';
import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './plugins/api.js'

createApp(App).use(store).use(router).use(api).mount('#app')
defineCustomElements(window)
