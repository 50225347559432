<!-- MenuOption: An option used on a Menu -->
<template>
    <router-link :to="routerlink" v-if="routerlink != undefined">
        <div 
            :style="``"
            :class="optionClasses">
            <Icon v-if="icon != undefined" :id="icon" class="w-5 h-5 mr-1"/>
            <div>{{label}}</div>
        </div>
    </router-link>
    <div v-else
            :style="``"
            :class="optionClasses">
            <Icon v-if="icon != undefined" :id="icon" class="w-5 h-5 mr-1"/>
        <div>{{label}}</div>
    </div>
</template>

<script>
import { ref } from "vue";
import Icon from "@/components/Icon.vue";
import { useStore } from "vuex";

export default {
    components: {
        Icon
    },
    props:{

        /* Properties without defaults */
        id: String,                     //id: ID of the menu option
        label: String,                  //label: Didplay label for menu option
        routerlink: String,             //routerLink: Routerlink for the option

        /* Properties with defaults */
        alt: {                          //alt: Display in alt colors
            type: Boolean,
            default: false
        },
        icon: {                         //icon: Icon for menu option
            type: String,
            default: undefined
        }
    },
    setup(){
        let optionClasses = `mn3-content-hover flex justify-start items-center p-3 md:p-2 pl-2 text-sm cursor-pointer transition duration-150 ease-in-out mb-0 border-b`;

        return {
            optionClasses
        }
    }
}

</script>