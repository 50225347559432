<template>
    <div class="max-w-screen-md w-full flex flex-col flex-wrap">
        <div class="w-full p-10 mn3-content rounded m-2 overflow-hidden">
            <img :src="$store.state.theme.mainLogoUrl" class="w-96 h-auto mx-auto" />
        </div>
        <!--Total Members-->
        <div class="mx-auto mn3-content flex flex-row items-center justify-start rounded-sm my-2 uppercase font-bold text-xl">
            <div class="mn3-navbar p-2 rounded-l">
            <Icon id="user-solid" class="h-6 w-6" />
            </div>
            <span class="p-2">{{totalMembers}} Registered Members</span>
        </div>

        <!-- Member Logins -->
        <div class="mb-2 mx-2 flex-grow">
            <div class="mn3-navbar px-2 py-1 rounded-t font-bold text-xl flex justify-start items-center">
                <Icon id="users" class="h-8 w-8 mr-1" />
                <p>Member Logins</p>
            </div>
            
            <div class="px-2 py-4 mn3-content">
                <apexchart class="w-full" type="bar" id="loginchart"></apexchart>
            </div>
            <div class="flex flex-row mn3-content justify-between pt-2 flex-wrap">

                <div 
                    :class="`flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">{{loginBreakdown.web + loginBreakdown.android + loginBreakdown.ios}}</p>
                    <p class="text-center text-xs uppercase opacity-70">Total<br />Last 30 Days</p>
                </div>
                
                <div
                 :class="`flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">{{loginBreakdown.web}}</p>
                    <p class="text-center text-xs uppercase opacity-70">Web<br />Last 30 Days</p>
                </div>
                
                <div
                 :class="`flex flex-col items-center w-24 mx-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">{{loginBreakdown.android}}</p>
                    <p class="text-center text-xs uppercase opacity-70">Android<br />Last 30 Days</p>
                </div>
                
                <div 
                 :class="`flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">{{loginBreakdown.ios}}</p>
                    <p class="text-center text-xs uppercase opacity-70">Iphone<br />Last 30 Days</p>
                </div>

            </div>
        </div>

	</div>
</template>

<script>
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Icon from "@/components/Icon.vue";
    import { ref } from 'vue'
    import { useRouter } from "vue-router"
    import VueApexCharts from 'vue3-apexcharts'

	export default {
		components:{
            Icon,
			Form,
			Field,
            apexchart: VueApexCharts
		},
		data(){
			return {
			};
		},

        methods: {
            async getTotalMembers(){                
                let fetched = await this.$fapi.get("members");
                this.totalMembers = fetched.data.meta.total;
            },
            async getLoginData(){ 
                let fetched = await this.$fapi.get("osd-query?q=logins");
                this.loginData = fetched;

                //Update chart series info
                this.loginChartOptions.series = this.loginData.data.series

                //Convert and set xaxis for chart data format                
                let xaxis = {
                    type: 'category',
                    categories: fetched.data.xaxis.titles
                }
                this.loginChartOptions.xaxis = xaxis;

                //Get total number of logins
                console.log('loginChartOptions.series: ', this.loginChartOptions.series);
                for(var xkey in this.loginChartOptions.series){
                    let series = this.loginChartOptions.series[xkey];
                    console.log('series: ', series);
                    let total = 0;
                    for(var ii = 0; ii < series.data.length; ii++){
                        total += series.data[ii];
                    }
                    this.loginBreakdown[series.name] = total;
                }

                
                //Render the chart
                var chart = new ApexCharts(document.querySelector("#loginchart"), this.loginChartOptions);
                chart.render();
            }  
        },

		created(){
            document.title = 'Admin Dashboard'
            this.getLoginData();
            this.getTotalMembers();
		}, 

        setup(){
            

			/* Form field values */
			let fv = {}

            const loginBreakdown = ref({ web: 0, android: 0, ios: 0}) //Breakdown of total logins be device
            const loginChart = ref(null);
            const loginData = ref([]);
            const totalMembers = ref(0)
            const loginChartOptions = ref({
          series: [
            { name: 'web', data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
            { name: 'android', data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] },
            { name: 'ios', data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] }
          ],
          chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
             "type": "category", "categories": ["","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""], "convertedCatToNumeric": false 
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
        });
        

        var chartTest = {
          series: [{
          name: 'PRODUCT A',
          data: [44, 55, 41, 67, 22, 43]
        }, {
          name: 'PRODUCT B',
          data: [13, 23, 20, 8, 13, 27]
        }, {
          name: 'PRODUCT C',
          data: [11, 17, 15, 15, 21, 14]
        }, {
          name: 'PRODUCT D',
          data: [21, 7, 25, 13, 22, 8]
        }],
          chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          type: 'datetime',
          categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
            '01/05/2011 GMT', '01/06/2011 GMT'
          ],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
        };

            return { 
                chartTest,
				fv,
                loginBreakdown,
                loginChart,
                loginChartOptions,
                loginData,
                totalMembers
            }

        }
	}
	
</script>
