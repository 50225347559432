<!-- 'BottomBar': Fills full screen with member controls from the top and nav options the bottom. Gives enough room at bottom to still show mobile footer nav-->
<template>
    <!--4 button nav bar-->
    <transition name="bar" mode="out-in">
        <div class="mn3-navbar border-t-4 bottom-0 fixed w-full block z-50 block md:hidden cursor-pointer">
            <div :class="`z-50 flex flex-row justify-between ${mobileNavHeight} items-center`">
                <div :class="`w-50p border-r flex-grow align-middle justify-center items-center flex px-5 uppercase text-xs ${mobileNavHeight} ${mobileNavPadding}`" >
                    
                    <div v-for="screen in [this.$store.state.fi.mainNav1,this.$store.state.fi.mainNav2,rdcEnabled ? 'capture' : this.$store.state.fi.mainNav3]" :key="screen"
                        :class="`${itemWidth} ${screen == '' || !screen ? 'hidden' : ''} flex-0 flex items-center flex-col`"
                        @click="navClicked('/' + screen);closeMenu();">
                        <Icon class="w-8 h-8" :id="mainNavIcon(screen)" />
                        <p>{{mainNavTitle(screen)}}</p>                
                    </div>
                    <div :class="`${itemWidth} flex-0`"                        
                        @click="$store.state.isMobileNavOpen ? closeMenu() : openMenu();">
                        <div v-if="$store.state.isMobileNavOpen" class=" flex items-center flex-col">
                            <Icon class="w-8 h-8" id="x" />
                            <p>Close</p>
                        </div>
                        <div v-else  class=" flex items-center flex-col">
                            <Icon class="w-8 h-8" id="hamb" />
                            <p>More</p>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>

import { ref } from "vue"

import Icon from "@/components/Icon.vue";

export default {
    name: "BottomBar",
    components: {
        Icon
    },
    computed: {
        rdcEnabled(){            
			let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
			let membStatus = "A";
			if(mship)
				membStatus = mship.attributes.status;			

            return this.$store.state.fi.rdcEnabled && membStatus != 'R' && membStatus != 'B'
        },
        activeAccount(){            
            for(var key in this.relatedAccounts){
                let acct = this.relatedAccounts[key];
                if(acct.id == this.activeAccountId)
                    return acct;
            }
            return {};
        },
        bottomPadding(){
            if(this.$store.state.isIos){
                return ' pb-20 ';
            }
            return ' pb-16 ';            
        },
        /* # of custom links to show by default, depends on window height */
        secondaryMaxDefault(){
            if(this.screenHeightCategory == 'short'){
                return 0;
            }

            let itemHeight = 70;
            let otherMenuHeight = 750;
            let num = Math.floor((window.innerHeight - otherMenuHeight) / itemHeight)
            return num
        },
        /* If the screen is less than 500px wide consider it skinny.
            Skinny screens with both estatements and rdc enabled will move estatements to 'more' menu */
        isSkinnyScreen(){
            return window.innerWidth <= 500;
        },
        itemWidth(){
            if(this.$store.state.isRdcEnabled){
                return ' w-1/4 ';
            }
            return ' w-1/3 ';  
        },
        menuMaxHeight(){
            let h = window.innerHeight - 45;
            return h + 'px';
        },
        mobileNavHeight(){
            if(this.$store.state.isIos){
                return ' h-20 ';
            }
            return ' h-16 ';
        },
        mobileNavPadding(){
            if(this.$store.state.isIos){
                return ' pb-6 ';
            }
            return ' pb-2 ';
        },
        /* Add links that aren't in the main bottom bar to the expanded menu */
        navLinksShow(){
            let links = [];
            for(var ii = 0; ii < this.$props.navLinks.length; ii++){
                console.log('props.navlinks[ii]: ', this.$props.navLinks[ii])
                if(this.$props.navLinks[ii].to !== '/accounts' &&
                    this.$props.navLinks[ii].to !== '/capture' &&
                    this.$props.navLinks[ii].to !== '/transfer')
                    links.push(this.$props.navLinks[ii])
            }
            return links;
        },
        screenHeightCategory(){
            if(window.innerHeight < 800){
                return 'short';
            }
            else if(window.innerHeight > 1150){
                return 'tall';
            }
            return 'medium';
        }
    },
    methods: {    
        async closeMenu(){
            //Short delay to allow menu to close before main content is shown again
            await new Promise(resolve => setTimeout(resolve, 100));  
            this.$store.state.isMobileNavOpen = false;
        },
        async openMenu(){
            this.$store.state.isMobileNavOpen = true; 
            this.customExpanded = false;
        },
        /* Load related sub accounts for logged in user */
        loadRelatedAccounts(){
            this.relatedAccounts = {};
            let data = JSON.parse(JSON.stringify(this.$store.state.memberships))
            let activeFound = false;
            let mainId = 0;
            let mainName = '';

            for(var ii = 0; ii < data.length; ii++){
                let atrb =  {...data[ii]}["attributes"]
                
                //Check for setting default account
                if(atrb.id == this.activeAccountId){
                    activeFound = true;
                }
                    
                if(atrb.default){
                    mainId = atrb.id
                    mainName = atrb.title
                }
                this.relatedAccounts[atrb.id] = {name: atrb.title, notice: 0, default: atrb.default, id: atrb.id};
            }

            //If no active account, set to default
            if(!activeFound){
                this.activeAccountId = mainId
            }
            
            //Check for returning to related account on reload            
            if(localStorage['membership_id'] != undefined && localStorage['membership_id'] != ''){
                let restoreId =  localStorage['membership_id'];
                if(restoreId != this.activeAccountId && this.relatedAccounts[restoreId] != undefined
                    && this.$store.state.membershipId != restoreId){
                    this.manageAccount(restoreId);
                }
            }
        },
        mainNavClicked(screen){        
            switch(screen){

                case 'accounts':
                    this.$router.push('accounts');
                break;

                case 'capture':
                    this.$router.push('capture');
                break;
                
                case 'transfer':
                    this.$router.push('transfer');
                break;
                
                case 'statements':
                    this.$router.push('statements');
                break;

            }
            return;
        },
        mainNavIcon(screen){        
            switch(screen){

                case 'accounts':
                return 'dollar';
                
                case 'capture':
                return 'camera';
                
                case 'transfer':
                return 'transfer';
                
                case 'statements':
                return 'document';

                case 'neuralpay':
                case 'ipay':
                case 'allied':
                case 'allied2':
                return 'ticket-2';

            }
            return screen;
        },
        mainNavTitle(screen){
            switch(screen){

                case 'accounts':
                return 'Accounts';
                
                case 'capture':
                return 'Deposit';

                case 'transfer':
                return 'Transfer';
                
                case 'statements':
                return 'Statements';  
                
                case 'neuralpay':
                return this.$store.state.fi.neuralpayTitle
                
                case 'ipay':
                return this.$store.state.fi.ipayTitle
                
                case 'allied':
                return this.$store.state.fi.alliedTitle
                
                case 'allied2':
                return this.$store.state.fi.allied2title
            }
            return screen;
        },
        /* Select a different account to manage */
        manageAccount(id){
            
            if(this.$store.state.membershipId == id){
                return;
            }else{
                this.$store.commit("setMembershipId", id);
                localStorage['membership_id'] = id;
                this.activeAccountId = id
                this.loadRelatedAccounts()
                this.$router.push('/accounts')  
            }
        },  
        /* Nav option selected, go to path or reload scren if same */
        navClicked(path){
            if(path == undefined)
                return;

            if(path.indexOf('http') != -1){
                window.open(path);
            }else{
                let current = this.$router.currentRoute['_rawValue'].path;
                if(current == path){
                    if(this.$store.state.isIos)
                        this.$router.push('/blank');
                    else
                        this.$router.go(0);
                }else{
                    this.$router.push(path);
                }
            }
        }    
    },
    props:{           
        mainNav:{            
            type: Object,
            default: []
        },
        secondaryNav:{
            type: Object,
            default: []
        },
        additionalNav:{
            type: Object,
            default: []
        },
        customLinks: {
            type: Object,
            default: []
        }, 
        isLoggedIn: {                         //isLoggedIn: If user is currently logged in
            type: Boolean,
            default: true
        },
        navLinks: {                           //navLinks: Nav links displayed
            type: Object,
            default: []
        }
    },
    created(){
        this.loadRelatedAccounts();
        this.activeAccountId = this.$store.state.membershipId
    },
    setup( props ){        
        /* --Variables-- */
        const activeAccountId               //activeAccountId: ID of account currently being managed
            = ref(-1) 
        const categoryClasses               //categoryClasses: Classes applied to header items in nav menu
            = ref('mn3-topnav px-2 py-3 text-lg border-t border-b font-bold uppercase flex flex-row items-center');
        const optionClasses                 //optionClasses: Classes applied to options in nav menu
            = ref('mn3-content-hover block py-4 px-3 border-b flex flex-row items-center');
        const relatedAccounts = ref({})     //relatedAccounts: Related accounts that can be managed
        const customExpanded = ref(false)
        const refOpenedMenu = ref(null)

        return{
            activeAccountId,
            categoryClasses,
            customExpanded,
            optionClasses,
            refOpenedMenu,
            relatedAccounts
        }
    }
}
</script>


