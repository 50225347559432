<!-- Button: Generic styled button used in forms -->
<template>
    <div :class="`${tileWidth(size)} flex flex-col p-1`">
        <!-- Tile BG -->
        <div :class="`${tileStyleRules(theme)}
                h-${height} flex flex-row items-start w-full border p-4 rounded-sm items-center justify-start cursor-pointer opacity-40 hover:opacity-30 duration-200`"
            :style="tileBgRules(theme)" />
        <!-- Tile Content -->
        <div :style="`${hasBgImage ? 'pointer-events: none;' : ''} position: relative; width: 100%; height: 0; overflow:visible;`">
            <div :class="`${tileStyleRules(theme)}
                ${size == 2 || size == 3 ? 'items-start' : 'items-center'} relative -top-${height} h-${height} w-full border p-4 rounded-sm flex flex-col justify-center cursor-pointer`"
            :style="tileStyleTextRules(theme)">                       
                <!--Size 3, full width-->
                <div v-if="size == 3" class="flex flex-row items-center justify-start">
                    <Icon :id="icon"  :outline="hasBgImage ? '#000' : ''"  :shadow="hasBgImage" :class="`${iconSizeRule} mt-0 flex-grow-0 flex-shrink-0`" />
                    <div class="flex-grow flex flex-col text-left justify-start">                                    
                        <div :class="`${descriptionFirst ? 'order-2 pt-1' : ''} oswald font-extrabold uppercase leading-7 px-2 rounded-sm w-full text-3xl`">{{title}}</div>
                        <div :class="`${descriptionFirst ? 'order-1' : 'pt-1 '} lato font-b leading-5 px-2 rounded-sm w-full text-xl`">{{description}}</div>
                    </div>
                </div>
                <!--Size 2, Med-->
                <div v-else-if="size == 2" class="flex flex-row items-center justify-center w-full">
                    <Icon :id="icon" :outline="hasBgImage ? '#000' : ''" :shadow="hasBgImage" :class="`${iconSizeRule} mt-0 flex-grow-0 flex-shrink-0`" />
                    <div class="flex-grow flex flex-col text-left justify-start">                                    
                        <div :class="`${descriptionFirst ? 'order-2 pt-1' : ''} oswald font-bold uppercase leading-6 px-2 rounded-sm w-full text-2xl`">{{title}}</div>
                        <div :class="`${descriptionFirst ? 'order-1' : 'pt-1'} lato leading-5 px-2 rounded-sm w-full text-xl`">{{description}}</div>
                    </div>
                </div>
                <!--Size 1, Sm -->
                <div v-else class="flex flex-col items-center w-full">
                    <Icon :id="icon"  :outline="hasBgImage ? '#000' : ''"  :shadow="hasBgImage" :class="iconSizeRule" />
                    <span :class="`oswald uppercase text-base leading-4 px-2 text-center rounded-sm w-full font-bold flex-grow`">{{title}}</span>
                           
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref } from 'vue'

	import Icon from "@/components/Icon.vue";

export default{
    components:{
        Icon
    },
    props:{        

        theme: String,                      //theme: String displayed on the button
        title: String,                      //title: String displayed on the button
        description: String,                       //description: String displayed on the button
        descriptionFirst:{
            type: Boolean,
            default: false
        },
        icon: String,                       //icon: String displayed on the button
        iconSize: {
            type: Number,
            default: 0
        },
        size: String,                       //size: String displayed on the button
        height: String,                     //height: String displayed on the button

    },
    created(){
        //Get all theme image urls
        for(var ii = 0 ; ii < this.styles.length; ii++){
            this.bgUrls[this.styles[ii]] = require('@/assets/img/buttons/'+this.styles[ii]+'.jpg');
        }
    },
    methods:{
        tileWidth(s){
            return 'w-full';
        },
        //Tile style style apllied vie style
        tileBgRules(theme){
            let url = '';
                        
            if(this.hasBgImage && this.bgUrls[theme] != undefined)
               url = this.bgUrls[theme];

            if(url != '')
                return `font-family: Lato; 
                        background-image: url("${url}");
                        background-size: cover;
                        background-position-y:60%;
                        background-position-x: center;
                        text-shadow: 2px 2px 0px #000;/*-1px -1px 0px #666, -1px 0px 0px #666, 0px -1px 0px #666;*/
                        text-size: 180%;`;

            return '';
        },
        tileStyleTextRules(theme){

            if(this.hasBgImage)
                return 'text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5), 2px 2px 1px rgba(0, 0, 0, 0.5), 1px 1px 0px #000, -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, -1px 0px 0px #000, 0px -1px 0px #000, 1px 0px 0px #000, 0px 1px 0px #000';
            
            return '';

        },
        //Tile theme rules applied via classes
        tileStyleRules(theme){

            if(this.hasBgImage)
                return 'text-white';

            switch(theme){
                default: 
                case 'content':
                    return 'mn3-content-hover';
                                
                case 'highlight':
                    return 'mn3-content-highlight-hover';

                case 'alert':
                    return 'mn3-alert';

                case 'submit':
                    return 'mn3-submit';

                case 'cancel':
                    return 'mn3-cancel';

                case 'red':
                case 'green':
                case 'blue':
                case 'yellow':
                case 'purple':
                case 'gray':
                    return `text-${theme}-800 bg-${theme}-100 border-${theme}-600 hover:text-${theme}-600 hover:bg-${theme}-50 duration-200`;
                    
                case 'red-dark':
                case 'green-dark':
                case 'blue-dark':
                case 'yellow-dark':
                case 'purple-dark':
                case 'gray-dark':
                    let color = theme.split('-')[0];
                    return `text-white bg-${color}-800 border-${color}-800 hover:text-${color}-100 hover:bg-${color}-600 duration-200`;
                    
                }
            return '';
        },

    },
    computed:{
        hasBgImage(){
            return this.styles.indexOf(this.$props.theme) != -1;
        },
        iconSizeRule(){
            if(this.$props.iconSize > 0)
                return 'h-'+this.$props.iconSize+' w-'+this.$props.iconSize;
            

            if(this.$props.size == 1)
                return 'h-20 w-20';

            return 'h-24 w-24';
            
        },
        textBgColor(){
            return false;
        }

    },
    setup( props ){

        const styles = ref(['amusement', 'appliances', 'beach', 'fall', 'cafe', 'car', 'car-2', 'car-lot', 'credit-card', 'education', 
        'fall-2', 'graduation', 'house', 'house-2', 'house-3', 'house-city', 'house-for-sale', 'house-projects', 'house-projects-2', 'house-projects-3',
        'money', 'nature', 'new-years', 'party', 'riches', 'road', 'roadtrip', 'flowers', 'snow', 'spring', 'storm', 'summer-1', 'summer-2', 
        'support', 'suv', 'truck', 'vacation', 'winter' ]);
        const bgUrls = ref({});

        return{
            bgUrls,
            styles
        }
    }
}

</script>

<style scoped>
.lato{
    font-family: lato;
}

.oswald{
    font-family: oswald;
}
</style>