<template>
    <div class="fixed w-full text-white bg-gray-800 flex flex-row justify-center p-0" 
        :style="`height: ${windowHeight}px`">
        
        <!--Camera/Capture Button-->
        <div class="flex flex-col justify-center self-stretch bg-gray-800">
            <!--Camera-->
           <div class="bg-gray-800 p-0 flex-grow-0 flex-shrink-0 overflow-hidden"
                :style="`height: ${dimensions.cameraH*1}px; width: ${dimensions.cameraW*1}px`">
                <camera class="w-full p-0 flex flex-row items-center justify-center bg-gray-800" 
                    ref="camera" :autoplay="true" :playsinline="true"
                    :style="`height: ${dimensions.cameraH}px; width: ${dimensions.cameraW}px;`">
                    <!--Check Frame -->
                    <div class="flex flex-col opacity-80 mx-auto"
                        :style="`height: ${dimensions.cameraH}px; width: ${dimensions.cameraW}px;`">                        
                        <div :style="`height: ${dimensions.checkBufferY}px;`" class="w-full bg-gray-800 flex-shrink-0">
                        </div>
                        <div class="w-full flex flex-row"
                            :style="`height: ${dimensions.checkH}px;`">                        
                            <div :style="`width: ${dimensions.checkBufferX}px;`" class="bg-gray-800 flex flex-col items-center">                                

                            </div>
                            <div class="border-red-600 border-2 flex flex-col justify-center items-center overflow-hidden"
                                :style="`height: ${dimensions.checkH}px; width: ${dimensions.checkW}px`">
                                <div v-if="capturingBack" class="text-red text-lg tracking-right text-center uppercase opacity-70 font-bold flex flex-col" 
                                    :style="`width: ${dimensions.checkH}; transform: rotate(90deg);text-shadow:-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;`">
                                        <p>Endorse with</p>
                                        <p>"{{endorseText}}"</p>
                                        <p>followed by your signature</p>
                                </div>
                                </div>
                            <div :style="`width: ${dimensions.checkBufferX}px;`" class="bg-gray-800 flex flex-col items-center justify-center">      
                                <!--                                                         
                                <img src="@/assets/img/fi_tct_capture.png" 
                                    :style="`width: ${dimensions.checkBufferX * 0.90}px`" />
                                -->
                            </div>
                        </div>
                        <div :style="`height: ${dimensions.checkBufferY}px;`"
                            class="w-full bg-gray-800 flex-shrink-0"></div>                                        
                    </div>
                </camera>
            </div>
            <div
                class="w-full flex flex-row justify-between bg-gray-800 flex-grow-0 flex-shrink-0 overflow-hidden items-center"
                style="height: 100px">
                <!--Spacer-->
                <div class="w-1/3 flex-grow-0 ml-5"></div>
                <!--Capture Button-->
                <div @click="captureImage()" 
                    class="w-1/3 flex-grow-0 ">
                    <div 
                    style="height: 75px; width: 75px" 
                        class="bg-red-600 rounded-full" />
                </div>
                <!--Close Button-->
                <div @click="emit('close');" 
                    class="w-1/3  mr-5 flex-grow-0 bg-black text-white p-2 font-bold rounded-sm text-3xl flex flex-col items-center justify-center"                
                        style="height: 60px; width: 60px" ><p>X</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

import Camera from "simple-vue-camera";

export default {
    components: {
        Camera
    },
    computed: {        
        //What to endorse on the back of a check
        endorseText(){                
            // Southern Pine
            if(this.$store.state.fi.id == '16'){
                return `FOR REMOTE DEPOSIT ONLY, Acount #, SPCU`;
            }else{
                return `FOR REMOTE DEPOSIT ONLY, ${this.$store.state.fi.title}`
            }
        },
        windowHeight(){
            return window.innerHeight;
        }
    },
    methods: {  
        /* Converts blob data from camera to base64 */
        blobToBase64(blob) {
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        },
        /* Capture the image and emit as base64 */
        async captureImage(){
            console.log('capture image')
            let blob = await this.camera.snapshot(               
                { width: 1080, height: 1920},
                "image/jpeg",
                1
            );
            this.$emit('capture', blob)
            this.$emit('close')
        },      
        /* Use the height of the screen to determine dimensions of capture window */
        figureScreenDimensions(){

            this.dimensions.windowW = window.innerWidth;
            this.dimensions.windowH = window.innerHeight;

            this.dimensions.cameraW = window.innerWidth - 25;
            this.dimensions.cameraH = window.innerHeight - 100 - 25 
            
            this.dimensions.checkH = this.dimensions.cameraH - 70;
            this.dimensions.checkW = (this.dimensions.checkH * 3 ) / 7.5;

            this.dimensions.checkBufferY = (this.dimensions.cameraH - this.dimensions.checkH) / 2;
            this.dimensions.checkBufferX = (this.dimensions.cameraW - this.dimensions.checkW) / 2;   

        }
    },
    props:{
        capturingBack: {        //If capturing back of check
            type: Boolean,
            default: false
        },
        endorseName: {          //Endorsement name for back of check
            type: String,
            default: ''
        }
    },
    async mounted(){
        //Slight delay to allow screen elements to render before getting dimensions
        setTimeout(() => {this.figureScreenDimensions();}, 5);        
    },
    setup( props, { emit }){
        const camera = ref(null)    //Ref for camera component
        const dimensions = ref({    //Dimensions to build screen
            cameraW: 0,             //Full camera display dimensions
            cameraH: 0,
            checkW: 0,              //Check frame dimensions
            checkH: 0,
            checkBufferY: 0,        //Buffer aound check frame
            checkBufferX: 0
        })
        
        return {
            camera,
            dimensions,
            emit
        }
    }
}
</script>
