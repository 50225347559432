<template>
    <div class="w-full flex flex-col mx-auto max-w-screen-lg">
        <Notice v-if="notice" :icon="noticeIcon" :notice="notice" :showClose="true" @close="notice=''" class="p-1 mb-2"  />
        <div class="w-full flex flex-row items-center gap-x-5 mb-2 justify-around">            
            <Button v-if="this.$route.query.boardcontrol == 1" label="Return to Board Control" icon="arrow-uturn-left" class="w-1/3" 
            @click="this.$router.push('/admin/boardcontrol')"/>
            <Button label="Add Admin User" icon="user-plus"  @click="showAddUser()" class="w-full" />
        </div>
        <Form v-if="focusedMode == 'addUser'" :showCancel="true" @cancel="focusedMode = ''" :error="addError" @submit="addUser()" title="Add Admin User">
             
            <Notice class="p-2" icon="key" notice="Once submitted a temporary password will be emailed to the admin user. They will be required to update the password on first login." />

            <div class="w-full p-5">
                <div class="relative z-0 w-full">
                    <input type="text" id="email" v-model="addEmail" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                    <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">User Email</label>
                </div>
            </div>  
            <div class="w-full p-5">
                <div class="relative z-0 w-full">
                    <input type="text" id="email" v-model="addTitle" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                    <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Title</label>
                </div>
            </div>
            <div class="w-full p-5">
                <div class="relative z-0 w-full">
                    <input type="text" id="email" v-model="addUsername" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                    <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Username</label>
                </div>
            </div>
            <div class="w-full flex flex-col mx-auto max-w-screen-sm mn3-content-alt p-2 rounded">
                <div class="w-full font-bold border-b">User Permissions</div>
                <div class="w-full flex flex-col flex-wrap" style="max-height: 400px">
                    <label v-for="permission in permissions" :key="permission.id" 
                        class="basis-1/2 p-2 flex flex-row items-center gap-x-2 cursor-pointer" :title="permission.description">
                        <input type="checkbox" class="h-8 w-8" @click="toggleAddPermissions(permission.id, $event)" />
                        <div>{{permission.title}}</div>
                    </label>
                </div>
            </div>
        </Form>
        <Form v-else 
            title="Admin Users" 
            :class="`w-full flex-grow`"
            :showSubmit="false"
            :showCancel="false">

            <!--Viewing X of X header-->
            <div class="w-full mn3-content-header text-center p-1 text-sm uppercase flex flex-row justify-between items-center">
                <div :class="`${!firstUrl ? 'mn3-disabled opacity-50' : 'cursor-pointer'} mn3-button  px-5`"  @click="fetchFirstPage()">First Page</div>
                <div>Viewing {{((page - 1) * membersPerPage) + 1}} - {{((page) * membersPerPage) > totalMembers ? totalMembers : ((page) * membersPerPage)}} of {{totalMembers}} Members</div>
                <div :class="`${!nextUrl ? 'mn3-disabled opacity-50' : ' cursor-pointer'} mn3-button  px-5`" @click="fetchNextPage()">Next Page</div>
            </div>
            <div v-for="memb in members" :key="memb.id"
                class="flex flex-col pt-4 border-b">
                <!-- Multi-Line Display-->
                    <div :class="`${memb.deleted ? 'hidden' : ''} ${focusedId != memb.id && focusedId != -1 ? 'opacity-60' : ' opacity-100'} duration-150 flex flex-row justify-between pb-4`">
                        <div class="flex flex-col px-2 w-3/4">
                    
                            <div class="font-bold text-lg flex flex-row justify-start items-center">
                                    <p class="">{{memb.attributes.username}} - {{memb.attributes.email}}</p>
                            </div>

                                <div class="font-mono ml-0 mt-1"><span class="font-bold">Last Login:</span> {{displayDate(memb.attributes.lastLogin)}}</div>
                                <div class="font-mono ml-0">
                                    <span class="uppercase" v-if="memb.info != undefined">{{memb.info.device}}</span> - <span class="capitalize" v-if="memb.info != undefined">{{memb.info.os}}/{{memb.info.browser}}</span> <span>({{memb.attributes.lastIp}})</span></div>
                            <div v-if="memb.alert != undefined"
                                class="mn3-alert text-sm p-1 rounded-sm flex-grow mr-2 mb-1 my-2 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="exclamation" class="h-6 w-6 mr-2" />
                                <p>{{memb.alert}}</p>
                            </div>
                            <div v-if="memb.notice != undefined"
                                class="mn3-content-alt text-sm p-1 rounded-sm flex-grow mr-2 my-2 mb-1 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="info" class="h-6 w-6 mr-2" />
                                <p>{{memb.notice}}</p>
                            </div>
                        </div>

                        <div class="flex flex-col justify-start mr-2 uppercase text-xs font-bold text-center">
                            <div @click="editMember(memb)" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="settings" class="h-4 w-4 mr-2"/>
                                <p>Edit User Info</p>
                            </div>                            
                            <div @click="editPassword(memb)" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="key" class="h-4 w-4 mr-2"/>
                                <p>Reset Password</p>
                            </div>
                            <div @click="focusedId = memb.id;focusedMode='delete';" 
                                :class="`mn3-alert cursor-pointer px-2 py-1 rounded mb-1 flex flex-row justify-between items-center`">
                                <Icon id="trash" class="h-4 w-4 mr-2"/>
                                <div class="flex flex-row items-center">Delete User</div>
                            </div>
                            
                        </div>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="focusedId == memb.id"
                            class="p-2 rounded-sm">
                        <div class="w-full mn3-content rounded p-0 text-center">
                            <div v-if="focusedMode == ''" class="w-full flex flex-col">
                                <div class="flex justify-end">
                                    <p @click="focusedId = -1" class="mn3-cancel cursor-pointer rounded w-min px-2 py-1">X</p>
                                </div>
                            </div>
                            <!--Delete Member-->
                            <div v-else-if="focusedMode == 'delete'" class="w-full flex flex-col">
                                <Form v-if="deleteStatus == -1" :title="`Delete Account`"
                                    icon="trash"
                                    :showCancel="true"
                                    :submitText="`Delete Account '${memb.attributes.username}'`"
                                    @submit="deleteAccount(memb)"
                                    @cancel="focusedMode = '';focusedId=-1;">
                                    <div class="mn3-alert text-lg p-2 rounded-sm mx-2">
                                        <div class="flex flex-row items-center justify-center">
                                            <span>Are you sure you want to delete this account?</span>
                                        </div>
                                        <div class="my-2">{{memb.attributes.username}}, {{memb.attributes.email}}</div>
                                        <div class="font-bold text-2xl uppercase">This cannot be undone.</div>
                                    </div>
                                </Form>
                                <div v-else-if="deleteStatus == 204"
                                    class="mn3-content flex flex-row justify-between items-center">
                                    <div class="flex-grow flex flex-row items-center justify-center text-center">
                                        <Icon id="trash" class="h-8 w-8 mr-2" />
                                        <div>This account has been deleted.</div>
                                    </div>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2 mn3-cancel" @click="deleteStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                </div>
                                <div v-else>
                                    <p class="flex-grow text-center">
                                        There was an error deleting this account.
                                    </p>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2 mn3-cancel" @click="deleteStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                
                                </div>
                            </div>
                                                
                            <!--Reset Password-->
                            <div v-else-if="focusedMode == 'editPassword'" class="w-full flex flex-col">                        
                                <Form :title="`Reset Password for ${memb.attributes.username}`"
                                    :showCancel="true"
                                    :error="editError"
                                    submitText="Reset Password"
                                    @submit="resetPassword"
                                    @cancel="focusedMode = '';focusedId=-1;">    
                                    <div class="w-full p-2">                                
                                    <Notice class="rounded-sm" icon="key" notice="A temporary password will be emailed to the admin user, they will be required to update the password on first login." />
                                    </div>
                                </Form>
                            </div>

                            <!--Edit User Info-->
                            <div v-else-if="focusedMode == 'editInfo'" class="w-full flex flex-col">                        
                                <Form :title="`Edit Member Info for ${memb.attributes.username}`"
                                    :showCancel="true"
                                    :error="editError"
                                    submitText="Update"
                                    @submit="updateUserInfo"
                                    @cancel="focusedMode = '';focusedId=-1;">
                                    
                                        <div class="w-full p-5">
                                            <div class="relative z-0 w-full">
                                                <input type="text" id="email" v-model="editEmail" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                                                <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Member Email</label>
                                            </div>
                                        </div>
                                        <div class="w-full p-5">
                                            <div class="relative z-0 w-full">
                                                <input type="text" id="email" v-model="editTitle" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                                                <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Title</label>
                                            </div>
                                        </div>
                                        <div class="w-full p-5">
                                            <div class="relative z-0 w-full">
                                                <input type="text" id="email" v-model="editUsername" placeholder=" " required class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200" />
                                                <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Username</label>
                                            </div>
                                        </div>                                        
                                        <div class="w-full flex flex-col mx-auto max-w-screen-sm mn3-content-alt p-2 rounded">
                                            <div class="w-full font-bold border-b">User Permissions</div>                                            
                                            <div class="w-full flex flex-col flex-wrap" style="max-height: 400px">
                                                <label v-for="permission in permissions" :key="permission.id" 
                                                    class="w-1/2 p-2 flex flex-row items-center gap-x-2 cursor-pointer" :title="permission.description">
                                                    <input type="checkbox" class="h-8 w-8" :ref="'editPerm_' + memb.id + '_' + permission.id" @click="toggleAddPermissions(permission.id, $event)" />
                                                    <div>{{permission.title}}</div>
                                                </label>
                                            </div>
                                        </div>
                                    </Form>
                            </div>
                        </div>
                    </div>
                    </transition>


            </div>           
            <!--Viewing X of X header-->
            <div class="w-full mn3-content-header text-center p-1 text-sm uppercase flex flex-row justify-between items-center">
                <div :class="`${!firstUrl ? 'mn3-disabled opacity-50' : ' cursor-pointer'} mn3-button  px-5 `" @click="fetchFirstPage()">First Page</div>
                <div>Viewing {{((page - 1) * membersPerPage) + 1}} - {{((page) * membersPerPage) > totalMembers ? totalMembers : ((page) * membersPerPage)}} of {{totalMembers}} Members</div>
                <div :class="`${!nextUrl ? 'mn3-disabled opacity-50' : ' cursor-pointer'} mn3-button  px-5`" @click="fetchNextPage()">Next Page</div>
            </div>
        </Form>

    </div>
</template>

<script>
    import { ref } from 'vue'
    import { UAParser } from 'ua-parser-js'

	import Button from "@/components/Button.vue";
	import Chat from "@/components/Chat.vue";
	import Form from "@/components/Form.vue";
	import Icon from "@/components/Icon.vue";
	import Notice from "@/components/Notice.vue";
	import Field from "@/components/Field.vue";
    import { useRouter } from "vue-router"

	export default {
		components:{
            Button,
            Chat,
			Form,
			Field,
            Icon,
            Notice
		},
		data(){
			return {
			};
		},
        computed: {
        },
        methods:{
            async addUser(){
                console.log('addUser: ', this.addEmail)           
                this.addError = '';    

                let data = {
                    data: {
                        attributes: {
                            email: this.addEmail,
                            username: this.addUsername,
                            title: this.addTitle,
                            permissions: this.addPermissions
                        }
                    }
                };                
                try{
                    let resp = await this.$fapi.post('admins', data);
                    console.log('addUser.resp: ', resp);
                    this.focusedMode = '';
                    this.notice = 'User has been added';
                    this.noticeIcon = 'user-plus';
                    this.fetchUsers()
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    this.nextUrl = null;
                    this.firstUrl = null;
                    if(errorDetail) this.addError = errorDetail;
                    else if(errorTitle) this.addError = errorTitle;
                    else this.addError = 'There was an error adding the user.'
                    console.log('resp catch: ', errorDetail, errorTitle, resp);
                }
            },
            fetchFirstPage(){
                if(this.firstUrl)
                    this.fetchUsers(this.firstUrl);
            },
            fetchNextPage(){
                if(this.nextUrl)
                    this.fetchUsers(this.nextUrl);
            },
            showAddUser(){
                this.focusedMode = 'addUser'
                this.addPermissions = []
            },            
            toggleAddPermissions(permission, evt){
                if(evt.target.checked){
                    if(this.addPermissions.indexOf(permission) == -1)
                        this.addPermissions.push(permission);
                }else{
                    let i = this.addPermissions.indexOf(permission)
                    if(i > -1)
                        this.addPermissions.splice(i, 1);
                }
            },
            async editMember(memb){
                this.focusedId = memb.id;
                this.focusedMode='editInfo'
                this.focusedUser = memb
                this.editPass = '';
                this.editEmail = memb.attributes.email
                this.editTitle = memb.attributes.title
                this.editUsername = memb.attributes.username
                this.addPermissions = memb.attributes.permissions ? memb.attributes.permissions : []

                //Update permission checkboxes
                await new Promise(resolve => setTimeout(resolve, 5)); //Delay so checkbox refs aren't null
                for(var ii = 0; ii < this.addPermissions.length; ii++){
                    let ref = this.$refs['editPerm_' + this.focusedId + '_' + this.addPermissions[ii]]
                    ref[0].checked = true;
                }
            },
            editPassword(memb){
                this.focusedId = memb.id;
                this.focusedMode='editPassword'
                this.focusedUser = memb
                this.editPass = '';
                this.editEmail = ''
                this.editTitle = ''
                this.editUsername = ''
            },
            async fetchPermissions(){
                let fetched = await this.$fapi.get("permissions");
                this.permissions = fetched.data
            },
            /* Fetch all members */
            async fetchUsers(url){
                if(!url) url = 'admins';
                let fetched = await this.$fapi.get(url);
                this.members = fetched.data.data
                this.nextUrl = fetched.data.links.next
                this.firstUrl = fetched.data.links.first
                this.totalMembers = fetched.data.meta.total;
                if(fetched.data.meta.filteredTotal != undefined)
                    this.totalMembers = fetched.data.meta.filteredTotal;

                var parser = new UAParser();
                
                //Format useragent data for each record
                for(var ii = 0; ii < this.members.length; ii++){                    
                    this.members[ii].info = {};
                    parser.setUA(this.members[ii].attributes.lastUserAgent)
                    let ua = parser.getResult()
                    
                    this.members[ii].info['os'] = ua.os.name
                    this.members[ii].info['browser'] = ua.browser.name
                    this.members[ii].info['device'] = ((ua.os.name == 'Windows' || ua.os.name == 'Mac OS' || ua.os.name == 'Linux') ? 'pc' : 'mobile')
                }
            },
            displayDate(date){                       
                let utc = new Date(date);
                
                const offset = utc.getTimezoneOffset();
                utc.setHours(utc.getHours() - (offset / 60))

                return utc.toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short"
                });  
            },
            /* Send api request to initiate member password reset */
            async deleteAccount(memb){
                
                //Get member id # from full
                let membId = memb.id.split('/')[3]
                try{
                    let resp = await this.$fapi.delete('admins/' +membId);
                    this.deleteStatus = resp.status
                    memb.deleted = true
                    console.log('deleteResp: ', resp)
                }catch(resp){
                    this.deleteStatus = resp.status
                    console.log('deleteResp.err: ', resp)
                }
            },
            async updateUserInfo(){
                let id = this.focusedId.split('/')[3]
                let data = {
                    data: {
                        attributes: {}
                    }
                };

                //Check if values changed, if so add to data
                if(this.editEmail != this.focusedUser.attributes.email)
                    data.data.attributes.email = this.editEmail
                
                if(this.editTitle != this.focusedUser.attributes.title)
                    data.data.attributes.title = this.editTitle
                
                if(this.editUsername != this.focusedUser.attributes.username)
                    data.data.attributes.username = this.editUsername

                data.data.attributes.permissions = this.addPermissions
            
                                
                try{
                    let resp = await this.$fapi.patch('admins/' + id, data);
                    this.focusedMode = '';
                    this.focusedId = -1;
                    this.notice = 'User has been updated';
                    this.noticeIcon = 'user';
                    this.fetchUsers()
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail) this.editError = errorDetail;
                    else if(errorTitle) this.editError = errorTitle;
                    else this.editError = 'There was an error updating the user.'
                    console.log('resp catch: ', errorDetail, errorTitle, resp);
                }
            },
            async resetPassword(){
                let id = this.focusedId.split('/')[3]
                                
                try{
                    let resp = await this.$fapi.post('admins/' + id + '/reset');
                    this.focusedMode = '';
                    this.focusedId = -1;
                    this.notice = 'User password has been reset';
                    this.noticeIcon = 'user';
                    this.fetchUsers()
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail) this.editError = errorDetail;
                    else if(errorTitle) this.editError = errorTitle;
                    else this.editError = 'There was an error resetting the user password.'
                    console.log('resp catch: ', errorDetail, errorTitle, resp);
                }
            }
        },
		created(){
            document.title = 'Members'
		}, 
        mounted(){
            this.fetchUsers()
            this.fetchPermissions()
        },
        setup(){            
            
            const editError = ref('');
            const editEmail = ref("")
            const editUsername = ref("")
            const editTitle = ref("")
            const editPass = ref('')

            const addError = ref('');
            const addTitle = ref("")
            const addUsername = ref("")
            const addPassword = ref("")
            const addPermissions = ref([])
            const addEmail = ref("")

            const notice = ref('')
            const noticeIcon = ref('')

            const focusedMode = ref("");
            const focusedId = ref(-1)
            const focusedUser = ref({})
            
            const members = ref([])
            const resetStatus = ref(-1)
            const deleteStatus = ref(-1)
            const totalMembers = ref(0)
            const page = ref(1);
            const membersPerPage = ref(10) //Used in calc of 'viewing xx-xx', doesn't determine number loaded
            const permissions = ref({})
            const nextUrl = ref('')
            const firstUrl = ref('')


            return { 
                addError,
             addTitle,
             addUsername,
             addPassword,
             addPermissions,
             addEmail,
                editError,
                firstUrl,
                focusedId,
                focusedMode,
                focusedUser,
                deleteStatus,
                editEmail,
                editPass,
                editTitle,
                editUsername,
                members,
                membersPerPage,
                nextUrl,
                notice,
                noticeIcon,
                page,
                permissions,
                resetStatus,
                totalMembers

             }

        }
	}
	
</script>
