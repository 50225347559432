<template>
    <div>
            [Notices]
    </div>
</template>

<script>

    import { ref } from 'vue'
    import { useStore } from 'vuex'

    import Form from '@/components/Form.vue'
    import Icon from '@/components/Icon.vue'
    import Field from '@/components/Field.vue'

    export default {
      name: 'Notices',
      components: {
          Form,
          Field,
          Icon
      },
      setup(){

          let store = useStore();

        return{
            store
        }    
      }
    }
</script>
