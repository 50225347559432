<template>
    <div class="w-full flex flex-col justify-between items-center">
        <div class="flex flex-col w-full lg:w-1/2">
             <Form title="Member Notice Message"
                submitText="Update"
                @submit="saveAlert()">
                <Field type="select"
                    label="Notice Level"
                    @change="checkShowOptions"
                    :formValue="fv.level"
                    :options="[
                        { value: '', label: 'No Notice' },
                        { value: 'news', label: 'News - Green' },
                        { value: 'notice', label: 'Notice - Yellow' },
                        { value: 'alert', label: 'Alert - Red' }
                    ]" />
                <Field v-if="showAlertOptions" type="text"
                    label="Notice Title"
                    :formValue="fv.title"
                     />
                <Field v-if="showAlertOptions" type="textarea"
                    label="Notice Text"
                    :formValue="fv.alert"
                     />
                <Field v-if="showAlertOptions" type="text"
                    label="Url When Clicked"
                    :formValue="fv.url"
                     />
            </Form>
        </div>              
	</div>
</template>

<script>
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
    import { ref } from 'vue'
    import { useRouter } from "vue-router"

	export default {
		components:{
			Form,
			Field
		},
		data(){
			return {
			};
		},
        methods: {
            checkShowOptions(evt){
                if(evt.target.value == ''){
                    this.showAlertOptions = false
                }else{
                    this.showAlertOptions = true
                }
            },
            async saveAlert(){   
                
                //If 'no alert' selected, clear out values                
                if(this.fv.level.value == ''){
                    this.fv.title.value = '';
                    this.fv.url.value = '';
                    this.fv.alert.value = '';
                }

                let data = {
                    data: {
                        attributes: {
                            alert: {
                                title: this.fv.title.value,
                                url: this.fv.url.value,
                                level: this.fv.level.value,
                                alert: this.fv.alert.value,
                            }
                        }
                    }
                }

                //Update in store
                this.$store.state.fi.alert = data.data.attributes.alert;

                await this.$fapi.patch("", data)
            }
        },
		mounted(){
            //Check for setting form values
            if(this.$store.state.fi.alert){
                this.fv['alert'].value = this.$store.state.fi.alert.alert
                this.fv['level'].value = this.$store.state.fi.alert.level
                this.fv['title'].value = this.$store.state.fi.alert.title
                this.fv['url'].value = this.$store.state.fi.alert.url

                if(this.$store.state.fi.alert.level == '')
                    this.showAlertOptions = false
            }
		}, 
        setup(){
            
            const showAlertOptions = ref(true)

			/* Form field values */
			let fv = {}
            fv['alert'] = ref('')
            fv['level'] = ref('')
            fv['title'] = ref('')
            fv['url'] = ref('')
            

            return { 
				fv,
                showAlertOptions
            }

        }
	}
	
</script>
