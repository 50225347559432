<!-- 'BottomBar': Fills full screen with member controls from the top and nav options the bottom. Gives enough room at bottom to still show mobile footer nav-->
<template>
    <div :class="`z-50 fixed h-screen w-full overflow-y-auto flex flex-col justify-between ${bottomPadding}`">

        <!--Member control area-->
        <transition name="down">
            <div v-if="showMemberControls" :class="`${hasNotch ? 'pt-10' : ''} flex flex-col w-full mn3-navbar pb-4`">
                <!--Active name/swap-->
                <div
                    @click="showAccountSelect = !showAccountSelect"
                    :title="hasSubAccounts ? 'Account Select / Logout' : 'Account Select / Logout'" 
                    :class="`${hasSubAccounts ? 'cursor-pointer' : ''} text-sm uppercase flex flex-row items-center justify-between pt-2 pb-4 px-2 leading-3`">
                    <Icon :id="hasSubAccounts ? 'user-group' : 'user-circle'" class="h-10 w-10 mr-2" />
                    <div class="flex flex-col flex-grow">
                    <div class="font-bold text-lg">{{activeAccount.name}}</div>
                    <div class="text-sm">{{activeAccount.displayId}}</div>

                    </div>
                    <Icon :id="showAccountSelect ? 'chev-up' : 'chev-down'" class="h-8 w-8" />
                </div>

                <!--Member Control Buttons-->
                <div v-if="!showAccountSelect" class="flex flex-row w-full">      
                    <div v-if="$store.state.fi.messagingEnabled" class="w-1/3  flex-grow text-center duration-200 cursor-pointer pt-2 flex flex-col mn3-content-hover"
                        @click="memberControl('messages')"
                        :title="unread > 0 ? unread + ' Unread Messages' : 'Secure Messages'">                                
                        <Icon 
                            :id="unread > 0 || true ? 'envelope-open' : 'envelope'" class="h-7 w-7 mx-auto" />
                        <div class="text text-sm font-bold uppercase flex flex-row justify-center items-center">       
                            <div v-if="unread > 0" class="mn3-alert h-5 w-5 rounded-full leading-5 text-xs mr-1">{{unread}}</div>
                            <span>Messages</span>
                        </div>                        
                    </div>                      
                    <div class="w-1/3 flex-grow text-center duration-200  cursor-pointer py-2 flex flex-col mn3-content-hover"
                        @click="memberControl('settings')"
                        title="Account Settings">                                
                        <Icon id="cog" class="h-7 w-7 mx-auto" />
                        <span class="text text-sm font-bold uppercase">Settings</span>
                    </div>
                    <div class="w-1/3 flex-grow text-center duration-200  cursor-pointer py-2 flex flex-col mn3-content-hover"
                        @click="memberControl('devices')"
                        title="Saved Devices">                                
                        <Icon id="mobile" class="h-7 w-7 mx-auto" />
                        <span class="text text-sm font-bold uppercase">Devices</span>
                    </div>
                </div>
                
                <!--Related account select-->
                <div v-else
                    class="w-full flex flex-col pb-0 mn3-navbar">

                    <div v-for="acct in relatedAccounts" :key="acct.name"
                        class="w-full mn3-content-hover pr-3 py-2 border-b hover:pr-4 hover:opacity-100 hover:font-bold px-2 leading-4 duration-300 cursor-pointer flex justify-start items-center"
                        @click="manageAccount(acct.id)"> 
                            
                        <div class="flex-shrink-0">
                            <div :class="`${acct.id == activeAccountId ? 'opacity-100 border-2' : 'opacity-60 border'} text-xs h-10 w-10 rounded-full text-center font-bold mr-2`">
                                <Icon :id="acct.id == activeAccountId ? 'user-solid' : 'user-line'" class="h-8 w-8 mx-auto" />
                            </div>
                        </div>
                        <div class="flex flex-col leading-3">
                            <p :class="`${(acct.id === activeAccountId ? ' font-bold' : '')} text-lg`" 
                                :title="`${(acct.id === activeAccountId ? 'Currently Managing This Account' : 'Manage This Account')}`">{{acct.name}}</p>
                            <p class="font-mono text-xs text-left">{{acct.displayId}}</p>
                        </div>     
                    </div>
                    <!--Logout-->
                    <div class="flex flex-row items-center justify-center mn3-alert px-2 py-4 duration-300 cursor-pointer"
                            @click.prevent="$authapi.logout">
                        <span class="font-bold uppercase">Logout</span>
                        <Icon id="logout" class="h-6 w-6 ml-2" />
                    </div>
                </div>
                
            </div>         

        </transition>
        <!--If tall screen, display first group as a slideshow-->               
        <div v-if="isTallScreen && groups != undefined & groups[0] != undefined" class="w-full flex flex-col justify-center">
            <Slideshow 
                :slides="groupLinks(groups[0].id)" 
                :showSlideSelect="false"
                :showProgress="true" 
                class="mx-5"/>

            <Button label="View All Additional Services"
                @click="this.$router.push('links');this.closeMenu()"
                icon="chev-right"
                class="mx-6 mt-2"/>
        </div>

        <!--Navigation menu-->
        <transition name="bar">
            <div v-if="showMemberControls" class="w-full flex flex-col">
                <!-- Standard custom links header -->                 
                <div v-if="!hasNotch && secondaryNavTrimmed.length > 0"
                    class="mn3-navbar px-2 py-3 text-lg font-bold uppercase flex flex-row items-center justify-between">
                    <Icon id="map" class="h-6 w-6 mr-2" />
                    <span>Navigation</span>
                    <div class="flex-grow" />
                    <button v-if="this.$store.state.fi.alert && this.$store.state.noticeClosed"
                        title="View sitewide notice"
                        @click="this.$store.state.noticeClosed = false;closeMenu();"
                        :class="`${noticeClass} self-stretch py-0 px-3 rounded-sm ml-2`">            
                        <Icon id="info" class="h-5 w-5" />
                    </button>
                </div> 
                <!-- Secondary Nav Links -->
                <div id="custom-container" class="w-full flex flex-col overflow-y-auto">       
                    <!--If FI has RDC enabled, add MainNav3 option here unless it is blank-->
                    <div v-if="rdcEnabled && $store.state.fi.mainNav3 != '' && $store.state.fi.mainNav3 != undefined"
                        @click="mainNavClicked($store.state.fi.mainNav3);closeMenu();"
                        :class="`mn3-content-hover block py-4 px-3 border-b flex flex-row items-center cursor-pointer`">
                        <div class="w-full">
                            <div class="w-full flex items-center justify-start h-8">
                                <Icon :id="mainNavIcon($store.state.fi.mainNav3)" class="h-6 w-6 mr-2 relative mt-1" />
                                <div class="flex flex-col">
                                    <div class="leading-3">{{mainNavTitle($store.state.fi.mainNav3)}}</div>
                                </div>
                            </div>
                        </div>                        
                    </div>               
                    <div @click="navClicked(link.url);closeMenu();"
                        v-for="(link) in secondaryNavTrimmed"
                        :key="link.url"
                        :class="`mn3-content-hover block py-4 px-3 border-b flex flex-row items-center cursor-pointer`">
                        <div class="w-full">
                            <div class="w-full flex items-center justify-start h-8">
                                <Icon :id="link.icon == undefined ? 'export' : link.icon" class="h-6 w-6 mr-2 relative mt-1" />
                                <div class="flex flex-col">
                                    <div class="leading-3">{{link.title}}</div>
                                    <div v-if="link.desc != undefined" class="text-xs mono font-normal leading-3">{{link.desc}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services link if enabled and not a tall screen. Shown above with Services section if tall screen-->                
                <router-link v-if="$store.state.fi.linksPageEnabled && !isTallScreen" 
                    to="/links" @click="closeMenu()" class="mn3-content-hover block py-4 px-3 border-b flex flex-row items-center">
                    <Icon class="h-7 w-6 mr-3" id="link" />
                    <p>Additional Services</p>
                    <div class="flex-grow"></div>
                    <Icon class="h-7 w-6 mr-3 opacity-60" id="chev-right" />
                </router-link>
                
                <!--Logout-->
                <div class="flex flex-row items-center justify-center mn3-alert px-2 py-4 duration-300 cursor-pointer"
                        @click.prevent="$authapi.logout">
                    <span class="font-bold uppercase">Logout</span>
                    <Icon id="logout" class="h-6 w-6 ml-2" />
                </div>
            </div>
        </transition>
    </div>
</template>


<script>

import { ref } from "vue"

import Button from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import Slideshow from "@/components/Slideshow.vue";

export default {
    name: "MobileNav",
    components: {
        Button,
        Icon,
        Slideshow
    },
    computed: {
        activeAccount(){            
            for(var key in this.relatedAccounts){
                let acct = this.relatedAccounts[key];
                if(acct.id == this.activeAccountId)
                    return acct;
            }
            return {};
        },
        bottomPadding(){
            if(this.$store.state.isIos){
                return ' pb-20 ';
            }
            return ' pb-16 ';
        },
        hasSubAccounts(){
            return Object.keys(this.relatedAccounts).length > 0;
        },
        isTallScreen(){
            return window.innerHeight >= 800;
        },              
        noticeClass(){
            if(!this.$store.state.fi.alert) return '';
            switch(this.$store.state.fi.alert.level){
                case 'news':
                default:
                    return 'mn3-approved';

                case 'alert':
                    return 'mn3-alert';

                case 'notice':
                    return 'mn3-highlight';
            }
        },
        rdcEnabled(){            
			let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
			let membStatus = "A";
			if(mship)
				membStatus = mship.attributes.status;			

            return this.$store.state.fi.rdcEnabled && membStatus != 'R' && membStatus != 'B'
        },
        // Remove the services link from secondary nav as auto added
        secondaryNavTrimmed(){
            let ret = [];
            for(var ii = 0; ii < this.$props.secondaryNav.length; ii++){

                let l = this.$props.secondaryNav[ii];
                if(l.url != 'links')
                    ret.push(l);
            }

            return ret;
        }
    },
    methods: {    
        async closeMenu(){
            this.showMemberControls = false;
            this.showAccountSelect = false;

            //Slight delay for items to fade first
            await new Promise(resolve => setTimeout(resolve, 100));  
            this.$store.state.isMobileNavOpen = false;
        },
        groupLinks(groupId){
            let links = [];
            for(var ii = 0; ii < this.serviceLinks.length; ii++){
                if(this.serviceLinks[ii].attributes.group == groupId)
                    links.push(this.serviceLinks[ii]);
            }
            return links;
        },
        async loadServiceLinks(){
            
            let links_page = await this.$pfapi.get('links_page');
            this.serviceLinks = links_page.data.data
            
            let links_page_groups = await this.$pfapi.get('links_page_groups');
            this.groups = this.parseGroupData(links_page_groups.data.data);
        },
        /* Load related sub accounts for logged in user */
        loadRelatedAccounts(){
            this.relatedAccounts = {};
            let data = JSON.parse(JSON.stringify(this.$store.state.memberships))
            let activeFound = false;
            let mainId = 0;
            let mainName = '';

            for(var ii = 0; ii < data.length; ii++){
                let atrb =  {...data[ii]}["attributes"]
                
                //Check for setting default account
                if(atrb.id == this.activeAccountId){
                    activeFound = true;
                }
                    
                if(atrb.default){
                    mainId = atrb.id
                    mainName = atrb.title
                }
                this.relatedAccounts[atrb.id] = {name: atrb.title, notice: 0, default: atrb.default, id: atrb.id, displayId: atrb.displayId};
            }

            //If no active account, set to default
            if(!activeFound){
                this.activeAccountId = mainId
            }
            
            //Check for returning to related account on reload            
            if(localStorage['membership_id'] != undefined && localStorage['membership_id'] != ''){
                let restoreId =  localStorage['membership_id'];
                if(restoreId != this.activeAccountId && this.relatedAccounts[restoreId] != undefined
                    && this.$store.state.membershipId != restoreId){
                    this.manageAccount(restoreId);
                }
            }
        },
        /* Select a different account to manage */
        manageAccount(id){
            
            if(this.$store.state.membershipId == id){
                return;
            }else{
                this.$store.commit("setMembershipId", id);
                localStorage['membership_id'] = id;
                this.activeAccountId = id
                this.loadRelatedAccounts()
                this.$router.push('/accounts')  
                this.closeMenu();
            }
        },  
        parseGroupData(data){
            let ret = [];
            for(var ii = 0; ii < data.length; ii++){
                let g = data[ii];
                //Parse slideshow setting from name
                let split = g.attributes.title.split('__');
                g.attributes.titleUse = split[0];
                if(split.length > 1 && split[1] == 'slide'){
                    g.mode = 'slideshow';
                    g.delay = 5;
                    g.active = 1;
                    g.size = 3;
                }
                ret.push(g);                            
            }
            return ret;
        },
        navClicked(url){
            if(url.indexOf('http://') != -1 || url.indexOf('https://') != -1)
                window.open(url);            
            else
                this.$router.push(url);
        },
        mainNavClicked(screen){        
            switch(screen){

                case 'accounts':
                    this.$router.push('/accounts');
                break;
                
                case 'transfer':
                    this.$router.push('/transfer');
                break;
                
                case 'statements':
                    this.$router.push('/statements');
                break;

                case 'allied':
                    this.$router.push('/allied');
                break;
                
                case 'allied2':
                    this.$router.push('/allied2');
                break;
                
                case 'ipay':
                    this.$router.push('/ipay');
                break;
                
                case 'neuralpay':
                    this.$router.push('/neuralpay');
                break;

            }
            return;
        },
        mainNavIcon(screen){        
            switch(screen){

                case 'accounts':
                return 'dollar';
                
                case 'transfer':
                return 'transfer';
                
                case 'statements':
                return 'document';

            }
            return screen;
        },
        mainNavTitle(screen){
            switch(screen){

                case 'allied':
                return this.$store.state.fi.alliedTitle;
                
                case 'allied2':
                return this.$store.state.fi.allied2Title;

                case 'neuralpay':
                return this.$store.state.fi.neuralpayTitle;

                case 'accounts':
                return 'Accounts';
                
                case 'transfer':
                return 'Transfer';
                
                case 'statements':
                    if(this.$store.state.fi.statementsEnabled && this.$store.state.fi.noticesEnabled )
                        return 'Statements & Notices';                
                    else if(this.$store.state.fi.statementsEnabled)
                        return 'Statements';                
                    else if(this.$store.state.fi.noticesEnabled)
                        return 'Notices';  
            }
            return screen;
        },
        memberControl(screen){

            switch(screen){
                case 'messages':
                    this.$router.push('/messages');
                    this.closeMenu();
                    break;
                    
                case 'settings':
                    this.$router.push('/settings');
                    this.closeMenu();
                    break;
                    
                case 'devices':
                    this.$router.push('/devices');
                    this.closeMenu();
                    break;
            }
        }
    },
    props:{    
        hasNotch:{
            type: Boolean,
            default: false
        },
        mainNav:{            
            type: Object,
            default: []
        },
        secondaryNav:{
            type: Object,
            default: []
        },
        additionalNav:{
            type: Object,
            default: []
        },
        customLinks: {
            type: Object,
            default: []
        }, 
        isLoggedIn: {                         //isLoggedIn: If user is currently logged in
            type: Boolean,
            default: true
        },
        navLinks: {                           //navLinks: Nav links displayed
            type: Object,
            default: []
        }
    },
    created(){
        this.activeAccountId = this.$store.state.membershipId;
        this.loadRelatedAccounts();

        //If a tall screen, load service links as well
        if(this.isTallScreen){
            this.loadServiceLinks();
        }
    },
    mounted(){
        this.showMemberControls = true;
    },  
    setup( props ){        
        const activeAccountId                   //activeAccountId: ID of currently active account
            = ref('')
        const relatedAccounts = ref({})     //relatedAccounts: Related accounts that can be managed
        const showMemberControls = ref(false)
        const showAccountSelect = ref(false)
        const serviceLinks = ref([]);
        const groups = ref([]);
        const unread = ref(0);

        return{
            activeAccountId,
            groups,
            serviceLinks,
            showAccountSelect,
            showMemberControls,
            unread
        }
    }
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0px;
  opacity: 0.5;
}
</style>
