<template>
    <div>
    </div>
</template>
<script>
import { useRouter } from "vue-router"

export default{
    setup(){
        const router = useRouter();         //router: router ref
        setTimeout(() => {router.go(-1);}, 5);
        
        return {
        }
    }
}
</script>