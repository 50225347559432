<template>
    <div class="w-full flex flex-col lg:flex-row justify-between">

        <Form 
            :title="`Unlock Accounts`" 
            :class="`mx-auto w-full flex-grow max-w-screen-md`"
            :showSubmit="false"
            :showCancel="false">
            <div v-for="l in locked" :key="l.attributes.value" 
                :class="`${focusedLock == l.attributes.value ? 'mn3-cancel' : ''} w-full flex flex-col justify-between items-center p-2 border-b`">
                <div v-if="focusedLock == l.attributes.value" class="w-full text-center text-base underline">Are you sure you want to clear this lock?</div>
                    <div class="w-full flex flex-row justify-between items-center">
                    <div class="w-1/3 font-bold flex flex-row items-center uppercase">
                        <Icon :id="typeToIcon(l.attributes.type)" class="h-6 w-6 mr-2" />{{l.attributes.type}} lock</div>
                    <div class="w-1/3">"{{l.attributes.value}}" - Expires in {{l.attributes.ttl}}s</div>
                    <div v-if="focusedLock != l.attributes.value" class="w-1/3 flex justify-end">
                        <Button @click="focusedLock = l.attributes.value" label="Clear Lock" icon="lock-open-solid" />
                    </div>
                    <div v-else  class="w-1/3 flex justify-end">
                        <Button @click="unlock(l.id)" label="Clear" />
                        <Button @click="focusedLock = ''" label="Cancel" />
                    </div>
                </div>
            </div>

        </Form>
		
	</div>
</template>

<script>
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Icon from "@/components/Icon.vue";
    import { ref } from 'vue'

	export default {
		components:{
            Button,
			Form,
			Field,
            Icon
		},
		data(){
			return {
			};
		},
        methods: {
            async loadLockedMembers(){
                let fetched = await this.$fapi.get("rl/locks");
                this.locked = fetched.data.data;
            },
            typeToIcon(type){
                switch(type){
                    case'username':
                        return 'user-solid';

                    case 'ip':
                        return 'pc';
                }

                return 'lock';
            },  
            async unlock(id){             
                await this.$fapi.delete(encodeURIComponent("rl/locks/" + id));
                this.focusedLock = '';
                this.loadLockedMembers();
            }
        },  
		created(){
            document.title = 'Unlock Accounts'
            this.loadLockedMembers();
		}, 

        setup(){
            
            const locked = ref([]);

			/* Form field values */
			let fv = {}
            const focusedLock = ref('')

            return { 
                focusedLock,
				fv,
                locked
            }

        }
	}
	
</script>
