<template>
    <div v-if="error != ''"
    class="mx-auto mn3-alert p-2 my-2 rounded-sm flex flex-row items-center">
        <Icon id="exclamation" class="h-6 w-6 mr-2" />
        <span>{{error}}</span>        
    </div>
    <div class="max-w-screen-lg w-full mx-5 mt-24">
        <div v-if="neuralpayUrl != ''" class="p-2 cursor-pointer mn3-button mn3-submit rounded-sm block flex" @click="openUrl">
            <Icon class="h-6 w-6 mr-2" id="export" />Please click here to open {{$store.state.fi.neuralpayTitle}}.
        </div>
    </div>
</template>
<script>
    import { useRouter } from "vue-router"
    import { ref, computed } from 'vue'

    import Icon from '@/components/Icon.vue'

export default{
    components:{
        Icon
    },
    props:{
    },
    async created(){
        document.title= this.$store.state.fi.neuralpayTitle;

        //Check for exiting screen if not enabled
        if(!this.$store.state.fi.neuralpayEnabled){
            this.$router.push('/');
        }else{
            this.fetchNeuralpay();
        }
    },
    methods: {
        async fetchNeuralpay(){
            try{
                var fetched = await this.$mapi.get("neuralpay-sso")
                this.neuralpayUrl = fetched.data.data.attributes.url;  
            }catch(e){
                if(e.response && e.response.data && e.response.data.errors){
                  this.error = e.response.data.errors[0].detail;
                }else{
                  this.error = e;
                }
            }   
        },
        openUrl(){
            window.open(this.neuralpayUrl);
        }
    },
    setup(){
        const neuralpayUrl = ref("");
        const error = ref("");

        return {
            error,
            neuralpayUrl
        }
    }
}
</script>
