<!-- InputToggle: A toggleable field input that is either a Y or N -->
<template>
    <div class="val val--input">
        <div @click="toggleValue" :class="`border p-1 flex justify-start items-center leading-4 rounded cursor-pointer 
            ${(input !== 'Y' ? 'line-through hover:underline mn3-disabled' : 'font-bold')}`">
            <svg v-if="input === 'Y'" class="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
            <svg v-if="input !== 'Y'" class="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
            {{label}}
        </div> 
        <input type="hidden" :id="id" v-model="input" />
    </div>
</template>
<script>

import { ref, computed } from "vue";

export default{
    props:{
        /* Properties without defaults */
        id: String,                     //id: ID assigned to the hidden input element
        label: String,                  //label: Label displayed on the toggle

        /* Properties with defaults */
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
    },
    methods:{        
        focus(){
            console.log('InputToggle doesnt support focus');
        },        
    },
    setup(props, { emit }){
        
        /* --Variables-- */
        const val = ref(props.formValue);   //val: Used in computed input property
        
        /* --Computed-- */        
        const input                         //input: holds input value, emits updated event on change
            = computed({
            get: () => val.value,
            set: (v) => (val.value = v,emit('updated', formValue => Object.keys(formValue)[0], v))
        })

        /* --Functions-- */     
        // toggleValue: Used on a toggle switch to swap between Y and N
        function toggleValue(){
            if(input.value == 'Y')
                input.value = 'N';
            else
                input.value = 'Y';
        }

        return {
            input,
            toggleValue
        }
    }
}

</script>