<template>

    <div class="w-full flex flex-col lg:flex-row justify-between">
        <div class="flex flex-col w-full lg:w-1/2">

        </div>
        <div class="flex flex-col w-full lg:w-1/2">

        </div>
    </div>
  <div class="w-full mx-auto flex flex-col">
    <!--
    <router-link to="/admin/ficolor">
    <div class="mn3-button flex-shrink p-2 rounded-sm border flex "><Icon id="template" class="h-6 w-6" />Edit FI Colors</div></router-link>-->

    <div class="w-full flex justify-end text-right">
        <div class="fixed flex flex-row justify-end mn3-content rounded border z-50 top-2">
            <div class="mn3-button mn3-submit px-2 py-1 rounded-l uppercase cursor-pointer">Save</div>
            <div class="mn3-button mn3-cancel px-2 py-1 rounded-r uppercase cursor-pointer">Cancel</div>
        </div>
    </div>

    <div class="w-full flex flex-col lg:flex-row justify-between">

        <div class="flex flex-col w-full lg:w-1/2">

        <Form title="FI Information" :showSubmit="false">
                <Field
                    type="text"
                    label="Credit Union Name"
                    :required="true"
                    :formValue="fv.name"
                />

                <Field
                    type="select"
                    label="FI Type"
                    :formValue="fv.fiType"
                    :options="[
                    { value: 'batch', label: 'Batch' },
                    { value: 'live', label: 'Live' },
                    ]"
                />
                <Field
                    type="text"
                    label="Data URL"
                    :formValue="fv.url"
                />
                <Field
                    type="text"
                    label="Credit Union Member URL Variable"
                    :formValue="fv.urlVar"
                >
                    <div class="text-xs text-left w-fit mx-auto">
                    <p class="mb-2">
                        This will be used to identify the credit union in the url.
                    </p>
                    </div>
                </Field>
                <Field
                    type="select"
                    label="API Environment"
                    :formValue="fv.api"
                    :options="[
                    { value: 'production', label: 'Production' },
                    { value: 'staging', label: 'Staging' },
                    ]"
                />
                <Field
                    type="text"
                    label="Credit Union RTN"
                    :formValue="fv.rtn"
                    format="number"
                    :digits="3"
                />
            </Form>

            <Form title="Notification Settings" :showSubmit="false">
                <Field
            type="text"
            label="Primary Contact Email"
            :formValue="fv.primaryContact"
            
          />
          <Field
            type="text"
            label="E-Statement Change Request Notification Email"
            :formValue="fv.estEmail"
            
          />
          <Field
            type="text"
            label="Contact Us Email"
            :formValue="fv.contactUs"
            
          />
          <Field
            type="text"
            label="New Loan Application Email"
            :formValue="fv.loanApp"
            
          />
          <Field
            type="text"
            label="Member Email Rejection Notification Email"
            :formValue="fv.rejection"
            
          />
            </Form>


            <Form title="Visual Customization" :showSubmit="false">

        <Field
            type="file"
            label="CU Logo"
            :formValue="fv.cu_logo"
            class="w-full"
          />       
          <Field
            type="file"
            label="CU Favicon"
            :formValue="fv.cu_favicon"
            class="w-full"
          />
            </Form>

<Form title="Features" :showSubmit="false">
    <div class="flex flex-row justify-between mt-1 pb-2 border-b">
          <Button
            v-for="cat in featureModes"
            :key="cat.key"
            :type="`${featureMode === cat.key ? 'focused' : 'button'}`"
            :label="cat.label"
            :padding="1"
            text="xs"
            @click="setFeatureCat(cat.key)"
          />
        </div>
        <transition name="route" mode="out-in">
        <div class="flex flex-wrap" v-if="featureMode === 'hold'">
          <Field
            type="toggle"
            label="Enable Hold Messages"
            :formValue="fv.holdMsg"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'check'">
          <Field
            type="toggle"
            label="Alloya"
            :formValue="fv.alloya"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Corporate America"
            :formValue="fv.corporateAmerica"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Fiserv"
            :formValue="fv.fiserv"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Mid-Atlantic"
            :formValue="fv.midAtlanic"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Suncorp"
            :formValue="fv.suncorp"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="VSOFT"
            :formValue="fv.vsoft"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Corporate One"
            :formValue="fv.corpOne"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="IBT Imaging"
            :formValue="fv.ibt"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="East Shared Financial Solution"
            :formValue="fv.esfs"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Catalyst"
            :formValue="fv.catalyst"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="My CU Services"
            :formValue="fv.myCuServices"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'statements'">
          <Field
            type="toggle"
            label="E-Statements on Related Accounts"
            :formValue="fv.estRel"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="E-Notices"
            :formValue="fv.enot"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="E-Statements"
            :formValue="fv.est"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="E-Statements Agreement"
            :formValue="fv.estAgr"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === '3rd'">
          <Field
            type="toggle"
            label="iPay Bill Pay"
            :formValue="fv.ipay"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Co-Op eCom Bill Pay"
            :formValue="fv.coopEcom"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Money Desktop"
            :formValue="fv.moneyDesktop"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'mobile'">
          <Field
            type="toggle"
            label="Mobile App"
            :formValue="fv.mobileApp"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Remote Deposit"
            :formValue="fv.removeDeposit"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'pay'">
          <Field
            type="toggle"
            label="Transfers"
            :formValue="fv.transfers"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Withdrawal Counter"
            :formValue="fv.withdrawalCounter"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Quick Transfers"
            :formValue="fv.quickTrans"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="ACH Quick Transfers"
            :formValue="fv.achQuickTrans"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Recurring Transfers"
            :formValue="fv.recurringTrans"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Dual Transactions"
            :formValue="fv.dualTransactions"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Card Payments"
            :formValue="fv.cardPayments"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Saved Accounts"
            :formValue="fv.savedAccts"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Export Transactions"
            :formValue="fv.exportTrans"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="ACH Origination"
            :formValue="fv.achOrigination"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Redeem / Reallocate Investments"
            :formValue="fv.reInv"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="New Investments"
            :formValue="fv.newInv"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Mail Check"
            :formValue="fv.mailCheck"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Kwik Cash"
            :formValue="fv.kwikCash"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Trinergy Autopost Module"
            :formValue="fv.trinergyAuto"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'misc'">
          <Field
            type="toggle"
            label="Account Applications"
            :formValue="fv.acctApp"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Account Lockouts"
            :formValue="fv.acctLock"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="E-Automoation"
            :formValue="fv.eAuto"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Organizational Accounts Only"
            :formValue="fv.orgAcctOnly"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Multiple Logins Enabled"
            :formValue="fv.multLogin"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Private Messaging Enabled"
            :formValue="fv.privateMsg"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Device Authorization"
            :formValue="fv.deviceAuth"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Full Description on History"
            :formValue="fv.fullDescOnHist"
            class="w-1/2"
            bg="bg-gray-300"
          />
        </div>

        <div class="flex flex-wrap" v-else-if="featureMode === 'charity'">
          <Field
            type="toggle"
            label="Enabled"
            :formValue="fv.charityDonations"
            class="w-1/2"
            bg="bg-gray-300"
          />
          <Field
            type="toggle"
            label="Allow members to request charities to be added"
            :formValue="fv.requestChar"
            class="w-1/2"
          />
          <Field
            type="text"
            label="New Donation &amp; Transfer Alert Email"
            :formValue="fv.donEmail"
            class="w-full"
          />
        </div>
        </transition>
</Form>

        </div>
        
        <div class="flex flex-col w-full lg:w-1/2">
            <Form title="FI Address" :showSubmit="false">
                <Field
            type="select"
            label="Country"
            :formValue="fv.country"
            
            :options="[
              { value: 'us', label: 'United States' },
              { value: 'ca', label: 'Canada' },
              { value: 'tt', label: 'Trinidad' },
              { value: 'jm', label: 'Jamaica' },
            ]"
          />

          <Field
            type="text"
            label="Address"
            :formValue="fv.address"
            
          />
          <Field
            type="text"
            label="City"
            :caps="true"
            :formValue="fv.city"
            
          />
          <Field
            type="text"
            label="State/Providence"
            :caps="true"
            :formValue="fv.state"
            
          />
          <Field
            type="text"
            label="Zip Code"
            format="number"
            :digits="5"
            :formValue="fv.zip"
            
          />
            </Form>

            <Form title="FI Contact Information" :showSubmit="false">
            <Field
            type="text"
            label="Phone Number"
            format="number"
            :digits="10"
            :formValue="fv.phone"
            
          />
          <Field
            type="text"
            label="Fax"
            format="number"
            :digits="10"
            :formValue="fv.fax"
            
          />
          <Field
            type="text"
            label="Homepage URL"
            :formValue="fv.homeUrl"
            
          />

            </Form>




            <Form title="Additional Customization" :showSubmit="false">
                <Field
            type="text"
            label="Branch Locator URL"
            :formValue="fv.urlBranch"
            
          />
          <Field
            type="text"
            label="Privacy Policy URL"
            :formValue="fv.urlPrivacy"
            
          />
          <Field
            type="text"
            label="Terms of Service URL"
            :formValue="fv.urlTos"
            
          />
          <Field
            type="text"
            label="Custom Link #1 - URL"
            :formValue="fv.url_c1_url"
            
          />
          <Field
            type="text"
            label="Custom Link #1 - Name"
            :formValue="fv.url_c1_name"
            
          />
          <Field
            type="text"
            label="Custom Link #2 - URL"
            :formValue="fv.url_c2_url"
            
          />
          <Field
            type="text"
            label="Custom Link #2 - Name"
            :formValue="fv.url_c2_name"
            
          />
          <Field
            type="text"
            label="Custom Link #3 - URL"
            :formValue="fv.url_c3_url"
            
          />
          <Field
            type="text"
            label="Custom Link #3 - Name"
            :formValue="fv.url_c3_name"
            
          />
            </Form>
        </div>

    </div>


        
  </div>
</template>

<script>
import { ref, computed } from "vue";

import Button from "@/components/Button.vue";
import Form from "@/components/Form.vue";
import Icon from "@/components/Icon.vue";
import Menu from "@/components/Menu.vue";
import MenuOption from "@/components/MenuOption.vue";
import Field from "@/components/Field.vue";
import TopBar from "@/components/TopBar.vue";

export default {
  name: "FiSettings",
  components: {
    Button,
    Form,
    Field,
    Icon,
    Menu,
    MenuOption,
    TopBar,
  },
  created(){
    document.title = 'FI Settings'
  },
  setup() {
    const featureMode = ref("hold");
    let featureModes = [
      {
        label: "Hold Messages",
        key: "hold",
      },
      {
        label: "Check Imaging",
        key: "check",
      },
      {
        label: "Statements",
        key: "statements",
      },
      {
        label: "3rd Party Vendors",
        key: "3rd",
      },
      {
        label: "Mobile",
        key: "mobile",
      },
      {
        label: "Payments",
        key: "pay",
      },
      {
        label: "Misc",
        key: "misc",
      },
      {
        label: "Charity",
        key: "charity",
      },
    ];

    function setFeatureCat(cat) {
      featureMode.value = cat;
    }

    /* Form field values */
    let fv = {};
    fv["fiType"] = ref("");
    fv["url"] = ref("");
    fv["urlVar"] = ref("");
    fv["api"] = ref("");
    fv["rtn"] = ref("");
    fv["country"] = ref("");
    fv["name"] = ref("");
    fv["address"] = ref("");
    fv["city"] = ref("");
    fv["state"] = ref("");
    fv["zip"] = ref("");
    fv["phone"] = ref("");
    fv["fax"] = ref("");
    fv["homeUrl"] = ref("");

    fv["logoSm"] = ref("");
    fv["logoLg"] = ref("");

    fv["colorBorder"] = ref("");

    fv["colorA"] = ref("");
    fv["colorTextA"] = ref("");

    fv["colorB"] = ref("");
    fv["colorTextB"] = ref("");

    fv["colorTable"] = ref("");
    fv["colorTextTable"] = ref("");

    fv["colorInput"] = ref("");
    fv["colorTextInput"] = ref("");

    fv["colorTools"] = ref("");
    fv["colorTextTools"] = ref("");

    fv["colorBG"] = ref("");
    fv["colorTextBG"] = ref("");

    fv["colorButton"] = ref("");
    fv["colorTextButton"] = ref("");

    fv["colorSubmit"] = ref("");
    fv["colorTextSubmit"] = ref("");

    fv["colorCancel"] = ref("");
    fv["colorTextCancel"] = ref("");

    fv["colorCell"] = ref("");
    fv["colorTextCell"] = ref("");

    fv["primaryContact"] = ref("");
    fv["estEmail"] = ref("");
    fv["contactUs"] = ref("");
    fv["loanApp"] = ref("");
    fv["rejection"] = ref("");

    fv["holdMsg"] = ref("N");

    fv["cu_logo"] = ref();
    fv["cu_favicon"] = ref();

    fv["holdMsg"] = ref("N");
    fv["estRel"] = ref("N");
    fv["enot"] = ref("N");
    fv["est"] = ref("N");
    fv["estAgr"] = ref("N");

    fv["urlBranch"] = ref("");
    fv["urlPrivacy"] = ref("");
    fv["urlTos"] = ref("");
    fv["url_c1_url"] = ref("");
    fv["url_c1_name"] = ref("");
    fv["url_c2_url"] = ref("");
    fv["url_c2_name"] = ref("");
    fv["url_c3_url"] = ref("");
    fv["url_c3_name"] = ref("");
    fv["imgMarket"] = ref("");
    fv["imgUrl"] = ref("");
    fv["msgLogin"] = ref("");
    fv["msgAcct"] = ref("");
    fv["msgPass"] = ref("");

    fv["alloya"] = ref("N");
    fv["corporateAmerica"] = ref("N");
    fv["fiserv"] = ref("N");
    fv["midAtlanic"] = ref("N");
    fv["suncorp"] = ref("N");
    fv["vsoft"] = ref("N");
    fv["corpOne"] = ref("N");
    fv["ibt"] = ref("N");
    fv["esfs"] = ref("N");
    fv["catalyst"] = ref("N");
    fv["myCuServices"] = ref("N");

    fv["estRel"] = ref("N");
    fv["enot"] = ref("N");
    fv["est"] = ref("N");
    fv["estAgr"] = ref("N");

    fv["ipay"] = ref("N");
    fv["coopEcom"] = ref("N");
    fv["moneyDesktop"] = ref("N");

    fv["mobileApp"] = ref("N");
    fv["removeDeposit"] = ref("N");

    fv["mobileApp"] = ref("N");
    fv["removeDeposit"] = ref("N");
    fv["transfers"] = ref("N");
    fv["withdrawalCounter"] = ref("N");
    fv["quickTrans"] = ref("N");
    fv["achQuickTrans"] = ref("N");
    fv["recurringTrans"] = ref("N");
    fv["dualTransactions"] = ref("N");
    fv["cardPayments"] = ref("N");
    fv["savedAccts"] = ref("N");
    fv["exportTrans"] = ref("N");
    fv["achOrigination"] = ref("N");
    fv["reInv"] = ref("N");
    fv["newInv"] = ref("N");
    fv["mailCheck"] = ref("N");
    fv["kwikCash"] = ref("N");
    fv["trinergyAuto"] = ref("N");

    fv["acctApp"] = ref("N");
    fv["acctLock"] = ref("N");
    fv["eAuto"] = ref("N");
    fv["orgAcctOnly"] = ref("N");
    fv["multLogin"] = ref("N");
    fv["privateMsg"] = ref("N");
    fv["deviceAuth"] = ref("N");
    fv["fullDescOnHist"] = ref("N");

    fv["charityDonations"] = ref("N");
    fv["donEmail"] = ref("");
    fv["requestChar"] = ref("N");

    return {
      featureMode,
      featureModes,
      fv,
      setFeatureCat
    };
  },
};
</script>
