<template>
    <div class="w-full flex flex-col justify-between mx-auto max-w-screen-md">
        <Form :title="`Pending Credit Card Payments`" 
            :class="`flex-shrink-0 max-w-screen-sm w-full`"
            :showSubmit="false"
            :showCancel="false">        
            <div v-if="firstUrl || nextUrl" class="w-full flex flex-row mn3-content-alt rounded-sm overflow-hidden mb-2 justify-between items-center">
            <div @click="loadFirst()" :class="`${!firstUrl ? 'opacity-20' : '' } py-3 duration-150 w-1/3 p-2 mn3-content-alt-hover text-center cursor-pointer flex flex-row items-center justify-start`"><Icon id="chev-left" class="h-6 w-6" />First Page</div>
            <div @click="loadNext()" :class="`${!nextUrl ? 'opacity-20' : '' } py-3 duration-150  w-1/3 p-2 mn3-content-alt-hover cursor-pointer flex flex-row items-center justify-end`">Next Page<Icon id="chev-right" class="h-6 w-6" /></div>
        </div>

            <div v-for="p in payments" :key="p.id" 
                :class="`${confirmArchive == p.id ? 'mn3-denied' : 'mn3-content' } flex flex-row items-center px-4`">
                <Icon id="receipt" class="h-10 w-10 mr-4" />
                <div class="flex flex-col  flex-grow leading-5 py-1">
                    <div class="font-bold">{{p.attributes.name}}</div>
                    <div>{{p.attributes.fromAccount}} - ${{p.attributes.amount}}</div>
                    <div>Card #: {{p.attributes.last4}}</div>
                    <div>Sent on {{formatDate(p.attributes.created)}}</div>
                </div>
                <div class="flex flex-col">
                    <div v-if="confirmArchive == p.id" class="font-bold">Archive this payment?</div>
                    <div class="flex flex-row items-center">
                    
                        <!--Confirm Archive Buttons-->
                        <Button v-if="confirmArchive == p.id" @click="archivePayment(p.id)" label="Archive" icon="archive" title="Archive" />
                        <Button v-if="confirmArchive == p.id"  @click="confirmArchive = ''" class="ml-2" label="Cancel" icon="x"  title="Cancel" />

                        <!--Standard Buttons-->
                        <Button v-if="confirmArchive != p.id"  @click="confirmArchive = p.id;" class="ml-2" label="Archive" icon="archive"  title="Archive" />
                    </div>
                </div>
            </div>
        </Form>
	</div>
</template>

<script>
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Icon from "@/components/Icon.vue";
    import { ref } from 'vue'

	export default {
		components:{
            Button,
			Form,
			Field,
            Icon
		},
        methods:{
            async archivePayment(id){

                let split = id.split('/');                    
                if(split.length >= 4){
                    id = split[4];
                }
                this.$fapi.delete('ccpayment/payments/' + id).then(r=>{
                    this.loadPendingPayments();
                });     
                
            },        
            formatDate(raw){
                let date = new Date(raw).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                });
                return date;
            },
            loadFirst(){
                if(this.firstUrl)
                    this.loadPendingPayments(this.firstUrl);
            },
            loadNext(){
                if(this.nextUrl)
                    this.loadPendingPayments(this.nextUrl);
            },
            async loadPendingPayments(url){                
                let fetchUrl = url ? url : 'ccpayment/payments';
                let fetched = await this.$fapi.get(fetchUrl);
                this.payments = fetched.data.data;
                this.nextUrl = null
                this.firstUrl = null
                if(fetched.data.links){
                    if(fetched.data.links.next)
                        this.nextUrl = fetched.data.links.next 
                    if(fetched.data.links.first)
                        this.firstUrl = fetched.data.links.first 
                }

            }
        },
		data(){
			return {
			};
		},

		created(){
            document.title = 'Pending CC Payments'
            this.loadPendingPayments();
		}, 

        setup(){
                        
            const firstUrl = ref('')
            const nextUrl = ref('')
            const confirmApprove = ref('')
            const confirmArchive = ref('')

            const payments = ref([]);
            
            let mon = new Intl.NumberFormat('en-US', {  //mon: Used for money formatting
                style: 'currency',
                currency: 'USD'
            });


            function formatMoney(val){
                if(isNaN(parseFloat(val)))
                    return '';
                return mon.format(val);
            }       
            
            return { 
                confirmApprove,
                confirmArchive,
                firstUrl,
                formatMoney,
                nextUrl,
                payments
            }

        }
	}
	
</script>
