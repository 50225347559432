<template>
    <div class="max-w-screen-md w-full">
        


        <div class="w-full text-center font-bold text-5xl p-2 mb-10">MembNet Admin Dashboard</div>
        <div class="w-full flex justify-end m-2 "><div title="Customize Dash" class="mn3-button w-8 h-8 border rounded mr-4 cursor-pointer"><Icon id="cog" /></div></div>
        <!-- Member Logins -->
        <div class="mb-2 mx-2 flex-grow">
            <div class="mn3-navbar px-2 py-1 rounded-t font-bold text-xl flex justify-start items-center">
                <Icon id="users" class="h-8 w-8 mr-1" />
                <p>Member Logins (Across all apps)</p>
            </div>
            
            <div class="px-2 py-4 mn3-content">
                <apexchart class="w-full" type="bar" 
                :options="{
                    chart: {
                        id: 'loginsWeekly'
                    },
                    fill: {
                    colors: ['#F44336']
                    },
                    xaxis: {
                        categories: loginCategories[loginActive]
                    }
                }" 
                :series="
                [{
                    name: ['Logins'],
                    data: logins[loginActive]
                }]"></apexchart>
            </div>
            <div class="flex flex-row mn3-content justify-around pt-2 flex-wrap">

                <div @click="loginActive = 'weekly'"
                    :class="`${loginActive == 'weekly' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 7 Days)</p>
                </div>
                
                <div @click="loginActive = 'last30'"
                 :class="`${loginActive == 'last30' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">1,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 30)</p>
                </div>
                
                <div @click="loginActive = 'last90'"
                 :class="`${loginActive == 'last90' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">12,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 90)</p>
                </div>
                
                <div @click="loginActive = 'year'"
                 :class="`${loginActive == 'year' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">12,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(This Year)</p>
                </div>

                <div @click="loginActive = 'total'"
                 :class="`hidden ${loginActive == 'total' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20  rounded-sm`">
                    <p class="font-bold text-2xl">12,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Total)</p>
                </div>

            </div>
        </div>


        <div class="flex flex-grow">
            
        <!-- Android Installs -->
        
        <div class="mb-2 mx-2 flex-grow">
            <div class="mn3-navbar px-2 py-1 rounded-t font-bold text-xl flex justify-start items-center">
                <Icon id="mobile" class="h-8 w-8 mr-1" />
                <p>Android Installs</p>
            </div>
            
            <div class="flex flex-row mn3-content justify-between pt-2 flex-wrap">

                <div 
                    @click="androidActive = 'weekly'"
                    :class="` ${androidActive == 'weekly' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 7 Days)</p>
                </div>
                
                <div
                    @click="androidActive = 'last30'"
                    :class="` ${androidActive == 'last30' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">1,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 30)</p>
                </div>
                
                <div 
                    @click="androidActive = 'last90'"
                    :class="` ${androidActive == 'last90' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">12,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(total)</p>
                </div>

            </div>


            <div class="px-2 pt-4 mn3-content">
                <apexchart class="w-full" type="bar" 
                :options="{
                    chart: {
                        id: 'loginsWeekly'
                    },
                    fill: {
                        colors: ['#43F436']
                    },
                    xaxis: {
                        categories: androidInstallCategories[androidActive]
                    }
                }" 
                :series="
                [{
                    name: ['Installs'],
                    data: androidInstalls[androidActive]
                }]"></apexchart>
            </div>

        </div>
        
        <!-- IOS Installs -->
        <div class="mb-2 mx-2 flex-grow">
            <div class="mn3-navbar px-2 py-1 rounded-t font-bold text-xl flex justify-start items-center">
                <Icon id="mobile" class="h-8 w-8 mr-1" />
                <p>IOS Installs</p>
            </div>

            
            <div class="flex flex-row mn3-content justify-between pt-2 flex-wrap">

                <div 
                    @click="iosActive = 'weekly'"
                    :class="` ${iosActive == 'weekly' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 7 Days)</p>
                </div>
                
                <div
                    @click="iosActive = 'last30'"
                    :class="` ${iosActive == 'last30' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 mb-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">1,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(Last 30)</p>
                </div>
                
                <div 
                    @click="iosActive = 'last90'"
                    :class="` ${iosActive == 'last90' ? 'mn3-highlight' : ''} cursor-pointer flex flex-col items-center w-24 mx-2 tracking-tighter flex-grow-0 flex-shrink-0 p-2 border h-20 rounded-sm`">
                    <p class="font-bold text-2xl">12,234</p>
                    <p class="text-center text-xs uppercase opacity-70">(total)</p>
                </div>

            </div>


            <div class="px-2 pt-4 mn3-content">
                <apexchart class="w-full" type="bar" 
                :options="{
                    chart: {
                        id: 'loginsWeekly'
                    },
                    fill: {
                        colors: ['#4336F4']
                    },
                    xaxis: {
                        categories: iosInstallCategories[iosActive]
                    }
                }" 
                :series="
                [{
                    name: ['Installs'],
                    data: iosInstalls[iosActive]
                }]"></apexchart>
            </div>

        </div>

        </div>

	</div>
</template>

<script>
	import Icon from "@/components/Icon.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
    import { ref } from 'vue'
    import { useRouter } from "vue-router"
    
    import VueApexCharts from 'vue3-apexcharts'

	export default {
		components:{
			Form,
            Icon,
			Field,
            apexchart: VueApexCharts
		},
		data(){
			return {
			};
		},

		created(){
            document.title = 'Dashboard'
		}, 

        setup(){
            


        const loginActive = ref('weekly');
        const logins = ref({
            'weekly': [12,14,36,23,9,5,23],
            'last30': [12,14,16,23,9,5,23,12,14,26,23,9,5,23,12,14,36,23,9,5,23,12,14,46,23,9,5,23,44,31],
            'last90': [345,396,567,487,465,501],
            'year': [512,480,560,620,780,640,712,580,624,0,0,0]
        })
        const loginCategories = ref({
            'weekly': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            'last30': ['','08/30','','','','','','','09/06','','','','','','','09/13','','','','','','','09/20','','','','','','','09/27'],
            'last90': [''],
            'year': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        })

        const androidActive = ref('weekly');
        const androidInstalls = ref({
            'weekly': [12,14,36,23,9,5,23],
            'last30': [12,14,16,23,9,5,23,12,14,26,23,9,5,23,12,14,36,23,9,5,23,12,14,46,23,9,5,23,44,31],
            'last90': [345,396,567,487,465,501],
        });
        const androidInstallCategories = ref({
            'weekly': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            'last30': ['','08/30','','','','','','','09/06','','','','','','','09/13','','','','','','','09/20','','','','','','','09/27'],
            'last90': ['']
        });
        

        const iosActive = ref('weekly');
        const iosInstalls = ref({
            'weekly': [12,14,36,23,9,5,23],
            'last30': [12,14,16,23,9,5,23,12,14,26,23,9,5,23,12,14,36,23,9,5,23,12,14,46,23,9,5,23,44,31],
            'last90': [345,396,567,487,465,501],
        });
        const iosInstallCategories = ref({
            'weekly': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            'last30': ['','08/30','','','','','','','09/06','','','','','','','09/13','','','','','','','09/20','','','','','','','09/27'],
            'last90': ['']
        });


			/* Form field values */
			let fv = {}

            return { 
                androidActive,
                androidInstalls,
                androidInstallCategories,
                iosActive,
                iosInstalls,
                iosInstallCategories,
				fv,
                loginActive,
                logins,
                loginCategories
            }

        }
	}
	
</script>
