<!-- Field: Displays a single field of a form -->
<template>
    <div :class="`py-${paddingY} px-${paddingX}`">
        <!-- Text / Password / File Inputs - Seperate from other inputs due to sliding label-->
        <InputText v-if="type === 'text' || type === 'number' || type === 'password'|| type === 'file' || type == 'date'"
        ref="refInput"
        :type="type"
        :id="id"
        :label="label"
        :index="index"        
        :textFormat="textFormat"
        :formValue="formValue"
        :align="align"
        :maxLength="maxLength"
        :uppercase="uppercase"
        :placeholder="placeholder"
        @onError="onError"
        @blur="clearErrors;emit('blur')"
        @change="clearErrors;emit('change')"
        @keydown="keydown"
        @esc="emit('esc')" />
        <!-- Other input types -->
        <label v-else :for="id" class="field text-left">
            <!-- Input label-->
            <div v-if="type !== 'toggle' && type !== 'color'" 
                :class="`flex font-sans tracking-tight`">
                <p :style="`${type == 'select' ? 'font-size: 80%; position: relative; top: -2px' : ''}`">{{label}}</p>
                <p v-if="isRequired" class="mn3-required font-bold">*</p>
            </div>
            <div v-if="type === 'display'">{{formValue.value}}</div>
            <InputToggle v-else-if="type === 'toggle'"
                ref="refInput"
                :id="id"
                :index="index"
                :label="label"
                :formValue="formValue"
                @onError="onError" />
            <InputTextarea v-else-if="type === 'textarea'"
                ref="refInput"
                :id="id"
                :index="index"
                :formValue="formValue"
                @onError="onError" />
            <InputSelect v-else-if="type === 'select'"
                ref="refInput"
                :id="id"
                :index="index"
                :formValue="formValue"
                :options="options"
                @onError="onError"
                />
            <InputColor v-else-if="type === 'color'" 
                ref="refInput"
                :id="id"
                :index="index"
                :formValue="formValue"
                @onError="onError"
                />
        </label>
        <transition name="down" mode="out-in">
            <div v-if="error != ''"
                class="mn3-alert text-bold text-sm rounded-b px-2 py-1">
                {{error}}
            </div>
        </transition>
        <div v-if="desc != undefined" class="px-2 py-1 text-xs mn3-content cursor-default border-l border-b border-r rounded-b text-left">{{desc}}</div>
        <slot></slot>
    </div>
</template>

<script>
/* Component API */
import { ref } from "vue";

import InputColor from '@/components/InputColor.vue'
import InputSelect from '@/components/InputSelect.vue'
import InputText from '@/components/InputText.vue'
import InputTextarea from '@/components/InputTextarea.vue'
import InputToggle from '@/components/InputToggle.vue'


export default {
    components: {
        InputColor,
        InputSelect,
        InputText,
        InputTextarea,
        InputToggle
    },
    props:{
        /* Properties without defaults */
        desc: String,                   //desc: Additional optional field description
        digits: Number,                 //digits: Number of whole # digits allowed when textFormat = number
        id: String,                     //id: ID for the root field element
        index: Number,                  //index: Tabindex for the field
        label: String,                  //label: Main descriptive label for the field
        options: Object,                //options: Dropdown options if type = select
        placeholder: String,
        textFormat: String,             //textFormat: Formatting applied to text input. email, money/amount, rate, date, number
        type: String,                   //type: text, password, date, color, display, toggle, file, textarea, select
        
        /* Properties with defaults */
        align: {                        //align: Text alignment of the field
            type: String,
            default: 'left'
        },
        allowNegative: {                //allowNegative: If negative numbers are allowed
            type: Boolean,
            default: false
        },
        decimals: {                     //decimals: Number of whole number digits allowed in number
            type: Number,
            default: 0
        },
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
        isRequired: {                   //isRequired: If this field can't be left blank
            type: Boolean,
            default: false
        },
        maxLength: {                    //maxLength: Max length of input entered
            type: Number,
            default: null
        },
        paddingX: {                     //paddingX: X padding used on the field
            type: Number,
            default: 3
        },
        paddingY: {                     //paddingY: Y padding used on the field
            type: Number,
            default: 4
        },
        uppercase: {
            type: Boolean,              //uppercase: If alpha characters are converted to uppercase
            default: false
        }
    },
    methods: {
        clear(){
            this.formValue.value = '';
        },
        clearErrors(){
            this.error = '';
        },       
        getValue(){
            return this.formValue.value
        },
        keydown(evt){
            this.emit('keydown')

            if(evt.key == 'Enter'){
                this.emit('enter') 
                evt.preventDefault();
            }               
        },
        onError(a){
            if(a && a.length > 0)
                console.log('field.onError: ' + a)
            
            this.error = a;
        },
        focus(){
            this.refInput.focus();
        }
    },
    setup(props, { emit }){
        const error = ref("");      //error: Validation error on the field
        const refInput = ref(null)

        return {
           emit,
           error,
           refInput
        }
    }
}

</script>

