<template>
    <div v-if="recordsLoading" class="text-center text-xs py-1 mn3-content-alt border-t border-b italic flex flex-row items-center justify-center">
        <Icon class="h-4 w-4 mr-1" id="loading" /><p>Loading Records</p>
    </div>
    <div v-else-if="showRecordCount" class="text-center text-xs py-1 mn3-content-alt md:rounded-t md:border-l md:border-r border-t">
        <p v-if="recordCount > 0">Viewing records <span class="font-bold">{{recordStart}}</span> - <span class="font-bold">{{recordEnd}}</span> of <span class="font-bold">{{recordCount}}</span> results</p>
        <p v-else>No records found</p>
    </div>
    <div :class="`border-b border-t mn3-content records rounded-sm`">
            <div v-if="recordsToShow.length == 0 && !recordsLoading && !showRecordCount" class="mn3-content w-full text-center p-2 italic">No records found</div>
            <div v-if="table == 'account'">{{render}}
                <!--Account Transactions-->
                <div v-for="(record, index) in recordsToShow" :key="index" >
                    <!--Account: web view-->
                    <div :class="`${focused == index ? 'border-t border-b' : ''}
                        hidden md:flex flex-col w-full mn3-content-hover flex-row justify-start p-2 leading-5 border-l border-r`">
                        <div class="flex flex-row w-full justify-between items-start ">
                            <div style="width: 120px" :class="`flex-none text-left py-1 ml-2 ${(filterHighlight == 'date') ? ' mn3-highlight' : ''}`">
                                {{formatDate(record.date)}}
                            </div>
                            <div :class="`flex-grow p-1 rounded ${(filterHighlight == 'desc') ? ' mn3-highlight' : ''}`">
                                <div :class="`flex flex-col items-start`">                                    
                                    <p class="text-base uppercase">{{record.desc}}</p>
                                    <div v-if="record.lines.length > 0" class="flex flex-col items-start text-sm pl-1">
                                        <p v-for="line in record.lines" :key="line">{{line}}</p>
                                    </div>
                                    <div v-if="record.checkNumber != undefined && record.checkImagesUrl == undefined"
                                        class="flex flex-col items-start text-sm pl-1">Check #{{record.checkNumber}}</div>
                                </div>
                            </div>
                            <div style="width: 115px" :class="`flex-none text-right flex justify-end rounded ${(filterHighlight == 'checkNum') ? ' mn3-highlight' : ''}`">
                                <div v-if="record.checkImagesUrl != null" 
                                        @click="requestCheckImages(record.checkImagesUrl, index)"
                                        class="cursor-pointer text-xs w-min whitespace-nowrap rounded border px-1 mn3-button flex flex-row items-center justify-start">
                                        <Icon id="ticket" class="h-5 w-5 mr-1" />
                                        <p>Check #{{record.checkNumber}}</p>
                                </div>
                            </div>
                            <div v-if="record.principalAmount" style="width: 80px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'principalAmount') ? ' mn3-highlight' : ''}`">
                                <div :class="`flex justify-end text-xs flex-col`"><span class="ml-2">{{formatMoney(record.principalAmount)}}</span><p>Principal</p>
                                </div>
                            </div>
                            <div v-if="record.interestAmount" style="width: 80px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'interestAmount') ? ' mn3-highlight' : ''}`">
                                <div :class="`flex justify-end text-xs flex-col`"><span class="mn3-debit ml-2">{{formatMoney(record.interestAmount)}}</span><p>Interest</p>
                                </div>
                            </div>
                            <div v-if="record.feeAmount" style="width: 80px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'feeAmount') ? ' mn3-highlight' : ''}`">
                                <div :class="`flex justify-end text-xs flex-col`"><span class="mn3-debit ml-2">{{formatMoney(record.feeAmount)}}</span><p>Fee</p>
                                </div>
                            </div>
                            <div style="width: 110px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'amount') ? ' mn3-highlight' : ''}`">
                                <div v-if="record.amount" 
                                    :class="`flex font-bold justify-end
                                        ${record.type === 'Credit' ? ' mn3-credit ' : ' mn3-debit '}`">
                                <span :class="`${record.type === 'Debit' ? '' : 'hidden'}`">-</span>{{formatMoney(record.amount)}} 
                                </div>
                            </div>
                            <div style="width: 110px" :class="`flex-none text-right text-sm py-1`">
                                <div v-if="record.balance" :class="`flex flex-col rounded ${(filterHighlight == 'balance') ? ' mn3-highlight' : ''}`">
                                {{formatMoney(record.balance)}} 
                                </div>
                            </div>
                        </div> 
                        <div v-if="focused == index"
                            class="w-full text-center flex justify-around mn3-content-alt rounded p-2">
                            <div class="bg-white border rounded-sm h-20 w-48">
                                <a v-if="!checkFrontInvalid" :href="checkFront" target="_blank" class="cursor-pointer"><img :src="checkFront" @error="checkFrontInvalid = true" /></a>
                                <div v-if="checkFrontInvalid" class="text-cent font-2xl uppercase font-bold p-6">No Check Image to Display</div>
                            </div>
                            <div class="bg-white border rounded-sm h-20 w-48">
                                <a v-if="!checkBackInvalid" :href="checkBack" target="_blank" class="cursor-pointer"><img :src="checkBack" @error="checkBackInvalid = true" /></a>
                                <div v-if="checkBackInvalid" class="text-cent font-2xl uppercase font-bold p-6">No Check Image to Display</div>
                            </div>
                        </div>
                    </div>       
                    <!--Account: mobile view-->
                    <div class="block md:hidden flex w-full mn3-content-hover flex-col justify-between border-b p-5 cursor-default"
                        @click="emit('loadAccount', $event, index)">                        
                        <div :class="`flex justify-start text-xs pl-1 rounded ${(filterHighlight == 'date') ? ' mn3-highlight' : ''} flex-none text-left`">
                            {{formatDate(record.date)}}
                        </div>
                        <div :class="`p-1 rounded ${(filterHighlight == 'desc') ? ' mn3-highlight' : ''}`">
                            <div class="flex justify-between mb-2 font-bold">
                                <div class="leading-5 text-lg uppercase flex flex-col">
                                    <span>{{record.desc}}</span>     
                                    <div v-if="record.lines.length > 0" class="flex flex-col items-start text-sm pl-2">
                                        <p v-for="line in record.lines" :key="line">{{line}}</p>
                                    </div>               
                                </div>            
                                <div v-if="record.checkImagesUrl != null" 
                                    @click="requestCheckImages(record.checkImagesUrl, index)"
                                    class="cursor-pointer relative -top-6 w-min whitespace-nowrap rounded border px-2 py-1 mn3-button flex flex-row items-center justify-start">
                                    <Icon id="ticket" class="h-6 w-6 mr-2" />
                                    <p>Check #{{record.checkNumber}}</p>
                                </div>
                            </div>
                        </div>
                        <div :class="`p-1 rounded ${(filterHighlight == 'checkNum') ? ' mn3-highlight' : ''} flex justify-start`" v-if="record.checkNum">                        
                            <div class="flex flex-col">
                                <p class="w-24">Check #:</p><p>{{record.checkNum}}</p>
                            </div>
                        </div>
                        <div class="flex justify-between items-top">
                            <div class="flex flex-col text-sm italic">
                                <div v-if="record.principalAmount" :class="`flex justify-start p-1 rounded ${(filterHighlight == 'principalAmount') ? ' mn3-highlight' : ''}`">                                               
                                    <p class="w-24">Principal:</p><p :class="`flex flex-col`">{{formatMoney(record.principalAmount)}}</p>
                                </div> 
                                <div v-if="record.interestAmount" :class="`flex justify-start p-1 rounded ${(filterHighlight == 'interestAmount') ? ' mn3-highlight' : ''}`">                                               
                                    <p class="w-24">Interest:</p><p :class="`flex flex-col`">{{formatMoney(record.interestAmount)}}</p>
                                </div> 
                                <div v-if="record.feeAmount" :class="`flex justify-start p-1 rounded ${(filterHighlight == 'feeAmount') ? ' mn3-highlight' : ''}`">                                               
                                    <p class="w-24">Fee:</p><p :class="`flex flex-col`">{{formatMoney(record.feeAmount)}}</p>
                                </div> 
                                <div :class="`flex justify-start p-1 rounded ${(filterHighlight == 'balance') ? ' mn3-highlight' : ''}`">                                               
                                    <p class="w-24">Balance:</p><p :class="`flex flex-col`">{{formatMoney(record.balance)}}</p>
                                </div> 
                                <div v-if="record.checkNumber != undefined" :class="`flex justify-start p-1 rounded`">                                               
                                    <p class="w-24">Check #:</p><p :class="`flex flex-col`">{{record.checkNumber}}</p>
                                </div>
                            </div>
                            <div :class="`flex justify-end p-1 rounded ${(filterHighlight == 'amount') ? ' mn3-highlight' : ''}`">                        
                                <p class="pl-4 w-24" v-if="false">Amount:</p><p :class="`flex flex-row font-bold text-lg
                                    ${record.type === 'Credit' ? ' mn3-credit ' : ' mn3-debit'}`"><span :class="`${record.type === 'Debit' ? '' : 'hidden'}`">-</span>{{formatMoney(record.amount)}}</p>
                            </div>
                        </div>
                        <!--Check Display-->
                        <div v-if="focused == index"
                            class="w-full text-center flex justify-around mn3-content-alt rounded p-2">
                            <div class="bg-white border rounded-sm h-20 w-48">
                                <a v-if="!checkFrontInvalid" :href="checkFront" target="_blank" class="cursor-pointer"><img :src="checkFront" @error="checkFrontInvalid = true" /></a>
                                <div v-if="checkFrontInvalid" class="text-cent font-2xl uppercase font-bold p-6">No Check Image to Display</div>
                            </div>
                            <div class="bg-white border rounded-sm h-20 w-48">
                                <a v-if="!checkBackInvalid" :href="checkBack" target="_blank" class="cursor-pointer"><img :src="checkBack" @error="checkBackInvalid = true" /></a>
                                <div v-if="checkBackInvalid" class="text-cent font-2xl uppercase font-bold p-6">No Check Image to Display</div>
                            </div>
                        </div>
                        
                    </div>    
                </div>
            </div>            
            <div v-else-if="table == 'accounts'">
            <!-- Accounts -->
                <div v-for="(record, index) in recordsToShow" :key="index">
                    <!--Accounts: web view-->
                    <!--If external owner changed, display new header-->
                    <div v-if="index > 0 && (recordsToShow[index-1]['externalOwnerName'] != record['externalOwnerName'])"
                        class="pt-5 text-base font-bold uppercase border px-2 mn3-content">
                        {{record['externalOwnerName']}}
                    </div>
                    <div :class="`${recordBaseClass(record)} hidden md:flex w-full flex-row justify-between p-3 items-center border-l border-r z`"
                       @click="accountClicked($event, index, record)">
                        <div style="width: 45px" class="flex-none" >
                            <div class="w-full border rounded-full p-1 text-center"><Icon :id="record.icon" /></div>
                        </div>
                        <div class="flex-grow pl-5">
                            <p :class="`leading-5 text-xl p-1 rounded ${(filterHighlight == 'desc') ? ' mn3-highlight' : ''}`">{{record.desc}}</p>
                            <p :class="`text-xs uppercase p-1 rounded ${(filterHighlight == 'subDesc') ? ' mn3-highlight' : ''}`">{{record.subDesc}}</p>
                        </div>
                        <!-- Line of Credit / Loan -->
                        <div v-if="record.type == 'LineOfCredit'||record.type == 'Loan'" class="flex">
                            <div stye="width: 65px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`" >
                                <p>{{record.interestRate}}%</p><p class="text-xs">Rate</p>
                            </div>
                            <div style="width: 150px" 
                                v-if="record.nextPaymentDate != ''"
                                :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'nextPaymentDate') ? ' mn3-highlight' : ''}`" >
                                <p>{{formatPaymentDate(record.nextPaymentDate)}}</p>
                                <p class="text-xs">Next Due</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'nextPaymentAmount') ? ' mn3-highlight' : ''}`" >
                                <p>{{formatMoney(record.nextPaymentAmount)}}</p>
                                <p class="text-xs">Payment</p>
                            </div> 
                            <div v-if="record.type == 'Loan' || record.type == 'LineOfCredit'" style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.actualBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div> 
                            <div v-else style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.availableBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div>                            
                        </div>
                        <!-- Credit Card -->
                        <div v-else-if="record.type == 'CreditCard'" class="flex">
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.availableBalance)}} </p>
                                <p class="text-xs">Available Credit</p>
                            </div>    
                            <div style="width: 150px" 
                                v-if="record.nextPaymentDate != ''"
                                :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'nextPaymentDate') ? ' mn3-highlight' : ''}`" >
                                <p>{{formatPaymentDate(record.nextPaymentDate)}}</p>
                                <p class="text-xs">Next Due</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'nextPaymentAmount') ? ' mn3-highlight' : ''}`" >
                                <p>{{formatMoney(record.nextPaymentAmount)}}</p>
                                <p class="text-xs">Payment</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.actualBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div>                            
                        </div>
                        <!-- Certificate/investment -->
                        <div v-else-if="record.type == 'Investment'" class="flex">
                            <div stye="width: 65px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`">
                                <p>{{record.interestRate}}%</p><p class="text-xs">Rate</p>
                            </div>
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'maturityDate') ? ' mn3-highlight' : ''}`">
                                <p>{{formatPaymentDate(record.maturityDate)}}</p>
                                <p class="text-xs">Maturity Date</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.availableBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div>                            
                        </div>
                        <!-- Savings -->
                        <div v-else-if="record.type == 'Savings'" class="flex">
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                <p>{{formatMoney(record.availableBalance)}}</p>
                                <p class="text-xs">Available</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                <p class="font-bold">{{formatMoney(record.actualBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div>                     
                        </div>
                        <!-- Other -->
                        <div v-else class="flex">
                            <div stye="width: 65px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`" v-if="record.interestRate != undefined && this.interestRate > 0">
                                <p>{{record.interestRate}}%</p><p class="text-xs">Rate</p>
                            </div>
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`" v-if="record.availableBalance != undefined">
                                <p>{{formatMoney(record.availableBalance)}}</p>
                                <p class="text-xs">Available</p>
                            </div> 
                            <div style="width: 150px" :class="`flex-none text-right p-1 rounded ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`" v-if="record.actualBalance != undefined">
                                <p class="font-bold">{{formatMoney(record.actualBalance)}} </p>
                                <p class="text-xs">Balance</p>
                            </div>
                        </div>
                    </div>       
                    <!--Accounts: mobile view-->
                    <div :class="`${recordBaseClass(record)} block md:hidden flex w-full flex-col justify-between border-b p-5`"
                        @click="accountClicked($event, index, record)">
                            <div class="w-full flex justify-start mb-2 items-center text-lg">
                                <div class="flex-none w-14 mx-2" >
                                    <div class="w-full border rounded-full p-1 text-center"><Icon :id="record.icon" /></div>
                                </div>
                                <div class="flex-grow pl-3">
                                    <p :class="`leading-5 text-lg font-bold ${(filterHighlight == 'desc') ? ' mn3-highlight' : ''}`">{{record.desc}}</p>
                                    <p :class="`uppercase ${(filterHighlight == 'subDesc') ? ' mn3-highlight' : ''}`">{{record.subDesc}}</p>
                                </div>
                            </div>               
                            <!-- Line of Credit -->             
                            <div v-if="record.type == 'LineOfCredit' || record.type == 'Loan'" class="flex flex-col">                                
                                <div  v-if="record.type != 'CreditCard'"
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Rate:</p><p>{{record.interestRate}}%</p>         
                                </div>                                                      
                                <div                                 
                                    v-if="record.nextPaymentDate != ''"
                                    :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Next Due:</p><p>{{formatPaymentDate(record.nextPaymentDate)}}</p>     
                                </div>                                               
                                <div 
                                    :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Payment:</p><p>{{formatMoney(record.nextPaymentAmount)}}</p>
                                </div>
                                <div v-if="record.type == 'Loan'||record.type == 'LineOfCredit'"
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.actualBalance)}}</p>
                                </div>     
                                <div v-else
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.availableBalance)}}</p>
                                </div>       
                            </div>  
                            <!--Credit Card-->
                            <div v-else-if="record.type == 'CreditCard'" class="flex flex-col">                                
                                <div :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Available:</p><p>{{formatMoney(record.availableBalance)}}</p>
                                </div>                                                   
                                <div v-if="record.nextPaymentDate != ''"
                                    :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Next Due:</p><p>{{formatPaymentDate(record.nextPaymentDate)}}</p>     
                                </div>                                               
                                <div 
                                    :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Payment:</p><p>{{formatMoney(record.nextPaymentAmount)}}</p>
                                </div>    
                                <div :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.actualBalance)}}</p>
                                </div>       
                            </div>           
                            <!-- Investment / Certificate -->             
                            <div v-else-if="record.type == 'Investment'" class="flex flex-col">                                
                                <div  
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Rate:</p><p>{{record.interestRate}}%</p>         
                                </div>                                                      
                                <div
                                    :class="`flex justify-start ml-16 pl-1 text-lg`">
                                    <p class="pl-4 w-36">Maturity Date:</p><p>{{formatPaymentDate(record.maturityDate)}}</p>     
                                </div>       
                                <div
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.actualBalance)}}</p>
                                </div>       
                            </div>      
                            <!-- Savings -->             
                            <div v-else-if="record.type == 'Savings'" class="flex flex-col">   
                                <div :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Available:</p><p>{{formatMoney(record.availableBalance)}}</p>
                                </div>   
                                <div :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.actualBalance)}}</p>
                                </div>       
                            </div>
                            <div v-else class="flex flex-col">
                                <div v-if="record.interestRate != undefined && this.interestRate > 0" 
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'interestRate') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Rate:</p><p>{{record.interestRate}}%</p>
                                </div>
                                <div v-if="record.availableBalance != undefined" 
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'availableBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Available:</p><p>{{formatMoney(record.availableBalance)}}</p>
                                </div>   
                                <div v-if="record.actualBalance != undefined" 
                                    :class="`flex justify-start ml-16 pl-1 text-lg ${(filterHighlight == 'actualBalance') ? ' mn3-highlight' : ''}`">
                                    <p class="pl-4 w-36">Balance:</p><p>{{formatMoney(record.actualBalance)}}</p>
                                </div>        
                            </div>     
                    </div>    
                </div>
            </div>   
        </div>   

</template>

<script>

import { ref, computed } from "vue";

import Icon from "@/components/Icon.vue";

export default{
    components: {
        Icon
    },
    methods: {
        async requestCheckImages(url, index){

            //if already focused on this record, instead unfocus
            if(this.focused == index){
                this.focused = -1;
                this.checkFront = '';
                this.checkBack = '';
                return;
            }else{
                const imgData = await this.$mapi.get(url);  
                this.checkFront = imgData.data.data.attributes.frontUrl
                this.checkBack = imgData.data.data.attributes.backUrl
                this.focused = index;
            }
        },
        recordBaseClass(record){
            let classes = '';

            if(this.isDueDatePast(record) && record.actualBalance > 0 && record.nextPaymentAmount > 0)
                classes += 'mn3-alert ';          
            else if(record.canViewHistory == false)
                classes += 'mn3-content ';
            else 
                classes += 'mn3-content-hover cursor-pointer ';

            return classes;
        },
        recordBaseClick(record){

        }
    },
    props: {
        filterData: Object,                 //filterData: Filter data to apply to results
        filterHighlight: {                  //filterHighlight: Currently highlighted column
            type: String,
            default: ''
        },
        quickFilter: String,                //quickFilter: Current quickFilter value
        records: Object,                    //records: Records to display from
        recordsLoading: {                   //recordsLoading: If currently loading records
            type: Boolean,
            default: false
        },
        showRecordCount: {                  //showRecordCount: # of recrods shown at head of table
            type: Boolean,
            default: true
        },
        sortBy: String,
        sortOrder: Number,
        sortCompare: String,
        table: String                       //table: key for the table, determines record template used
    },
    setup(props, { emit }){
        
        /* --Variables-- */   
        let mon = new Intl.NumberFormat('en-US', {  //mon: Used for money formatting
            style: 'currency',
            currency: 'USD'
        });
        let page = ref(0)                           //page: Current page
        let pages = ref(1)                          //pages: Total pages
        let perPage = ref(500)                      //perPage: Records to show per page
        let recordCount = ref(0)                    //recordCount: Total records
        let recordEnd = ref(0)                      //recordEnd: Last record shown on page
        let recordStart = ref(0)                    //recordStart: First record shown on page
        let recs = ref(props.records)               //recs: Records
        let focused = ref(-1)                       //record to focus display on
        let sortBy = ref(props.sortBy);
        let sortOrder = ref(props.sortOrder);
        let checkFront = ref('')                    //checkFront: URL for image of check front
        let checkBack = ref('')                     //checkBack: URL for image of check back
        let checkFrontInvalid = ref(false)          //checkFrontInvalid: If checkFront url leads to invalid image
        let checkBackInvalid = ref(false)           //checkFrontInvalid: If checkBack url leads to invalid image



        /* --Computed-- */        
        const recordsToShow                 //recordsToShow: Determine what records to show based on filters + pages
            = computed(() => {       
            if(recs.value === undefined)
                return ['.'];

            let show = []; 

            //Check for quickfilter  
            if(props.quickFilter != undefined 
                && props.quickFilter.length > 0
                && props.quickFilter.trim() != '')
            {
                let qfVal = props.quickFilter.trimLeft().trimRight();

                if(qfVal.replace(/\s/g, '').length){    
                    for(var ii = 0; ii < recs.value.length; ii++){
                        let include = false;
                        let filterFor = qfVal.toLowerCase();
                        for(var key in recs.value[ii]){
                            if(recs.value[ii][key] != null)
                            if(recs.value[ii][key].toString().toLowerCase().indexOf(filterFor) !== -1){
                                include = true
                            }
                        }

                        if(include){
                            show.push(recs.value[ii]);
                        }
                    }
                }
            }
            //Check for per-column full filter  
            else if(filtersInUse(props.filterData)){
                console.log('check full filters: ');
                let f = props.filterData;//{...props.filterData['values']}
                
                for(var ii = 0; ii < recs.value.length; ii++){

                    let showRec = true;
                    for(var valueKey in f){
                        let filterMet = true;
                        let filterFor = f[valueKey]['value'].toString().toLowerCase()
                        let filterMode = f[valueKey]['mode'].toString();
                         
                        if(filterFor.length > 0){
                            let checkVal = recs.value[ii][valueKey]
                            if(checkVal !== undefined){

                                if(filterMode == 'gt' || filterMode == 'lt'){
                                    checkVal = parseFloat(checkVal)
                                    filterFor = parseFloat(filterFor)

                                    if((filterMode == 'gt' && checkVal < filterFor)
                                        ||(filterMode == 'lt' && checkVal > filterFor)){
                                            filterMet = false
                                    }
                                }
                                else if(checkVal.toString().toLowerCase().indexOf(filterFor) === -1){
                                    filterMet = false;
                                }
                            }
                        }                        

                        if(!filterMet)
                            showRec = false;
                    }

                    if(showRec){
                        show.push(recs.value[ii])
                    }
                }                 
            }
            //If no filter, start with all records
            else{
                show = recs.value.slice();
            }    
             
            //Check for pages
            if(perPage.value == 0 || perPage.value == undefined)
                return show;
                
            pages.value = Math.ceil( show.length / perPage.value );

            if(page.value >= pages.value)
                page.value = pages.value-1
            else if(page.value < 0)
                page.value = 0;

            let start = page.value*perPage.value;
            let end = parseInt(page.value*perPage.value) + parseInt(perPage.value);
    
            recordCount.value = show.length

            recordStart.value = start+1
            recordEnd.value = (end > show.length ? show.length : end)

            return show.slice(start, end);
        });
        
        /* --Functions-- */
        
        //accountClicked: Emit when a table row is clicked emit msg
        //  event: Click event
        //  rowKey: Key of the row clicked on
        function accountClicked(event, rowKey, record){
            if(!record.canViewHistory) return;

            console.log('account clicked: ', event, rowKey);
            if(event !== undefined)
                emit('loadAccount', event, rowKey);
        }    

        //filtersInUse: Checks if any values in filterData aren't blank
        // fiterData: Data to check if any filters active
        function filtersInUse(filterData){
            let anyInUse = false;

            if(filterData == undefined) return false;

            let vals = filterData;
            //console.log('fiu vals: ', vals)
            for(var key in vals){
                if(vals[key]['value'].length > 0)
                    anyInUse = true
            }

            return anyInUse;
        }

        // apply default sort if set
        /*
        if(props.sortBy != undefined && props.sortOrder != undefined && props.sortCompare != undefined )
            sortRecords(props.sortBy, props.sortOrder, props.sortCompare);
        */
        function sortRecords(sortBy, sortOrder, sortCompare){
            
            if(recs.value !== undefined){ 
                recs.value.sort(function(a, b){

                    //Check if either record has a priority flag set
                    if(a[props.priorityField] !== undefined || b[props.priorityField] !== undefined){
                        if(a[props.priorityField] === true && b[props.priorityField] !== true){
                            return -1;
                        }else if(b[props.priorityField] === true && a[props.priorityField] !== true){
                            return 1
                        }
                    }

                    //If no priority mismatch, return comparison
                    return compare(a[sortBy], b[sortBy], sortOrder, sortCompare);
                });          
            }
        }

        function compare(a, b, order, compareMethod){
            let result = 0;            
            switch(compareMethod){
                default:
                    result = compareBasic(a, b, order)
                    break;

                case 'numeric':
                    if(a == '' || a == undefined) a = '0';
                    if(b == '' || b == undefined) b = '0';
                    result = compareBasic(a, b, order)
                    //result = compareBasic(parseFloat(a.replace(/[^\d.-]/g, '')), parseFloat(b.replace(/[^\d.-]/g, '')), order)
                    break;

                case 'date':
                    result = compareDate(a, b, order)
                    break;

                case 'time':
                    result = compareTime(a, b, order)
                    break;
            }

            return result;
        }

        //Compare A vs B values, return -1, 1, or 0
        function compareBasic(a, b, order){
            
            if(a < b)
                return -1 * order;
            else if(a > b)
                return 1 * order

            //Return 0 on match so fallback comparison can be used
            return 0;
        }
        
        //Compare A vs B date values, return -1, 1, or 0
        function compareDate(a, b, order){

            let aSplit = a.split('/');
            let bSplit = b.split('/');

            //Make sure enough info in splits for a date
            if(aSplit.length != 3){
                aSplit[0] = 0;
                aSplit[1] = 0;
                aSplit[2] = 0;
            }
            if(bSplit.length != 3){
                bSplit[0] = 0;
                bSplit[1] = 0;
                bSplit[2] = 0;
            }

            let monthA = aSplit[0];
            let dayA, yearA;

            let monthB = bSplit[0];
            let dayB, yearB;

            //Zero all out if date not found
            if(monthA == ''){
                monthA = 0;
                dayA = 0;
                yearA = 0;
            }else{
                dayA = aSplit[1];
                yearA = aSplit[2];
            }            
            if(monthB == ''){
                monthB = 0;
                dayB = 0;
                yearB = 0;
            }else{
                dayB = bSplit[1];
                yearB = bSplit[2];
            }

            //Check for dif in years
            if(yearA < yearB)
                return -1 * order;
            else if(yearA > yearB)
                return 1 * order;

            //Check months
            if(monthA < monthB)
                return -1 * order;
            else if(monthA > monthB)
                return 1 * order;

            //Check days
            if(dayA < dayB)
                return -1 * order;
            else if(dayA > dayB)
                return 1 * order;

            return 0;
        }
        
        //formatDate: Formats date for display
        // raw: Date to format
        function formatDate(raw){
            let date = new Date(raw).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });
            return date;
        }

        
        function formatPaymentDate(val){
            if(val == undefined)
                return "";

            let split = val.split('-');
            //Make sure full date
            if(split.length < 3)
                return "";

            return split[1] + '/' + split[2] + '/' + split[0];
        }
        
        //formatMoney: Format money for display
        // val: Value to format
        function formatMoney(val){
            if(isNaN(parseFloat(val)))
                return '';
            return mon.format(val);
        } 
        

        // isDueDatePast: Returns if the next due date for a loan is past
        // Returns false if date not past or no due date
        function isDueDatePast(rec){                
            if(rec.nextPaymentDate == "" || rec.nextPaymentDate == undefined)
                return false;
            
            let split = rec.nextPaymentDate.split('-')
            if(split.length != 3){
                return false;
            }

            let date = new Date(parseInt(split[0]), parseInt(split[1])-1, parseInt(split[2]))
            let today = new Date(new Date().toDateString())

            return date < today;
        }



        return {
            accountClicked,
            checkFront,
            checkBack,
            checkFrontInvalid,
            checkBackInvalid,
            emit,
            isDueDatePast,
            focused,
            formatDate,
            formatMoney,
            formatPaymentDate,
            page,
            pages,
            recordCount,
            recordEnd,
            recordStart,
            recordsToShow
        }
    }

}
</script>