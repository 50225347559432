<template>
    <div class="w-full flex flex-col justify-between mx-auto max-w-screen-md">
        <Notice v-if="notice != ''" :notice="notice" level="notice" icon="info" title="Note" :showClose="true" @close="notice = ''" class="mb-2" />
        <Form v-if="loanApp == ''"
            :title="`Pending Loan Applications`" 
            :class="`flex-shrink-0 max-w-screen-sm w-full`"
            :showSubmit="false"
            :showCancel="false">
        <div v-if="firstUrl || nextUrl" class="w-full flex flex-row mn3-content-alt rounded-sm overflow-hidden mb-2 justify-between items-center">
            <div @click="loadFirst()" :class="`${!firstUrl ? 'opacity-20' : '' } py-3 duration-150 w-1/3 p-2 mn3-content-alt-hover text-center cursor-pointer flex flex-row items-center justify-start`"><Icon id="chev-left" class="h-6 w-6" />First Page</div>
            <div @click="loadNext()" :class="`${!nextUrl ? 'opacity-20' : '' } py-3 duration-150  w-1/3 p-2 mn3-content-alt-hover cursor-pointer flex flex-row items-center justify-end`">Next Page<Icon id="chev-right" class="h-6 w-6" /></div>
        </div>    

            <div v-for="l in loans" :key="l.locked" 
                :class="`${confirmDelete == l.id ? 'mn3-alert' : ''} w-full flex flex-row justify-between items-center p-2 border-b`">
                <div class="flex flex-row items-center leading-4 mr-4">
                    <Icon :id="loanTypeToIcon(l.attributes.data.type)" class="h-12 w-12 mr-2" />
                    <div class="flex flex-col whitespace-nowrap">
                        <div class="font-bold">{{l.attributes.data.applicant.name}}{{(l.attributes.data.cosignerUsed ? ', ' + l.attributes.data.cosigner.name : '')}}</div>
                        <div class="text-sm">{{formatMoney(l.attributes.data.amount)}} {{l.attributes.data.type}} Loan</div>
                        <div class="text-sm">Applied on {{formatDate(l.attributes.created)}}</div>
                    </div>
                </div>
                <div v-if="confirmDelete != l.id" class="w-1/2 flex justify-end self-stretch">
                    <Button class="flex-grow" @click="reviewLoan(l.attributes, l.id)" label="Review Loan" icon="eye" title="Review Loan" />
                    <Button icon="trash" @click="confirmDelete = l.id;showDeny=true;" class="w-10 ml-2" title="Delete Application" />
                </div>
                <div v-else class="flex justify-end flex-col">
                    <div class="w-full text-center font-bold">Deny this loan?</div>
                    <div class="flex flex-row items-center w-full">
                        <Button icon="trash" class="w-1/2 leading-4 self-stretch" @click="denyLoan(l.id)" type="submit" label="Yes, Deny Loan" title="Deny this loan" />
                        <Button class="w-1/2 leading-4 self-stretch " @click="confirmDelete = '';showDeny=false;" type="cancel" label="No, Do Not Deny" title="Do not deny loan"/>                        
                    </div>
                </div>
            </div>
        </Form>
        <div v-else :class="`max-w-screen-lg w-full`">
        <Form
            ref="reviewForm"
            title="Reviewing Pending Application"
            :showClose="true"
            :showCancel="true"
            :ignoreCancelCooldown="true"
            @close="closeLoan()"
            @cancel="denyLoan(loanAppId)"
            @submit="exportToCore(loanAppId, loanApp)"
            :showSubmit="!showDeny && this.$store.state.fi.system == 'encompass'"
            submitText="Import to AMI"
            :cancelText="showConfirmSend ? 'Cancel' : 'Deny Application'"
            :class="`w-full flex flex-col mx-auto`">   
             <!--Basic Loan Info-->
            <div class="flex flex-col border w-full md:w-3/4 mx-auto my-2">
                    <div class="w-full mn3-content-alt p-2 uppercase justify-start flex flex-row"><Icon id="loan" class="h-6 w-6 mr-1" /><span>Basic Loan Information</span></div>
                <div class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Loan Type:</span><span class="text-right w-1/2">{{loanApp.data.type}}{{loanApp.data.typeSpecific ? ', ' + loanApp.data.typeSpecific : ''}}{{loanApp.data.typeCode ? '  (' + loanApp.data.typeCode+ ')' : ''}}</span></div>
                <div class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Loan Amount:</span><span class="text-right w-1/2">{{formatMoney(loanApp.data.amount)}}</span></div>
                <div class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Term:</span><span class="text-right w-1/2">{{loanApp.data.term}} Months</span></div>
                <div class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Frequency:</span><span class="text-right w-1/2">{{loanApp.data.freq}}</span></div>
            </div>
            
            <div :class="`${loanApp.data.cosignerUsed ? 'w-full' : 'w-full md:w-3/4 mx-auto'} flex flex-row mn3-content rounded-sm mn3-content rounded-sm`">
                <div v-for="apc in (loanApp.data.cosignerUsed ? [{type: 'Applicant', info: loanApp.data.applicant}, {type: 'Cosigner', info: loanApp.data.cosigner}] : [{type: 'Applicant', info: loanApp.data.applicant}])" :key="apc.info.name"
                    :class="`${loanApp.data.cosignerUsed ? 'w-2/5' : 'w-full'} flex flex-col border mx-auto my-2 `">
                    <div class="w-full mn3-content-alt p-2 uppercase justify-start flex flex-row">
                        <Icon :id="apc.type == 'Cosigner' ? 'users' : 'user-solid'" class="h-6 w-6 mr-1" /><span>{{apc.type}} Information</span><div class="flex-grow" />
                    </div>

                    <div v-for="field in [
                        {label: 'name', key:'name'},
                        {label: 'Account Id', key:'accountId'},
                        {label: 'email', key:'email'},
                        {label: 'Phone', key:'phoneHome'},
                        {label: 'Work Phone', key:'phoneWork'},
                        {label: 'Birthdate', key:'bday'},
                        {label: 'Social Security #', key:'ssn', locked: true, value: '***-**-' + apc.info.ssn.substring(7) },
                        {label: 'US Citizen', key:'citizen' },
                        {label: apc.type + 'Address', type: 'header'},
                        {label: 'Address Line 1', key:'addr1'},
                        {label: 'Address Line 2', key:'addr2'},
                        {label: 'City', key:'city'},
                        {label: 'State', key:'state'},
                        {label: 'Zip', key:'zip'},
                        {label: 'Mortgage/Rent', key:'addrRent'},
                        {label: 'Address Since', key:'addrSince'},
                        {label: apc.type + 'Finances', type: 'header'},
                        {label: 'Employer', key:'emp'},
                        {label: 'Position', key:'empPos'},
                        {label: 'Income', key:'empIncome'},
                        {label: 'Since', key:'empSince'}
                        ]" :key="field.key" class="w-full flex flex-col mn3-content-hover ">
                        <div v-if="field.type == 'header'"
                            class="w-full mn3-content-alt p-1 text-sm uppercase">
                            {{field.label}}
                        </div>
                        <label v-else class="flex flex-row cursor-pointer p-1 items-center">
                            <span class="w-1/2 font-bold capitalize">{{field.label}}:</span>
                            <input @blur="checkValueChange($event, field.key, apc.type)" 
                                :value="field.value != undefined  ? field.value : apc.info[field.key]" 
                                :readonly="field.locked || true"
                                :class="`${field.locked || true ? 'mn3-disabled' : 'mn3-content'} text-right w-1/2  p-1 rounded-sm border`" />
                        </label>
                        <div v-if="field.type != 'header'" class="w-full flex flex-row justify-end">
                            <div v-if="loanAppChanges[apc.type.toLowerCase()][field.key] != undefined &&loanAppChanges[apc.type.toLowerCase()][field.key] != ''" 
                                @click="undoValueChange(field.key, apc.type)"
                                title="Restore original value"
                                class="text-sm bg-red-600 -top-1 relative rounded-b text-white w-1/2 px-2 mr-1 pb-2 leading-3 line-through hover:no-underline w-min whitespace-nowrap text-right cursor-pointer p-1 opacity-80 hover:opacity-100">{{loanAppInitial[apc.type.toLowerCase()][field.key]}}</div>
                        </div>
                    </div>
                </div>
            </div>            
            <Notice v-if="error != ''" :notice="error" level="alert" title="Error" :showClose="true" @close="error = ''" class="mb-2"/>
            <div v-if="showDeny" class="w-full flex flex-row w-full items-center mt-10">               
            <Field type="text" 
                class="hidden text-xl w-full"
                label="Reason for Denial" 
                textFormat="text"
                :formValue="fv.reason"></Field>
            <div class="flex-grow pl-3 font-bold text-lg text-center">Are you sure you want to deny this application?</div>
            <div class="mn3-cancel p-2 rounded-sm cursor-pointer h-8 w-8 text-sm text-center mr-2" 
                @click="showDeny = false" title="Cancel Denying Application">X</div>
            </div>
            <div v-if="showConfirmSend" class="w-full flex flex-col w-full items-center mt-10">
                <div class="flex-grow pl-3 font-bold text-lg text-center"><span v-if="loanApp.data.applicant.accountId != ''">Importing Application for Account # {{loanApp.data.applicant.accountId}}. If Applicant’s Account Number is not correct, please enter their correct Account Number below. </span><span v-else>Importing Application for a Non-Member. If Applicant has an Account, please enter their Account Number below.</span></div>
                <Field label="Account # Override (optional)" :formValue="fv.accountOverride" class="w-full" type="text" />
            </div>
        </Form>
        </div>
	</div>
</template>

<script>
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Icon from "@/components/Icon.vue";
	import Notice from "@/components/Notice.vue";
    import { ref } from 'vue'

	export default {
		components:{
            Button,
			Form,
			Field,
            Icon,
            Notice
		},
        methods:{
            checkValueChange(evt, field, applicantType){

                //Cosigner field
                if(applicantType == 'Cosigner'){
                    this.loanApp.cosigner[field] = evt.target.value

                    if(evt.target.value != this.loanAppInitial.cosigner[field]){
                        this.loanAppChanges.cosigner[field] = evt.target.value
                    }
                    else{
                        this.loanAppChanges.cosigner[field] = '';
                    }
                }
                //Applicant field
                else if(applicantType == 'Applicant'){
                    this.loanApp.applicant[field] = evt.target.value

                    if(evt.target.value != this.loanAppInitial.applicant[field]){
                        this.loanAppChanges.applicant[field] = evt.target.value
                    }
                    else {
                        this.loanAppChanges.applicant[field] = '';
                    }
                }
            },
            closeLoan(){
                this.loanApp = '';
                this.error = '';
                this.showDeny = false;
                this.fv.reason.value = '';
            },
            async approveLoan(id){
                console.log('approveLoan: ', id);
            },   
            async denyLoan(id){
                // Canceling out of confirm exporting loan
                if(this.showConfirmSend){
                    this.showConfirmSend = false;
                    await new Promise(resolve => setTimeout(resolve, 1));  
                    this.$refs.reviewForm.resetCooldown();
                }
                else if(!this.showDeny){
                    this.showDeny = true;
                    await new Promise(resolve => setTimeout(resolve, 1));  
                    this.$refs.reviewForm.resetCooldown();
                }
                else{
                    let split = id.split('/');                    
                    if(split.length >= 4){
                        id = split[4];
                    }
                    this.$fapi.delete('loanapp/apps/' + id).then(r=>{
                        this.loadPendingApps();
                        this.showDeny = false;
                        this.loanApp = '';
                    });     
                }
            },    
            async exportToCore(loanAppId, loanApp){
                
                //If not already showing confirm account # form, display that
                if(!this.showConfirmSend){
                    this.showConfirmSend = true;
                    await new Promise(resolve => setTimeout(resolve, 1));  
                    this.$refs.reviewForm.resetCooldown();
                    return;
                }

                //Otherwise submit the post
                //Check for including account # override
                let data = {}                
                if(loanApp.data.applicant.accountId != this.fv.accountOverride.value && this.fv.accountOverride.value != ''){
                    data['account'] = this.fv.accountOverride.value;
                }

                this.$fapi.post('loanapp/apps/' + loanAppId + '/export', data).then(r=>{
                    this.showDeny = false
                    this.loanApp = ''
                    this.loadPendingApps
                    this.notice = 'Loan app imported to AMI'
                    this.fv.accountOverride.value = '';
                }).catch((error) => {
                            console.log('exporeToCore.error[0]: ', error.response.data.errors[0].detail);
                            this.error = error.response.data.errors[0].detail;
                        });    
            },
            formatDate(raw){
                let date = new Date(raw).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                });
                return date;
            },
            loadFirst(){
                if(this.firstUrl)
                    this.loadPendingApps(this.firstUrl);
            },
            loadNext(){
                if(this.nextUrl)
                    this.loadPendingApps(this.nextUrl);
            },
            async loadPendingApps(url){     
                let fetchUrl = url ? url : 'loanapp/apps'           
                let fetched = await this.$fapi.get(fetchUrl);
                this.loans = fetched.data.data;
                for(var ii = 0; ii < this.loans.length; ii++){
                    this.loans[ii].attributes.data = JSON.parse(this.loans[ii].attributes.data);
                }
                this.nextUrl = null
                this.firstUrl = null
                if(fetched.data.links){
                    if(fetched.data.links.next)
                        this.nextUrl = fetched.data.links.next 
                    if(fetched.data.links.first)
                        this.firstUrl = fetched.data.links.first 
                }
            },
            loanTypeToIcon(loanType){

                switch(loanType.toLowerCase()){
                    case 'home':
                    case 'mortgage':
                        return 'home';

                    case 'vehicle':
                    case 'new auto':
                    case 'used auto':
                    case 'tractor':
                        return 'truck';

                    default:
                        return 'receipt';
                        
                }
            },
            reviewLoan(attributes, id){
                this.fv.accountOverride.value = '';
                this.showDeny = false;
                this.error = '';
                this.loanApp = attributes;
                let split = id.split('/');
                this.loanAppId = split[split.length-1];
                this.loanAppInitial = structuredClone(attributes);
                this.loanAppChanges = {
                    applicant: {},
                    cosigner: {}
                };
            },
            undoValueChange(field, applicantType){
                if(applicantType == 'Cosigner'){
                    this.loanAppChanges.cosigner[field] = '';
                    this.loanApp.cosigner[field] = this.loanAppInitial.cosigner[field];
                }else{
                    this.loanAppChanges.applicant[field] = '';
                    this.loanApp.applicant[field] = this.loanAppInitial.applicant[field];
                }
            }
        },
		data(){
			return {
			};
		},

		created(){
            document.title = 'Pending Loan Applications'
            this.loadPendingApps();
		}, 

        setup(){
                        
            const firstUrl = ref('')
            const nextUrl = ref('')
            const reason = ref('')
            const loanApp = ref('')
            const loanAppId = ref('')
            const error = ref('')
            const notice = ref('')
            const loanAppInitial = ref({})
            const loanAppChanges = ref(
                {
                    applicant: {},
                    cosigner: {}
                }
            ) //Current loan app without any changes
            const confirmDelete = ref('')

            const loans = ref([]);
            
            let mon = new Intl.NumberFormat('en-US', {  //mon: Used for money formatting
                style: 'currency',
                currency: 'USD'
            });
            const showConfirmSend = ref(false)
            const showDeny = ref(false)

			/* Form field values */
			let fv = {}  
            fv['reason'] = ref('')               //fv.reason: Loan denial reason
            fv['accountOverride'] = ref('')

            function formatMoney(val){
                if(isNaN(parseFloat(val)))
                    return '';
                return mon.format(val);
            }       
            
            return { 
                confirmDelete,
                error,
                firstUrl,
                formatMoney,
				fv,
                loanApp,
                loanAppId,
                loanAppChanges,
                loans,
                nextUrl,
                notice,
                showConfirmSend,
                showDeny
            }

        }
	}
	
</script>
