<template>
    <div class="w-full">
        <canvas class="hidden"></canvas>
    <!--Check capture component-->
    <CheckCapture 
        v-if="showCapture"
        class="absolute top-0 left-0"
        style="z-index: 999;"
        :capturingBack="!capturingFront"
        :endorseName="$store.state.fi.title"
        @capture="checkCaptured"
        @close="showCapture = false" />
    <!--Main screen display-->
    <div v-else class="flex flex-col items-center w-full max-w-screen-md mx-auto" :key="screenKey">
        <div v-if="state == 'history'" class="w-full items-center flex flex-col">
            <div class="w-full p-2">
                <div class="mn3-submit border mx-2 md:mx-0 p-2 rounded cursor-pointer text-center"
                    @click="setState('new')">+ New Deposit</div>
            </div>
            <Table 
                title="Deposit History"
                table="captures"
                class="w-full mt-5"
                :useRecords="false"
                :hasQuickFilter="false">
                <div v-if="history.length == 0" class="w-full text-center mn3-content p-2 border rounded-sm">[No deposit history found]</div>
                <div v-for="deposit in history" :key="deposit.id"
                    :class="`flex items-center border-b p-2 w-full cursor-pointer ${depositContainerClasses(deposit.attributes.status)}`">
                    <div class="flex flex-col text-sm">
                        <p>{{formatDate(deposit.attributes.dateTimeSubmitted)}}</p>
                        <div :class="`text-xs text-center rounded p-1 font-bold uppercase ${depositStatusClasses(deposit.attributes.status)}`">
                            {{(deposit.attributes.status == 'accepted' ? 'approved' : deposit.attributes.status)}}
                        </div>
                    </div>
                    <div class="flex flex-col flex-grow text-right">
                        <p class="text-xl text-right flex-grow">{{formatMoney(deposit.attributes.amount)}}</p>
                        <div class="text-xs opacity-70">{{toAccountNames[deposit.attributes.toAccountId]}}</div>
                    </div>
                </div>
            </Table>
        </div>
        <div v-else-if="state == 'success'" class="w-full">
            <div class="flex flex-col w-full justify-center items-center mb-6 mn3-content p-2 rounded">
                <div class="uppercase rounded text-center w-full flex items-center justify-center">
                    <Icon id="clock" class="w-8 h-8" /><p class="text-2xl font-bold">Pending Deposit</p>
                </div>
                <div class="w-full text-center text-sm mt-1">Your check is being deposited and should be processed soon.</div>

                <div class="flex flex-col items-center mt-3">
                    <p>Deposit Amount:</p><p class="font-bold text-2xl">{{formatMoney(successData.amount)}}</p>
                </div>
                <div class="flex flex-col mt-3 items-center">
                    <p>To Account:</p><p class="font-bold text-2xl">{{toAccountNames[successData.toAccountId]}}</p>
                    <p class="font-bold">(Available Balance: {{formatMoney(toAccountBalances[successData.toAccountId])}})</p>
                </div>
                <div class="flex flex-col md:flex-row justify-around w-full mt-5">
                    <div class="mn3-button text-center cursor-pointer p-2 mr-2 rounded w-full md:w-1/2" @click="setState('new')">Make Another Deposit</div>
                    <div class="mn3-button text-center cursor-pointer p-2 rounded w-full md:w-1/2 mt-2 md:mt-0" @click="setState('history')">View Existing Deposits</div>
                </div>
            </div>
        </div>
        <div v-else-if="state == 'new'" class="w-full">
            <div v-if="!hasAgreedToTerms">
                <Form title="Remote Deposit Capture Terms and Conditions" 
                    submitText="Continue"
                    @submit="checkTerms"
                    class="w-full">
                    <div class="flex flex-col">
                        <div class="flex items-center mn3-content-hover border rounded w-full cursor-pointer">
                            <Icon id="export" class="h-5 w-5 mx-3"/>
                            <a :href="termsUrl" target="_blank">View Terms and Conditions</a>
                        </div>
                        <label for="terms-accept" class="normal-label flex justify-start mn3-content-hover items-center m-3 cursor-pointer opacity-80 hover:opacity-100 border rounded-sm p-2 box-border">
                            <input v-model="acceptTerms" type="checkbox" class="w-6 h-6" id="terms-accept" />
                            <div class="ml-5" for="terms-accept">I Accept the Terms and Conditions</div>
                        </label>
                    </div>
                </Form>
            </div>
            <div v-else>
                <Form title="Remote Deposit Capture" 
                    v-if="showInstructions"
                    submitText="Continue"
                    @submit="showInstructions = false"
                    class="w-full">
                    <div class="px-3 flex flex-col">
                        <div v-if="endorseInstructions == '' || endorseInstructions == undefined" class="mn3-content flex justify-start items-center border rounded my-2 font-italic p-2 text-sm font-bold">
                            <Icon id="flag" class="flex-0 h-12 w-12 items-center flex mr-2" />
                            <span>Endorse the back of the check with "{{endorseText}}", followed by your signature.</span>
                        </div>
                        <div v-if="endorseInstructions == '' || endorseInstructions == undefined" class="mn3-content flex justify-start items-center border rounded mb-2 font-italic p-2 text-sm font-bold">
                            <Icon id="flag" class="flex-0 h-12 w-12 items-center flex mr-2" />
                            <span>Securely store your deposit for {{saveDays}} business days before destroying.  For details, refer to the Mobile Terms and Conditions.</span>
                        </div>
                        <div v-else class="mn3-content flex justify-start items-center border rounded my-2 font-italic p-2 text-sm font-bold">
                            <div v-html="endorseInstructions" />
                        </div>
                    </div>
                </Form>
                <Form title="Remote Deposit Capture"
                    v-else
                    class="w-full"
                    :showSubmit="!depositProcessing"
                    @submit="onDeposit"
                    submitText="Deposit Check">
                    <div class="flex w-full">
                        <div @click="setState('history')" class="mn3-content-hover text-xs cursor-pointer p-3 w-full text-left border-b text-center">Return to History</div>
                        <div @click="showInstructions = true" class="mn3-content-hover text-xs cursor-pointer p-3 w-full text-left border-b text-center">View Deposit Instructions</div>
                    </div>
                    <Field type="select" 
                        label="Account" 
                        :formValue="fv.toAccountId"
                        :options="toAccountOptions"
                        />
                    <Field type="text" 
                        :formValue="fv.amount"
                        label="Amount" 
                        :desc="`Deposit Limit: ${formatMoney(depositLimit)} per day`"
                        textFormat="amount"
                        align="right" />
                        
                    <div class="w-full flex items-center text-lg uppercase leading-5 font-bold">
                        <div class="mn3-button border flex flex-col items-center py-3 w-50 m-3 mb-1 rounded cursor-pointer w-1/2 flex-0 text-center h-32 px-3" 
                            @click="captureFront">
                            <Icon id="camera" class="h-16 w-16 flex-0" />
                            <p v-if="!capturedFront">Capture Front</p>
                            <p v-else>Re-Capture Front</p>
                        </div>
                        <div class="mn3-button border flex flex-col items-center py-3 w-50 m-3 mb-1 rounded cursor-pointer w-1/2 flex-0 text-center h-32 px-3" 
                            @click="captureBack">
                            <Icon id="camera" class="h-16 w-16 flex-0" />
                            <p v-if="!capturedBack">Capture Back</p>
                            <p v-else>Re-Capture Back</p>
                        </div>
                    </div>
                    <div class="w-full flex mt-3">
                        <div v-if="!processingFront" class="w-1/2 flex-0 px-3 flex flex-col">
                            <img v-if="capturedFront" class="rounded-sm" :src="imgFront" />
                            <img v-else @click="captureFront" class="w-full h-auto flex-0 rounded-sm duration-300 hover:opacity-80 cursor-pointer" src="@/assets/img/capture-front.png" />
                            
                        </div>
                        <div v-else class="w-1/2 flex-0 px-3 flex flex-col text-center mn3-highlight h-24 text-xl font-bold rounded">
                            Processing Image...
                        </div>
                        
                        <div v-if="!processingBack" class="w-1/2 flex-0 px-3">          
                            <img v-if="capturedBack" class="rounded-sm" :src="imgBack" />
                            <img v-else @click="captureBack" class="w-full h-auto flex-0 rounded-sm duration-300 hover:opacity-80 cursor-pointer" src="@/assets/img/capture-back.png" />
                            
                        </div>
                        <div v-else class="w-1/2 flex-0 px-3 flex flex-col text-center mn3-highlight h-24 text-xl font-bold rounded">
                            Processing Image...
                        </div>
                    </div>
                    
                    <div class="mn3-content flex justify-start items-center border rounded mb-3 mx-3 p-2 text-sm">
                        <Icon id="info" class="h-9 w-9 mr-2 flex items-center" />
                        For best capture, place deposit on a flat surface with a dark background and good lighting. 
                    </div>
                    <div v-if="depositProcessing" class="w-full m-2 text-center mn-content">Deposit Processing...</div>
                    <div class="mn3-alert mx-0 px-2 py-3 text-center flex justify-center text-sm" v-if="depositError != ''"><p class="font-bold mr-2">Deposit Error:</p><p>{{depositError}}</p></div>
                </Form>
            </div>
            <div class="mt-5 text-sm mn3-content md:rounded w-full">
                <p v-if="footerMessage == '' || footerMessage == undefined" class="py-2 px-4">Check will be held for {{holdDays}} business day before balance is available.</p>
                <p v-else v-html="footerMessage" class="py-2 px-4" />
                <p class="py-2 px-4 border-t ">For more information on deposit limits and availability, please call us at {{phone}}.</p>
            </div>
        </div>
    </div>
    </div>
</template>
<script>    
    import { ref } from 'vue'
    
    import CheckCapture from "@/components/CheckCapture.vue";
    import Field from "@/components/Field.vue";
    import Form from "@/components/Form.vue";
    import Icon from "@/components/Icon.vue";
    import Table from "@/components/Table.vue";
    
    export default {
      name: 'ImgCapture',
      components: {
          CheckCapture,
          Form,
          Field,
          Icon,
          Table
        }, 
        async created(){
            document.title="Remote Deposit Capture"

            //Exit screen if fi doesn't have RDC enabled
            if(!this.rdcEnabled){
                this.$router.push('/');
            }
            //Load RDC history data and settings
            else{           

                try{
                    const recordData = await this.$mapi.get("rdc");
                    this.depositLimit = recordData.data.meta.rdc.scanLimit
                    this.hasAgreedToTerms = recordData.data.meta.rdc.hasAgreedToTerms 
                    this.history = recordData.data.data;  
                    this.termsUrl = recordData.data.meta.rdc.termsUrl     
                    this.phone = recordData.data.meta.rdc.phone
                    this.holdDays = recordData.data.meta.rdc.holdDays
                    this.saveDays = recordData.data.meta.rdc.saveDays
                    this.endorseInstructions  = recordData.data.meta.rdc.endorseInstructions
                    this.footerMessage = recordData.data.meta.rdc.footerMessage 

                    // set a url if null so demo still opens new window
                    if(this.termsUrl == null)
                        this.termsUrl = 'http://google.com'                    
                        
                    // build dropdown option values for the deposit account               
                    for(var key in recordData.data.meta.rdc.toAccounts){
                        let acct = recordData.data.meta.rdc.toAccounts[key]
                        
                        this.toAccountOptions.push({
                            label: `${acct.type}: ${acct.displayAccountId} • ${(acct.nickname != undefined) ? acct.nickname : acct.description}`,
                            value: acct.id})
                        this.toAccountNames[acct.id] =  `${acct.type}: ${acct.displayAccountId}`;
                        this.toAccountBalances[acct.id] = acct.availableBalance;
                    }     
                } catch(ex){
                    console.log("Reload Error Catch: ", ex);   

                    //Force inactive logout
                    this.$store.commit('setShowInactiveNotice', true);           
                    this.$authapi.logout();
                }
            }            
        },
        computed:{
            //What to endorse on the back of a check
            endorseText(){                
                // Southern Pine
                if(this.$store.state.fi.id == '16'){
                    return `FOR REMOTE DEPOSIT ONLY, Acount #, SPCU`;
                }else{
                    return `FOR REMOTE DEPOSIT ONLY, ${this.$store.state.fi.title}`
                }
            },
            rdcEnabled(){            
                let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
                let membStatus = "A";
                if(mship)
                    membStatus = mship.attributes.status;			

                return this.$store.state.fi.rdcEnabled && membStatus != 'R' && membStatus != 'B'
            }
        },
        methods: {
            /* Converts blob data from camera to base64 */
            blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                });
            },
            /* Begin capture of back of check */
            async captureBack(){
                document.activeElement.blur();
                await new Promise(resolve => setTimeout(resolve, 750));
                this.capturingFront = false;
                this.showCapture = true;
            },
            /* Begin capture of front of check */
            async captureFront(){
                document.activeElement.blur();
                await new Promise(resolve => setTimeout(resolve, 750));
                this.capturingFront = true;
                this.showCapture = true;
            },
            /* Image received from CheckCapture */
            async checkCaptured(e){

                if(this.capturingFront){
                    let toBase = await this.blobToBase64(e);
                    this.imgFront = toBase
                    this.capturedFront = true;

                    let self = this

                    //Compress the submitted image
                    this.compress(this.imgFront, function(resultBase64) {
                        if(resultBase64 != undefined)
                            self.imgFrontCompressed = resultBase64;
                    });

                    //Check for rotating the displayed image
                    this.dimFront = await this.getImageDimensions(this.imgFront)
                    if(parseInt(this.dimFront.h) > parseInt(this.dimFront.w))
                        this.rotateFront();


                }else{
                    let toBase = await this.blobToBase64(e);
                    this.imgBack = toBase
                    this.capturedBack = true;

                    let self = this

                    //Compress the submitted image
                    this.compress(this.imgBack, function(resultBase64) {
                        if(resultBase64 != undefined)
                            self.imgBackCompressed = resultBase64;
                    });

                    //Check for rotating the displayed image
                    this.dimBack = await this.getImageDimensions(this.imgBack)
                    if(parseInt(this.dimBack.h) > parseInt(this.dimBack.w))
                        this.rotateBack();
                }
            },
            /* Check if RDC terms have been accepcted */
            checkTerms(){
                if(this.acceptTerms){
                    this.hasAgreedToTerms = true
                    this.$mapi.post("rdc-agree-to-terms", {})
                }else{
                    alert('You must accept the terms and conditions.');
                }    
            },               
            /* Classes applied to a deposit container */
            depositContainerClasses(status){
                switch(status){
                    case 'pending':
                    case 'accepted':
                        return ' mn3-content-hover ';
                    case 'rejected':
                        return ' mn3-rejected ';
                }
                return ''
            },
            /* Classes applied to a deposit */
            depositStatusClasses(status){
                switch(status){
                    case 'pending':
                        return ' mn3-pending  ';
                    case 'accepted':
                        return ' mn3-accepted ';
                    case 'rejected':
                        return ' mn3-rejected ';
                }
                return ''
            },
            /* Format a date for display */
            formatDate(raw){
                let date = new Date(raw).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                });
                return date;
            }, 
            /* Format an amount of money for display */
            formatMoney(val){
                if(isNaN(parseFloat(val)))
                    return '';
                return this.mon.format(val);
            },
            getImageDimensions(srcBase64) {
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                    resolved({w: i.width, h: i.height})
                    };
                    i.src = srcBase64
                })
            },
            /* Verify submission for issues, then post */
            async onDeposit(){

                let submitFront = this.imgFrontCompressed
                let submitBack = this.imgBackCompressed

                // cut off image type data if needed     
                if(submitFront != undefined)               
                if(submitFront.indexOf(',') != -1){
                    submitFront = submitFront.split(',')[1]
                }                     
                if(submitBack != undefined)          
                if(submitBack.indexOf(',') != -1){
                    submitBack = submitBack.split(',')[1]
                }
                
                this.depositError = ''

                //return;
                let data = {
                    "data": {
                        "attributes": {
                            "toAccountId": this.fv['toAccountId'].value,
                            "amount": parseFloat(this.fv['amount'].value),
                            "front": submitFront,
                            "back": submitBack
                        }
                    }
                };                    

                //Check form values
                if(data.data.attributes.amount == 0 || isNaN(data.data.attributes.amount)){
                    this.depositError = 'Amount of deposit must be entered.'
                    this.depositProcessing = false;
                }else if(data.data.attributes.amount > this.depositLimit){
                    this.depositError = 'Amount is above your deposit limit.'
                    this.depositProcessing = false;
                }else if(data.data.attributes.toAccountId == ''){
                    this.depositError = 'Account to deposit to must be selected.'
                    this.depositProcessing = false;
                }else if(data.data.attributes.front == undefined){
                    this.depositError = 'Front of check must be photographed.'
                    this.depositProcessing = false;
                }else if(data.data.attributes.back == undefined){
                    this.depositError = 'Back of check must be photographed.'
                    this.depositProcessing = false;
                }
                //No value issues, post
                else{     
                    this.depositProcessing = true;
                    this.$mapi.post('rdc', data).then(r=>{
                        this.state = 'success';
                        this.depositProcessing = false;
                        this.successData = {amount: data.data.attributes.amount, toAccountId: data.data.attributes.toAccountId}
                        this.fv['toAccountId'].value = ''
                        this.fv['amount'].value = ''
                        this.imgFront = ''
                        this.imgBack = ''
                        this.capturedBack = false
                        this.capturedFront = false
                        this.depositError = ''
                        this.reloadHistory()
                    })
                    .catch((ex) => {
                        
                        this.state = 'new';
                        this.depositProcessing = false;

                        if(ex.response != undefined){
                            let er = ex.response.data.errors[0].detail;
                            let code = ex.response.data.errors[0].status;
                            console.log('ER: [', er, ']');
                            switch(er){
                                default:
                                    if(code == "500"){
                                        if(er.length <= 7)
                                            this.depositError = "500: Internal Server Error - Deposit unable to be completed at this time.";
                                        else
                                            this.depositError = er;

                                    }else{
                                        this.depositError = er;
                                    }
                                    break;
                                case "Error processing image: Processing error":
                                    this.depositError = 'There was an error processing your check photo.  Please make sure to place the check on flat surface with a dark background and good lighting.'
                                    break;
                            }
                        }else{console.log('Undefined response')}
                    });
                }
            },
            /* Reload RDC history */
            async reloadHistory(){
                const recordData = await this.$mapi.get("rdc");
                this.depositLimit = recordData.data.meta.rdc.scanLimit
                this.history = recordData.data.data;   
            },         
            /* Compress image to grayscale jpeg 0.5 quality */      
            compress(srcBase64, callback) {
                const canvas = document.createElement('canvas');
                let ctx = canvas.getContext("2d");
                let image = new Image();

                image.onload = function () {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    //ctx.filter = 'grayscale(1)';
                    ctx.drawImage(image, 0, 0, image.width, image.height);

                    callback(canvas.toDataURL('image/jpeg', 0.5));
                };

                image.src = srcBase64;
            },  
            /* Rotate a bas64 image */      
            rotate(srcBase64, degrees, callback) {
                const canvas = document.createElement('canvas');
                let ctx = canvas.getContext("2d");
                let image = new Image();

                image.onload = function () {
                    canvas.width = degrees % 180 === 0 ? image.width : image.height;
                    canvas.height = degrees % 180 === 0 ? image.height : image.width;

                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(image, image.width / -2, image.height / -2);

                    callback(canvas.toDataURL('image/'+this.imgFormat, this.imgQuality));
                };

                image.src = srcBase64;
            },
            /* Rotate the captured back image */
            rotateBack(){
                this.processingBack = true;
                let self = this
                this.rotate(self.imgBack, -90, function(resultBase64) {
                    if(resultBase64 != undefined)
                        self.imgBack = resultBase64;

                    self.processingBack = false;
                });
            },
            /* Rotate the captured front image */
            rotateFront(){
                this.processingFront = true;
                let self = this
                this.rotate(self.imgFront, -90, function(resultBase64) {
                    if(resultBase64 != undefined)
                        self.imgFront = resultBase64;

                    self.processingFront = false;
                });
            },
            /* Set the state of the screen and re-render*/
            setState(val){
                this.state = val;
                this.$router.push('/capture/' + val);
                this.screenKey++;
            }
        },
        setup(props){
    
		/* --Variables-- */		
        const acceptTerms = ref(false)                  // If accept terms box is checked   
        const capturedBack = ref(false)                 // Back captured status
        const capturedFront = ref(false)                // Front captured status
        const capturingFront = ref(false)               // Capturing front of check as opposed to back
		const depositError = ref('')					// Any error that occurred with a new deposit
		const depositLimit = ref(9999.99)				// Single deposit limit for user
        const depositProcessing = ref(false)            // Deposite post is processing
        const dimBack = ref({})
        const dimFront = ref({})
		let fv = {}										// Array of refs used to store input values for the screen
    	    fv["toAccountId"] = ref("")					// Account to deposit capture to
    	    fv["amount"] = ref()						// Amount of deposit
		const hasAgreedToTerms = ref(false) 			// If user has previously agreed to terms
		const history = ref([])							// Account deposit history
		const holdDays = ref()                          // Days check is held
		const phone = ref()                             // Support phone #
		const imgBack = ref()							// Base64 capture of the reverse of the deposit
        const imgBackCompressed = ref()
        const imgFormat = ref('jpeg');                  // Image format after rotation
		const imgFront = ref()							// Base64 capture of the front of the deposit
        const imgFrontCompressed = ref()
        const imgQuality = ref(0.3);                    // Quality of image after rotation
    	const mon 										// Used to format money for display
			= new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
        const processingBack = ref(false)               // Currenlty processing back capture
        const processingFront = ref(false)              // Currenlty processing front capture
		const saveDays = ref()                          // Days member should save check
        const screenKey = ref(0)                        // Used to force re-render of screen elements
        const showCapture = ref(false)                  // Show the capture component
		const showInstructions = ref(true)    			// If deposit instructions should be shown
		const state = ref('history') 					// Current screen state
		const successData = ref({})  					// Data returned from a successful deposit
		const termsUrl = ref()							// URL for RDC terms        
        const toAccountBalances = ref({})               // Balances of account
	    const toAccountNames = ref({})   				// Display names for accounts
		const toAccountOptions = ref([])   				// Options for dropdown account selector
        const footerMessage = ref('');
        const endorseInstructions = ref('')             

        return {
          acceptTerms,
          capturedBack,
          capturedFront,
          capturingFront,
          depositError,
          depositLimit,
          depositProcessing,          
          dimBack,
          dimFront, 
          footerMessage,
          endorseInstructions,
          fv,
          hasAgreedToTerms,
          history,
          holdDays,
          imgBack,
          imgBackCompressed,
          imgFormat,
          imgFront,
          imgFrontCompressed,
          imgQuality,
          mon,
          phone,
          processingBack,
          processingFront,
          saveDays,
          screenKey,
          showCapture,
          showInstructions,
          state,
          successData,
          toAccountBalances,
          toAccountNames,
          toAccountOptions,
          termsUrl
        }
    
      }
    }
</script>
