<template>
    <div class="w-96">
        <div class="bg-white border-gray-300 border rounded p-3 text-sm flex items-center mb-4" v-if="$store.state.isLoggedInAny">
            <Icon id="exclamation" class="w-6 h-6 mr-1 flex-shrink-0" />Please wait while we log you out...
        </div>
        <div v-else class="bg-white border-gray-300 border rounded p-3 text-sm flex items-center mb-4" >
            <Icon id="exclamation" class="w-6 h-6 mr-1 flex-shrink-0" />You are no longer logged in.
        </div>
  </div>
</template>

<script>
import { ref } from "vue";

import Icon from "@/components/Icon.vue";

export default {
  name: "Login",
  components: {
    Icon
  },
  created(){
        document.title="Logout";
  },
  mounted() {
		this.$mapi.logout();
  }
};
</script>


<style>
.down-enter-from {
  opacity: 0;
  transform: translateY(-50px);
}

.down-enter-active {
  transition: all 0.15s ease-in;
  z-index: -2;
}

.down-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.down-leave-active {
  transition: all 0.15s ease-out;
  z-index: -2;
}
</style>
