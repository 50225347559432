<!-- Button: Generic styled button used in forms -->
<template>
    <div :class="`mn3-${type} px-${padding} py-${padding} rounded cursor-pointer shadow border text-${text} flex flex-row justify-center items-center 
        ${buttonClasses}
        ${(bold ? ' font-bold' : '')}text-center`">
        <Icon v-if="icon != ''" :id="icon" 
            :class="`h-${iconSize} w-${iconSize} ${label != undefined ? 'mr-1' : ''}`" />
        <div>{{label}}</div>
    </div>
</template>

<script>
	import Icon from "@/components/Icon.vue";

export default{
    components:{
        Icon
    },
    props:{        
    /* Properties without defaults */
    label: String,                      //label: String displayed on the button

    /* Properties with defaults */
    icon: {
        type: String,
        default: ''
    },
    padding: { 
        type: Number,                   //padding: Padding of the button
        default: 2
    },
    buttonClasses: {                    //buttonClasses: Classes applied to button DOM
        type: String,
        default: ""
    },
    text: {                             //text: Size of the text used
        type: String,
        default: "lg"
    },
    type: {                             //type: Style of the button used
        type: String,
        default: "button"
    },
    bold: {                             //bold: If label should be in bold
        type: Boolean,
        default: false
    }

    },
    computed:{
        iconSize(){
            switch(this.$props.text){
                case 'lg': return '5';
                case 'sm': return '4';
            }

            return '5';
        }
    },
    setup( props ){

    }
}

</script>
