<!-- Field: Displays a single field of a form -->
<template>
    <div v-if="link != undefined && !newLinkCanceled"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
        :class="`${link.editing || editOnly ? 'border roudned-sm p-2' : ''}`">
    <!--Editing Link-->
    <div class="flex flex-col" v-if="link.editing || editOnly">
        
        <!--style Select-->
        <div v-if="screen == 'services'" class="w-full flex flex-row">
            <LinkTile
                    @click="showStyleSelect = !showStyleSelect"
                    :theme="link.attributes.style != undefined ? link.attributes.style : 'default'"
                    :title="link.attributes.style != undefined ? link.attributes.style : 'Default'"
                    icon="theme"
                    description="Tile Theme"
                    :descriptionFirst="true"
                    size="3"
                    iconSize="12"
                    height="20"
                />
            <div class="flex flex-col my-1 p-1 border rounded-sm items-center">
                <div class="font-bold">Tile Size</div>
                <select v-model="link.attributes.size"
                    class="flex-grow">
                    <option value="1">Small</option>
                    <option value="2">Medium</option>
                    <option value="3">Large</option>
                </select>
            </div>
        </div>
        <!--Style Select Grid-->
        <div v-if="showStyleSelect" class="w-full flex flex-col mb-5">
            <div class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                <input type="text" 
                    class="mn3-content rounded-sm p-1 border text-sm w-full" 
                    placeholder="Filter Themes" 
                    v-model="styleFilter"   />
            </div>      
            <div class="w-full flex flex-row flex-wrap overflow-y-auto" style="max-height: 500px">
                            
                <div class="w-1/2" 
                    @click="link.attributes.style = style;showStyleSelect = false"
                    v-for="style in styles"
                    :key="style">
                <LinkTile
                    :theme="style"
                    :title="style"
                    icon="theme"
                    size="2"
                    iconSize="6"
                    height="20"
                /></div>

            </div>
            <div class="w-full flex flex-row">
                <Button @click="showStyleSelect = false" class="flex-grow" type="cancel" label="Cancel Theme Select" />
            </div>

        </div>

        <div v-if="screen != 'services'" class="w-full flex flex-row">
            <div @click="customMode = true" title="Link to an external website" :class="`${customMode ? 'opacity-100' : 'opacity-50 hover:opacity-100'} cursor-pointer duration-150 mn3-button w-1/2 text-center justify-center flex flex-row items-center`">
                <Icon id="check" :class="`${customMode ? '' : 'opacity-0'} h-4 w-4 mr-2`" />
                <span>Custom Link</span>
            </div>
            <div @click="customMode = false" title="Link to a MemberNet3 screen" :class="`${!customMode ? 'opacity-100' : 'opacity-50 hover:opacity-100'} cursor-pointer duration-150 mn3-button w-1/2 text-center justify-center  flex flex-row items-center`">
                <Icon id="check" :class="`${!customMode ? '' : 'opacity-0'} h-4 w-4 mr-2`"  />
                <span>Screen Link</span>
            </div>
        </div>
        <!--Screen select instead of custom-->
        <div v-if="screen  != 'services' && !customMode" class="w-full flex flex-col">
            
            <div class="flex flex-row items-center justify-around px-5 py-2">
                    <label :for="`screensel`" class="font-bold leading-3">Screen</label>
                    <Icon id="web" class="h-6 w-6 mx-2" />
                    <select v-model="screenSelect"
                        :id="`screensel`"
                        class="py-2 rounded-sm flex-grow">
                        <option v-for="opt in screenOptions"
                            :key="opt.value"
                            :value="opt.value">
                            {{opt.label}}
                        </option>
                    </select>
                </div>
        
        </div>
        <!--Custom-->
        <div v-else class="w-full flex flex-col">
        <div class="w-full flex flex-row items-center">
            <label for="linkTitle" class="cursor-pointer"><Icon id="pencil" class="h-6 w-6 mr-2" title="Link Title" /></label>
            <input v-model="link.attributes.title"
                id="linkTitle"
                placeholder="Link Title"
                :class="`${screen=='custom' ? 'customLinkTitle' : 'serviceLinkTitle'} pt-3 pb-2 block flex-grow px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none 
                    focus:outline-none focus:ring-0 focus:border-black mn3-input-text`" />
                    
                <!-- Group Select -->
                <div v-if="screen == 'services'" class="hidden flex flex-row items-center flex-shrink">
                    <select v-model="groupSelect" class="flex-shrink-0 border rounded mn3-button ">
                        <option value="">No Group</option>
                        <option v-for="group in groups" :key="group.id" :value="group.id">{{group.attributes.titleUse}}</option>
                    </select>
                </div>
        
            </div>
            
        <div v-if="screen == 'services' && link.attributes.size != 1" class="w-full flex flex-row items-center">
            <label for="linkDesc" class="cursor-pointer"><Icon id="book-open" class="h-6 w-6 mr-2" title="Additional Description (Optional)" /></label>
            <input v-model="link.attributes.description"
                id="linkDesc"
                placeholder="Additional Description (Optional)"
                :class="`${screen=='custom' ? 'customLinkDescription' : 'serviceLinkDescription'} pt-3 pb-2 block flex-grow px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none 
                    focus:outline-none focus:ring-0 focus:border-black mn3-input-text`" />
            </div>
        <div class="flex flex-row w-full items-center py-2">
            <label for="linkUrl" class="cursor-pointer"><Icon id="link" class="h-6 w-6 mr-2" title="Link URL" /></label>
            <input v-model="link.attributes.url"
                id="linkUrl"
                placeholder="Link URL"
                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none 
                    focus:outline-none focus:ring-0 focus:border-black mn3-input-text" />
        </div>


        <div class="flex flex-row w-full flex-wrap items-center py-2">
            <!--Icon Select-->
            <div class="flex flex-row items-center w-full cursor-pointer" @click="showIconSelect = !showIconSelect"><Icon :id="link.attributes.icon" class="h-6 w-6 mr-2" title="Link Icon" />
            <div class="m3-input-border border-b-2 py-1 flex flex-row items-center w-full">
                <div class="font-bold">Link Icon:</div>
                <div class="capitalize pl-2 flex-grow">{{link.attributes.icon}}</div>
            </div>
            </div>
        </div>
        
        <!--Icon Select Grid-->
        <div v-if="showIconSelect" class="w-full flex flex-col mb-5">
            <div class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                <input type="text" 
                    class="mn3-content rounded-sm p-1 border text-sm w-full" 
                    placeholder="Filter Icons" 
                    v-model="iconFilter"   />
            </div>
            <div class="w-full flex flex-row flex-wrap overflow-y-auto" style="max-height: 500px">
                <div v-for="icon in icons" :key="icon" 
                    @click="link.attributes.icon = icon; showIconSelect = false;"
                    :title="icon"
                    :class="`${iconFilter != '' && icon.indexOf(iconFilter) == -1 ? 'hidden' : ''} w-1/6 p-2 opacity-50 hover:opacity-100 duration-100 cursor-pointer`">
                    <Icon :id="icon" />
                    <div v-if="iconFilter != ''" class="text-xs w-full text-center leading-3">{{icon}}</div>
                </div>
            </div>
            <div class="w-full flex flex-row">
                <Button @click="showIconSelect = false" class="flex-grow" type="cancel" label="Cancel Icon Select" />
            </div>
        </div>
    </div>
        <div v-if="!editOnly && !showIconSelect && !showStyleSelect" class="w-full flex flex-row">
            <Button @click="saveChanges(link)" class="flex-grow" type="submit" label="Save Link" />
            <Button @click="cancelChanges(link)" type="cancel" label="Cancel" />
        </div>
    </div>  
    <!--Deleting Link-->
    <div v-else-if="link.deleting == true" class="flex flex-col cursor-pointer mn3-alert p-1 rounded-sm justify-between">
        <span class="w-full text-center mb-1 font-bold">Are you sure you want to delete this link?</span>
        <div class="flex flex-row w-full justify-between">
            <Icon :id="link.attributes.icon" class="h-12 w-12 mr-1" />
            <div class="flex flex-col">
                <span class="font-bold">{{link.attributes.title}}</span>
                <span>{{link.attributes.url}}</span>
            </div>
            <div class="flex-grow" />
        </div>
        <div class="flex flex-row justify-between mt-1">
            <Button @click="this.$emit('deleteLink', link)" class="flex-grow" label="Yes, Delete Link" />
            <Button @click="link.deleting = false" type="cancel" label="Cancel" />
        </div>
    </div>     
    <!--Display Link-->
    <div v-else class="flex flex-row cursor-pointer mn3-content p-1 rounded-sm justify-between items-center">
        <div :class="`${isHover ? 'opacity-100' : 'opacity-10'} mr-0 duration-150 hidden`">
            <input type="text" class="h-10 w-10 rounded-sm border text-center" 
                :data-original="i"    
                :data-type="screen"
                :value="i"
                @blur="moveLink"
                @keyup.enter="moveLink" />
        </div>
        <div :class="`${isHover ? 'opacity-100' : 'opacity-10'} flex flex-col mr-2`">
            <div @click="this.$emit('moveUp', { i: i, id: link.id, screen: screen})" class="mn3-content-hover rounded-t p-1"><Icon id="chev-up" class="h-6 w-6" /></div>
            <div @click="this.$emit('moveDown', { i: i, id: link.id, screen: screen})" class="mn3-content-hover rounded-b p-1"><Icon id="chev-down" class="h-6 w-6" /></div>
        </div>
        <Icon :id="link.attributes.icon" class="h-14 w-14 mr-1" />
        <div class="flex flex-col overflow-hidden whitespace-nowrap" style="width: 250px">
            <span class="font-bold whitespace-nowrap">{{link.attributes.title}}</span>
            <span class="whitespace-nowrap">{{link.attributes.url}}</span>
        </div>
        <div class="flex-grow" />
        <Button :class="`${isHover ? 'opacity-100' : 'opacity-10'} mr-1`" @click="beginEdit()" icon="pencil-box" />
        <Button :class="`${isHover ? 'opacity-100' : 'opacity-10'}`" @click="link.deleting = true" icon="trash" />
    </div>  
</div>
    
</template>

<script>
/* Component API */
import { ref } from "vue";

    import Icon from "@/components/Icon.vue";
	import Field from "@/components/Field.vue";
	import Button from "@/components/Button.vue";
	import LinkTile from "@/components/LinkTile.vue";

export default {
    components: {
        Button,
        Field,
        Icon,
        LinkTile
    },
    props:{
        i: Number,
        link: Object,
        screen: String,
        groups: Object,
        editOnly: { //If edit-only mode, used for service link editing
            type: Boolean,
            default: false
        }
    },
    computed: {
        //If this link was a new link that was never saved
        newLinkCanceled(){
            return this.$props.link.new && !this.$props.link.editing && this.$props.link.canceled
        },
            screenOptions(){
                let options = {};

                options['accounts'] = { label: 'Accounts', value: 'accounts', icon: 'users' };
                options['transfer'] = { label: 'Transfer', value: 'transfer', icon: 'transfer' };

                
                if(this.$store.state.fi.statementsEnabled && this.$store.state.fi.noticesEnabled )
                    options['statements'] = { label: 'Statements & Notices', value: 'statements', icon: 'document' };                
                else if(this.$store.state.fi.statementsEnabled)
                    options['statements'] = { label: 'Statements', value: 'statements', icon: 'document' };                
                else if(this.$store.state.fi.noticesEnabled)
                    options['statements'] = { label: 'Notices', value: 'statements', icon: 'document' };                
               
                
                //RDC is auto set as third main nav option for mobile, last secondary nav option for desktop
                //if(this.$store.state.fi.rdcEnabled)
                //    options['capture'] = { label: 'Remote Deposit', value: 'capture', icon: 'camera' };   
                
                if(this.$store.state.fi.alliedEnabled)
                    options['allied'] = { label: 'Allied', value: 'allied', icon: 'ticket-2' };   
                
                if(this.$store.state.fi.allied2Enabled)
                    options['allied2'] = { label: 'Allied2', value: 'allied2', icon: 'ticket-2' };   
                
                if(this.$store.state.fi.ipayEnabled)
                    options['ipay'] = { label: 'Ipay', value: 'ipay', icon: 'ticket-2' };                       
                
                if(this.$store.state.fi.neuralpayEnabled)
                    options['neuralpay'] = { label: 'Neuralpay', value: 'neuralpay', icon: 'ticket-2' };   
                
                return options;
            },
    },
    methods: {
        beginEdit(){
            if(this.screen == 'services')
                this.$emit('editLink', this.$props.link)
            else
                this.$props.link.editing = true;
        },
        cancelChanges(link){
            link.editing = false;
            link.canceled = true;
        },     
        async moveLink(evt){
            this.$emit('moveLink', { original: evt.target.dataset.original, current: evt.target.value, screen: evt.target.dataset.type});
        },  
        saveChanges(link){
            //If setting to a screen link, set correct values
            if(this.screen  != 'services' && !this.customMode){
                link = this.toScreenLink(link, this.screenSelect)
            }

            //Move temp group value to link attribute
            link.attributes.group = this.groupSelect
            this.$emit('saveChanges', link)
        },
        toScreenLink(link, screen){

            switch(this.screenSelect){
                case 'accounts':
                    link.attributes.url = '/accounts'
                    link.attributes.icon = 'users'
                    link.attributes.title = 'Accounts'
                break;

                case 'transfer':
                    link.attributes.url = '/transfer'
                    link.attributes.icon = 'receipt'
                    link.attributes.title = 'Transfer'
                break;

                case 'statements':
                    link.attributes.url = '/statements'
                    link.attributes.icon = 'document'
                    link.attributes.title = 'Statements'
                break;
                
                case 'allied':
                    link.attributes.url = '/allied'
                    link.attributes.icon = 'ticket-2'
                    link.attributes.title = this.$store.state.fi.alliedTitle
                break;
                
                case 'allied2':
                    link.attributes.url = '/allied2'
                    link.attributes.icon = 'ticket-2'
                    link.attributes.title = this.$store.state.fi.allied2Title
                break;

                case 'ipay':
                    link.attributes.url = '/ipay'
                    link.attributes.icon = 'ticket-2'
                    link.attributes.title = this.$store.state.fi.ipayTitle
                break;
                
                case 'neuralpay':
                    link.attributes.url = '/neuralpay'
                    link.attributes.icon = 'ticket-2'
                    link.attributes.title = this.$store.state.fi.neuralpayTitle
                break;
            }
            return link;
        },
        tilePreviewSize(size){
            if(size == 1)
                return 'w-1/2 mx-auto';
            else if(size == 2)
                return 'w-full mx-auto';

            return 'w-full';
            
        }
    },
    mounted(){
        this.groupSelect = this.link.attributes.group
    },
    setup(props, { emit }){
        
        const isHover = ref(false)
        const groupSelect = ref('');
        const showIconSelect = ref(false);
        const iconFilter = ref('');
        const showStyleSelect = ref(false);
        const styleFilter = ref('');
        const customMode = ref(true) //If adding an additional link as custom or link to a screen
        const screenSelect = ref(''); //Screen selected to link to
        const styles = ref(['default', 'highlight', 'red', 'red-dark', 'green', 'green-dark', 'blue', 'blue-dark', 'gray', 'gray-dark', 'amusement', 'appliances', 'beach', 'fall', 'cafe', 'car', 'car-2', 'car-lot', 'credit-card', 'education', 
        'fall-2', 'graduation', 'house', 'house-2', 'house-3', 'house-city', 'house-for-sale', 'house-projects', 'house-projects-2', 'house-projects-3',
        'money', 'nature', 'new-years', 'party', 'riches', 'road', 'roadtrip', 'flowers', 'snow', 'spring', 'storm', 'summer-1', 'summer-2', 
        'support', 'suv', 'truck', 'vacation', 'winter' ]);
        const icons = ref([
            'academic-cap',
            'adjustments-horizontal',
            'adjustments-vertical',
            'archive',
            'archive-down',
            'archive-x',
            'arrow-down',
            'arrow-down-circle',
            'arrow-down-left',
            'arrow-down-right',
            'arrow-down-square',
            'arrow-down-stack',
            'arrow-down-tray',
            'arrow-left',
            'arrow-left-circle',
            'arrow-left-rectangle',
            'arrow-long-down',
            'arrow-long-left',
            'arrow-long-right',
            'arrow-long-up',
            'arrow-right',
            'arrow-right-circle',
            'arrow-up',
            'arrow-up-circle',
            'arrow-up-left',
            'arrow-up-right',
            'arrow-up-square',
            'arrow-up-stack',
            'arrow-up-tray',
            'arrow-uturn',
            'arrows-in',
            'arrows-left-right',
            'arrows-out',
            'arrows-up-down',
            'at-symbol',
            'attach',
            'backspace',
            'backward',
            'ban',
            'banknotes',
            'battery-0',
            'battery-100',
            'battery-50',
            'beaker',
            'bell',
            'bell-alert',
            'bell-slash',
            'bell-snooze',
            'bolt',
            'bolt-slash',
            'book-open',
            'bookmark',
            'bookmark-slash',
            'bookmark-square',
            'briefcase',
            'bug',
            'building-library',
            'building-office',
            'building-office-2',
            'building-storefront',
            'cake',
            'calculator',
            'calendar',
            'calendar-dates',
            'camera',
            'chart-bar',
            'chart-bar-square',
            'chart-pie',
            'chat',
            'check',
            'check-badge',
            'check-circle',
            'circle-stack',
            'clipboard',
            'clipboard-document',
            'clipboard-document-check',
            'clipboard-document-list',
            'clock',
            'cloud',
            'cloud-arrow-down',
            'cloud-arrow-up',
            'code-bracket',
            'code-bracket-square',
            'cog',
            'cog-2',
            'cog-8-tooth',
            'columns',
            'cpu-chip',
            'credit',
            'cube',
            'cube-transparent',
            'cursor-rays',
            'cursor-ripple',
            'device-mobile',
            'device-tablet',
            'document',
            'document-chart-bar',
            'document-check',
            'document-download',
            'document-duplicate',
            'document-minus',
            'document-plus',
            'document-text',
            'document-upload',
            'document-zoom',
            'dollar',
            'ellipsis-circle',
            'ellipsis-horizontal',
            'ellipsis-vertical',
            'envelope',
            'envelope-open',
            'euro',
            'exclamation-circle',
            'exclamation-triangle',
            'export',
            'eye',
            'eye-dropper',
            'eye-slash',
            'face-frown',
            'face-smile',
            'film',
            'filter',
            'fingerprint',
            'fire',
            'flag',
            'folder',
            'folder-down',
            'folder-minus',
            'folder-open',
            'folder-plus',
            'forward',
            'gif',
            'gift',
            'gift-top',
            'globe',
            'hand-raised',
            'hashtag',
            'heart',
            'home',
            'home-modern',
            'identification',
            'inbox',
            'inbox-down',
            'inbox-stack',
            'info',
            'input',
            'key',
            'language',
            'light-bulb',
            'lines',
            'link',
            'list-bullet',
            'lock',
            'lock-open',
            'loop-round',
            'loop-square',
            'map',
            'map-pin',
            'megaphone',
            'microphone',
            'minus-circle',
            'moon',
            'musical-note',
            'newspaper',
            'paint-brush',
            'paper-airplane',
            'pause',
            'pause-circle',
            'pc',
            'pencil',
            'pencil-box',
            'phone',
            'phone-in',
            'phone-out',
            'phone-x',
            'photo',
            'play',
            'play-pause',
            'plus',
            'power',
            'presentation-chart-bar',
            'presentation-chart-line',
            'printer',
            'puzzle-piece',
            'qr-code',
            'question-circle',
            'queue-list',
            'radio',
            'receipt-percent',
            'rectangle-stack',
            'rocket-launch',
            'rss',
            'scale',
            'scissors',
            'search',
            'send',
            'server',
            'server-stack',
            'share',
            'shield-check',
            'shield-exclamation',
            'shopping-bag',
            'shopping-cart',
            'signal',
            'signal-slash',
            'sort-asc',
            'sort-desc',
            'sparkles',
            'speaker-wave',
            'speaker-x',
            'square-2-stack',
            'square-stack',
            'squares-2x2',
            'squares-plus',
            'star',
            'stop',
            'stop-circle',
            'sun',
            'support',
            'tag',
            'template',
            'theme',
            'thumb-down',
            'thumb-up',
            'ticket',
            'ticket-2',
            'transfer',
            'trash',
            'trend-down',
            'trend-up',
            'trophy',
            'truck',
            'tv',
            'user',
            'user-circle',
            'user-group',
            'user-minus',
            'user-plus',
            'users',
            'variable',
            'video-camera',
            'video-camera-slash',
            'viewfinder-circle',
            'wallet',
            'web',
            'wifi',
            'window',
            'wrench',
            'wrench-screwdriver',
            'x',
            'x-mark',
            'zoom-circle',
            'zoom-in',
            'zoom-out'
            ]);
           
        return {
            customMode,
            groupSelect,
            isHover,
            iconFilter,
            styleFilter,
            icons,
            styles,
            screenSelect,
            showIconSelect,
            showStyleSelect
        }
    }
}

</script>

