<template>
    <div>
        <!-- table head -->
        <div class="flex justify-between items-center">
            <div class="font-bold hidden md:flex text-xl md:text-base ml-5 mr-4 md:ml-0">{{title}}</div>
            <div class="flex-grow" />
            <div class="flex items-center mb-1 flex-grow md:flex-grow-0">
                <!-- quick filter-->
                <div v-if="hasQuickFilter"
                    class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                    <input type="text" 
                        class="mn3-content rounded-sm p-1 border text-sm w-full" 
                        placeholder="Quick Filter" 
                        v-model="quickFilterVal"  
                        @keydown="quickFilterKeydown"
                        @keyup="emit('quickFilter', quickFilterVal)" />
                </div>
                <!-- full filter toggle-->
                <div v-if="hasFullFilter" 
                    :class="`md:w-8 md:h-8 h-12 w-12 cursor-pointer rounded border p-1 mr-1 ml-1
                    ${(showFilter ? 'mn3-content-disabled' : 'mn3-content-hover')}`" 
                    @click="showFilter = !showFilter">
                    <Icon id="settings" title="Filter Results"/>
                </div>
                <!--Download CSV button-->                
                <div v-if="showCsv && !$store.state.isMobileDevice" 
                    :class="`md:w-8 md:h-8 h-12 w-12 cursor-pointer rounded border p-1 mr-1 ml-1 mn3-submit`" 
                    @click="$emit('downloadCsv')">
                    <Icon id="document-arrow-down" title="Download CSV"/>
                </div>
            </div>
        </div>

        <!-- full filter -->
        <transition name="slide-quick" mode="in-out">  
            <div v-if="showFilter">
                <Form title="Filter By Value" 
                    icon="filter" 
                    :showReset="false" 
                    :showSubmit="false" 
                    fieldFlex="flex flex-col md:flex-row flex-wrap justify-between">   
                    <div class="w-full flex justify-start m-3 mn3-content border-bottom uppercase font-mono">
                        <!-- active filters display -->
                        <div v-for="(col, key) in filterColumns"
                            :key="key">
                            <div v-if="filters[key].value !== ''"
                                class="mn3-content mr-5 flex cursor-pointer hover:line-through items-center"
                                @click="clearFilter(key)"
                                @mouseover="filterFocused(key)"
                                @blur="filterBlurred(key)"
                                @mouseleave="filterBlurred(key)"
                                title="Click to clear this filter">
                                <Icon id="x" class="h-4 w-4 opacity-50 hover:opacity-90" /><p class="font-bold mr-1">{{col.label}}:</p> <p>{{filters[key].value}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- date filtering -->
                    <div class="px-4 flex w-full items-center" v-if="hasDateFilter">
                        <Field  
                            label="Start Date"                                 
                            type="date" 
                            textFormat="date"
                            placeholder="MM/DD/YYYY"
                            :maxLength="10"
                            @change="filterDateUpdated(false)"
                            :class="`w-1/2`"
                            :formValue="fv.dateStart" />
                            <p>-</p>
                        <Field  
                            label="End Date"                      
                            type="date"        
                            textFormat="date"
                            placeholder="MM/DD/YYYY"
                            :maxLength="10"
                            @change="filterDateUpdated(false)"
                            :class="`w-1/2`"
                            :formValue="fv.dateEnd" />
                        <button title="Clear Dates" :class="`${fv.dateEnd.value != '' || fv.dateStart.value != '' ? 'mn3-cancel cursor-pointer' : 'mn3-content opacity-70'} p-2 rounded-sm`" 
                            @click="fv.dateEnd.value = ''; fv.dateStart.value = '';filterDateUpdated(true);">
                            X
                        </button>
                    </div>
                    <!-- value filtering -->
                    <div v-for="(col, key) in filterColumns"
                        :key="key"
                        :class="`flex flex-grow ${col.classes} px-4`">
                        <Field 
                            v-if="filterColumns[key].textFormat == 'amount'"
                            :label="`${filterColumns[key].label}`"
                            :formValue="filterModes[key]"
                            @click="filterFocused(key)"
                            @focus="filterFocused(key)"
                            @mouseover="filterFocused(key)"
                            @blur="filterBlurred(key)"
                            @mouseleave="filterBlurred(key)"
                            @change="selectFilterInput(key)"
                            type="select"
                            class="text-xs text-left font-bold"
                            :paddingX="0"
                            :options="[
                                {
                                    label: 'includes',
                                    value: 'inc'
                                },
                                {
                                    label: 'greater than',
                                    value: 'gt'
                                },
                                {
                                    label: 'less than',
                                    value: 'lt'
                                }
                            ]"
                            />
                        <Field  
                            :label="`${(filterColumns[key].textFormat !== 'amount' ? filterColumns[key].label : ' ')}`" 
                            :formValue="filters[key]"
                            :id="`filter_${key}`"
                            @esc="filters[key].value = ''"
                            @keyup="filterUpdated(key)"
                            @click="filterFocused(key)"
                            @focus="filterFocused(key)"
                            @mouseover="filterFocused(key)"
                            @blur="filterBlurred(key)"
                            @mouseleave="filterBlurred(key)"
                            type="text" 
                            :paddingX="0"
                            :textFormat="filterColumns[key].textFormat"
                            :class="`flex-grow mr-0 pl-2`" />                            
                    </div>
                </Form>
            </div>
        </transition>
        
        <div v-if="recordError" 
            class="text-center text-sm py-1 mn3-alert border-t border-b">{{recordError}}</div>
        <div v-else>                
            <!--included slot for tabels that don't use TableRecords/pending display -->
            <slot />            
            <TableRecords
                v-if="useRecords"
                :records="records"
                :table="table"
                :quickFilter="quickFilterVal"
                :filterData="filterDataPass"
                :filterHighlight="highlightColumn"
                :recordsLoading="recordsLoading"
                :key="recordKey"
                :showRecordCount="showRecordCount"   
                :sortBy="sortBy"
                :sortOrder="sortOrder"
                :sortCompare="sortCompare"         
                @loadAccount="loadAccount" 
            />
        </div>
    </div>
</template>

<script>

import Field from '@/components/Field.vue'
import Form from '@/components/Form.vue'
import Icon from '@/components/Icon.vue'
import TableRecords from '@/components/TableRecords.vue'

import { computed, ref } from "vue"

export default{
    components: {
        Field,
        Form,
        Icon,
        TableRecords
    },
    props: {
        columns: Object,                //columns: Table columns            
        filterBy: {                     //filterBy: Filters available for the table
            type: Object,
            default: []
        },
        hasDateFilter: {                //hasDateFilter: Date filters enabled for table
            type: Boolean,
            default: false
        },
        hasFullFilter: {                //hasFullFilter: If in depth filters available on table
            type: Boolean,
            default: false
        },
        hasQuickFilter: {               //hasQuickFilter: If table has quickFilter enabled
            type: Boolean,
            default: true
        },
        recordKey: Number,              //recordKey: Used to re-render records when a change takes palce
        recordError: {
            type: String,
            default: ""
        },
        records: Object,                //records: Records to be passed to TableRecords component
        recordsPending: Object,
        recordsLoading: {
            type: Boolean,
            default: false
        },
        showCsv: {
            type: Boolean,
            default: false
        },
        showRecordCount: {              //showRecordCount: display # of records above record display
            type: Boolean,
            default: true
        },
        sortBy: String,
        sortOrder: Number,
        sortCompare: String,
        table: {                        //table: Table type, passed to TableRecords to render correct records
            type: String,
            default: ""
        },
        title: {                        //title: Title of the table
            type: String,
            default: ""
        },
        useRecords: {                   //useRecords: Use TableRecords, otherwise parent will display items in Table slot
            type: Boolean,
            default: true
        }
    },
    methods: {
        /* Set filter value to blank */
        clearFilter(key){
            this.filters[key].value = ""
            this.filterUpdated(key)
        },
        /* Remove higlight from column when filter field is blurred */
        filterBlurred(key){
            this.highlightColumn = '';
        },
        /* Emit filter data to parent when dates updated */
        filterDateUpdated(reset){

            if(reset){
                this.fv['dateStart'].value = '';
                this.fv['dateEnd'].value = '';

                this.lastEmit = {
                    start: this.stringToDate(this.fv['dateStart'].value),
                    end: this.stringToDate(this.fv['dateEnd'].value)
                }                
                this.emit("filterDateUpdated", this.lastEmit)
                return;
            }

            // Check if values changed since last emit and not both blank, and valid years
            let changed = (this.lastEmit['start'].split('T')[0] != this.fv['dateStart'].value || this.lastEmit['end'].split('T')[0] != this.fv['dateEnd'].value);
            let blank = (this.stringToDate(this.fv['dateStart'].value) == '' || this.stringToDate(this.fv['dateEnd'].value) == '');
            let valid = (this.fv['dateStart'].value.substring(0,1) != '0' && this.fv['dateEnd'].value.substring(0,1) != '0')

            if(changed && !blank && valid){ 
                this.lastEmit = {
                    start: this.stringToDate(this.fv['dateStart'].value),
                    end: this.stringToDate(this.fv['dateEnd'].value)
                }                
                this.emit("filterDateUpdated", this.lastEmit)
            }
        },
        /* Highlight column filter focused on */
        filterFocused(key){
            this.highlightColumn = key;
        },
        /* When a column-specific filter value is changed, send updated filter info up to be sent to results component */
        filterUpdated(key){
            this.quickFilterVal = '';
            this.highlightColumn = key

            let filterValues = {}
            
            for(var ii = 0; ii < this.$props.filterBy.length; ii++){
                let filter = this.$props.filterBy[ii]
                filterValues[filter.key] = { value: this.filters[filter.key].value, mode: this.filterModes[filter.key].value }
            }
            this.filterDataPass = filterValues
        },
        /* Emit event to load account from /accounts */
        loadAccount(ev, key){            
            this.emit('loadAccount', ev, key);
        },        
        /* Check for clearing quickfilter value on escape press */
        quickFilterKeydown(evt){
           if(evt.key == 'Escape')
                this.quickFilterVal = '';           
        },
        /* Focus on a filter input when the matching dropdown is changed */ 
        selectFilterInput(key){
            document.getElementById("filter_" + key).focus()
            this.filterUpdated(key)
        },
        /* Convert string to date and return ISO string */
         stringToDate(str){
            try{
                return (new Date(str).toISOString());
            }catch(ex){
                return '';
            }
        }
    },
    setup(props, { emit }){

        /* --Variables-- */
        const cols = ref(props.columns)         //cols: Table columns
        let id = ref("")                        //id: ID used on DOM elements
        let filterDataPass = ref({})            //filterDataPass: Used to pass filter data to TableRecords/to parent
        let filterModes = {}                    //filterMods: Array of mode applied to each filter
        let filters = {}                        //filters: Array of current filter values
        let fv = {}                             //fv: Array of ref elements holding input values used in table        
        fv['dateStart'] = ref('')               //fv.dateStart: Beginning cutoff date for results
        fv['dateEnd'] = ref('')                 //fv.dateend: Ending cutoff date for results
        let highlightColumn = ref("")           //highlightColumn: Currently highlighted table column
        const quickFilterVal = ref("")          //quickFilterVal: Current value of quickfilter
        const showFilter = ref(false)           //showFilter: Show full filter controls
        const lastEmit = ref({start:'',end:''})                //lastEmit: Values last sent, used to check for changes

        /* --Computed-- */        
        const filterColumns                     //filterColumns: Array of columns table can be filtered by
            = computed(() =>{
            let cols = {};
            for(var ii = 0; ii < props.filterBy.length; ii++){
                let filter = props.filterBy[ii];
                cols[filter.key] = filter;
            }
            return cols
        });
   
        //Fill filters/modes based on passed props
        if(props.hasFullFilter){
            for(var key in filterColumns.value){
                filters[key] = ref("")
                filterModes[key] = ref("inc")
            }
        }
        
        return {
            emit,
            filterColumns,
            filterModes,
            filterDataPass,
            filters,
            fv,
            highlightColumn,
            id,
            lastEmit,
            quickFilterVal,
            showFilter
        }

    }
}

</script>