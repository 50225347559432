<!-- Form: Used to create a structure of a form -->
<template>
  <form @submit.prevent="onSubmit" class="w-full mx-0 md:w-auto" id="{{ id }}">
    <div :class="`form p-0 mb-5`">
      <div :class="`flex flex-col items-center md:rounded-t justify-start mn3-content border mb-1`"
        v-if="title != undefined">
            <div class="flex flex-row items-center justify-start w-full">
            <Icon v-if="icon != ''" :id="icon" class="h-6 w-6 ml-2" />
            <span class="uppercase pl-3 p-2 font-bold">{{ title }}</span>
            <div class="flex-grow"></div>
            <button v-if="showClose" @click="onClose" title="Close Form" class="mn3-cancel p-2 rounded-sm w-10">X</button>
        </div>
        <div v-if="desc != undefined" class="w-full text-center px-3 py-1 leading-4 text-xs uppercase mn3-content-alt rounded-b-sm">{{ desc }}</div>
      </div>
      <div class="mn3-alert p-2 rounded-t" v-if="error != ''">
        Error: {{error}}
      </div>

      <!--Form Fields-->
        <div :class="`${fieldFlex} rounded-b border mn3-content`">
          <slot></slot>
          
      <!--Form Buttons-->
        <div :class="`form-buttons flex justify-between items-center px-2 py-2 w-full`"
          v-if="showSubmit || showCancel">
          <div v-if="bioMode == 'camera'"
            @click="onBio"
            class="mn3-submit p-2 rounded cursor-pointer shadow border mr-2">
            <Icon id="login-face" class="h-7 w-7" />
          </div>
          <div v-else-if="bioMode == 'fingerprint'"
            @click="onBio"
            class="mn3-submit p-2 rounded cursor-pointer shadow border mr-2">
            <Icon id="fingerprint" class="h-7 w-7" />
          </div>
          <Button 
            v-if="showSubmit"
            class="flex-grow"
            size="large"
            :type="onCooldown ? 'disabled' : 'submit'"
            @click="onSubmit"
            :label="submitText == undefined ? 'Submit' : submitText"
            :tabindex="submitIndex" />
          <Button 
            v-if="showCancel"
            size="large"
            type="cancel"
            :class="`${!showSubmit ? 'flex-grow' : 'ml-1'}`"
            @click="onCancel"
            :label="cancelText == undefined ? 'Cancel' : cancelText"
            :tabindex="submitIndex + 1" />
          <div :class="`flex-shrink`"></div>
          <slot name="secondary-buttons"></slot>
        </div>

        </div>

      
    </div>
  </form>
</template>

<script>
import Field from "@/components/Field.vue";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";

import { useStore } from "vuex";
import { ref } from "vue";

export default {
  components: {
    Button,
    Field,
    Icon
  },
  props: {

    /* Properties without defaults */
    buttons: Object,                    //buttons: Array of additional buttons to display alongside submit/cancel
    cancelText: String,                 //cancelText: Text displayed on the cancel button
    desc: String,                       //desc: Additional form description to be displayed
    id: String,                         //id: ID for the root form element
    ignoreCancelCooldown: Boolean,
    showBio: Boolean,                   //showBio: Hide or show the fingerprint buttom
    bioMode: String,                    //bioMode: fingerprint, camera, or disabled
    showCancel: Boolean,                //showCancel: Hide or show the cancel buttom
    submitText: String,                 //submitText: Text displayed on the submit button
    submitIndex: Number,                //submitIndex: The tab index applied to the submit button
    title: String,                      //title: Title of the form

    /* Properties with defaults */
    error: {                             //icon: ID of the icon in the form header
      type: String,
      default: ""
    },
    fieldFlex: {                        //fieldFlex: Tailwind flex classes applied to field root
      type: String,
      default: "flex flex-col"
    },
    icon: {                             //icon: ID of the icon in the form header
      type: String,
      default: ""
    },
    showClose: {                        //showClose: Show close X in title bar
      type: Boolean,
      default: false
    },
    showReset: {                        //showReset: Show or hide the button to reset all form field to their initial value
      type: Boolean,
      default: true
    },
    showSubmit: {                       //showSubmit: Show or hide the button to submit form
      type: Boolean,
      default: true
    }
  },
  methods: {
    // onCancel: Emit cancel event to parent
    async onCancel() {        
        if(!this.onCooldown || this.$props.ignoreCancelCooldown){
            this.emit("cancel");        
            this.onCooldown = true;
            clearTimeout(this.cooldown);
            this.cooldown = setTimeout(this.resetCooldown, this.cooldownTime * 1000);
        }
    },
    // onClose: Emit close event to parent
    onClose() {
      this.emit("close");
    },
    // onReset: Emit reset event to parent
    onReset() {
      this.emit("reset");
    },
    // onSubmit: Emit submit event to parent
    async onSubmit(evt) {
      
        if(evt != undefined)
            evt.preventDefault();    

        if(!this.onCooldown){
            this.emit("submit");        
            this.onCooldown = true;
            clearTimeout(this.cooldown);
            this.cooldown = setTimeout(this.resetCooldown, this.cooldownTime * 1000);
        }
    },    
    // onSubmit: Emit submit event to parent
    onBio() {
      this.emit("bio");
    },
    resetCooldown(){
        this.onCooldown = false;
    }
  },
  setup(props, { emit }) {
    const cooldownTime = ref(5); //in seconds
    const onCooldown = ref(false)
    const cooldown = ref()
    return { 
        cooldown,
        cooldownTime,   
        emit,
        onCooldown
    };
  }
};
</script>
