<!-- Menu: Used to create a structure of a menu  -->
<template>
    <div class="h-full">
        <div class="menu rounded border overflow-hidden">
            <div :class="`mx-auto`">
                <div 
                    v-if="title != undefined"
                    :class="`mn3-content-header 
                    ${(isLite ? 'form-header-simple' : 'form-header')}
                    ${(isExpanded ? '' : 'rounded-b')}
                    ${(title == undefined ? 'rounded-t' : 'border-b')}`">
                    <span class="font-bold p-2">{{title}}</span>
                    <div class="flex-grow"></div>
                    <div v-if="isExpanded && isLite" class="cursor-pointer"><svg class="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd"></path></svg></div>
                    <div v-if="!isExpanded  && isLite" class="cursor-pointer"><svg class="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg></div>
                </div>
                <transition name="fade">
                <div v-if="isExpanded">
                    <slot />                
                </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex"


export default {
    components: {
    },
    props:{

        /* Properties without defaults */
        title: String,                  //title: Title of the menu
        id: String,                     //id: ID of the menu
        isLite: Boolean,                //isLite: Dispolay 'lite' menu version
        options: Object,                //options: Menu options
        /* Properties with defaults */
        icon: {                         //icon: Icon displayed in menu header
            type: String,
            default: ''
        },
        isExpanded: {                   //expanded: If the menu is expanded or collapsed
            type: Boolean,
            default: true
        }
    },
    setup(){
        const store = useStore();
        return {
            store
        }
    }
}
</script>
