let acctFillerData = {    
        "data": {
            "data": {
                "type": "Account",
                "id": "fis/3/members/1/memberships/1/accounts/1",
                "attributes": {
                    "type": "Checking",
                    "accountId": "1",
                    "displayAccountId": "DRAFT-18",
                    "accountStatus": "Active",
                    "description": "SECURE CHECKING ACCOUNT",
                    "availableBalance": 1997.35,
                    "actualBalance": 1997.35,
                    "openDate": "2010-11-11",
                    "transferTo": true,
                    "transferFrom": true,
                    "external": false
                },
                "links": {
                    "self": "http://mn3f.test:6543/api/fis/3/members/1/memberships/1/accounts/1"
                }
            }
        },
        "status": 200,
        "statusText": "OK",
        "headers": {
            "content-length": "465",
            "content-type": "application/vnd.api+json"
        },
        "config": {
            "url": "accounts/1",
            "method": "get",
            "headers": {
                "Accept": "application/json, text/plain, */*"
            },
            "baseURL": "http://mn3f.test:6543/api/fis/3//members/1/memberships/1/",
            "transformRequest": [
                null
            ],
            "transformResponse": [
                null
            ],
            "timeout": 0,
            "withCredentials": true,
            "xsrfCookieName": "XSRF-TOKEN",
            "xsrfHeaderName": "X-XSRF-TOKEN",
            "maxContentLength": -1,
            "maxBodyLength": -1
        },
        "request": {}    
    }
    
export default acctFillerData