<template>
	<div class="w-full md:w-96">   
        <div v-if="$store.state.isUpdateAvailable || $store.state.isUpdateRequired"
            :class="`${ $store.state.isUpdateRequired ? 'mn3-alert' : 'mn3-content-highlight'} flex flex-col w-full p-2 rounded-sm items-center`">
            
            <div class="flex flex-row my-2">
                <Icon id="chev-double-up" class="h-6 w-6 mr-1" />
                <span class="font-bold text-xl uppercase">Update {{$store.state.isUpdateRequired ? 'Required' : 'Available'}}</span>
            </div>

            <div class="flex flex-col w-5/6">
            <span class="text-sm">A new version of this app is now available for download on the <span v-if="$store.state.isAndroid">Play</span><span v-else>App</span> Store!</span>
            <span class="text-sm my-2">Update now for improved app functionality.</span>
            </div>

            <button @click="openAppStore()" class="w-2/3 text-center p-2 rounded m-2 mn3-submit cursor-pointer border">Update App</button>
        </div>
		<transition name="down" mode="out-in">
            <div v-if="store.state.isUpdateRequired" class="bg-gray-50 border rounded p-3 my-5 flex flex-col">
                <span class="font-bold">An update is required to use this application.</span>
                <span>If you are experiencing issues after updating, please try uninstalling and reinstalling.</span>
            </div>
			<div v-else-if="store.state.isLoggedIn" class="bg-gray-50 border rounded p-3">
				<p>You are logged into account: {{store.state.userTitle}}</p>
				<div href="" @click.prevent="$authapi.logout" class="form-button rounded bg-red-800 text-white hover:bg-red-700 border cursor-pointer w-max px-2 py-1">Log Out</div>
			</div>
			<div v-else>
				<transition name="down" mode="out-in">
					<div class="flex flex-col">
                        <img
                            :src="$store.state.theme.mainLogoUrl"
                            class="w-auto rounded-sm mx-auto max-w-xs mb-5"
                            />
						<LoginComp 
                            ref="refLoginComp"
                            mode="members" />
					</div>
				</transition>
                <div v-if="($store.state.fi.enrollUrl == '' 
                    || $store.state.fi.enrollUrl == undefined) && loginCompStep == 'credentials'" class="w-full py-1">
                    <div @click="this.$router.push('/pub/register')"
                        class="mn3-submit mn3-button text-lg my-2 text-sm w-full text-center cursor-pointer">
                        Register Your Account
                    </div>
                </div>
                <div v-if="loginCompStep == 'credentials' && hasLoginInstructions" 
                    class="mx-0 text-xs mn3-content-highlight p-2 rounded-0 md:rounded-sm flex items-center border"><Icon class="h-6 w-6 opaticy-80 mr-2" id="info" /><p>{{$store.state.fi.loginInstructions}}</p></div>
			</div>
		</transition>
        <div v-if="store.state.isMobileDevice" class="text-xs text-center w-full box-border opacity-50 hover:opacity-80 duration-200 mt-8 p-1 border border-gray-400 rounded-sm">Installed Version: {{currentBuild}}</div>
	</div>
</template>
<script>
	import { ref } from 'vue'
	import { useStore } from 'vuex'
	
    import { BiometryType, NativeBiometric } from "@capgo/capacitor-native-biometric";

	import Form from '@/components/Form.vue'
	import Field from '@/components/Field.vue'
	import Icon from '@/components/Icon.vue'
	import LoginComp from '@/components/LoginComp.vue'
	
	export default {
	  name: 'Login',
	  components: {
	      Form,
	      Field,
	      Icon,
	      LoginComp
	    },
        computed:{
            hasLoginInstructions(){
                return (this.$store.state.fi.loginInstructions != undefined && this.$store.state.fi.loginInstructions != '')
            },  
            loginCompStep(){
                if(this.refLoginComp == null){
                    return 'credentials';
                }else{
                    return this.refLoginComp.step;
                }
            }
        },
        methods:{
            openAppStore(){
                console.log('openAppStore: ', this.$store.state.isAndroid, this.$store.state.isIos)
                if(this.$store.state.isAndroid){
                    console.log('Open Android App Link')
                }else if(this.$store.state.isIos){
                    console.log('Open Ios App Link')
                }
            }
        },
	    created(){
	        document.title="Login";     
	    },
	  setup(){
	               
        NativeBiometric.isAvailable().then(
            (result) =>{
                let isAvailable = result.isAvailable;
                let isFaceId = results.biometryType == BiometryType.FACE_ID;

                if(isAvailable){

                    //get users creds
                    NativeBiometric.getCredentials({
                        server: this.bioServer,
                    }).then((credentials) => {

                        //auth using bio before loggin in user
                        NativeBiometric.verifyIdentity({
                            reson: "Bio Login",
                            title: "Member Login"
                        }).then(
                            () => {
                                //auth good
                                console.log('Bio Auth Success')
                                console.log(credentials.username, credentials.password);
                            },
                            (error) =>{
                                //auth failed
                                console.log('Bio Auth Failed')
                            }
                        );

                    });
                }
            },
            (error) =>{
                console.log('Bio error: ', error);
            }
        )

        const refLoginComp = ref(null)

	    let store = useStore();                             //store: Store ref
        let currentBuild = window.mn3_build_id
				if(!window.mn3_prod) currentBuild += "S";
                
        const bioServer = ref('com.mnolb.id' + store.state.fi.id);
	

	    return{
            bioServer,
            currentBuild,
            refLoginComp,
	        store
	    }    
	  }
	}
</script>
<style>
	.down-enter-from{
	opacity: 0;
	transform: translateY(-50px);
	}
	.down-enter-active{
	transition: all 0.15s ease-in;
	z-index: -2;
	}
	.down-leave-to{
	opacity: 0;
	transform: translateY(-50px);
	}
	.down-leave-active{
	transition: all 0.15s ease-out;
	z-index: -2;
	}
</style>
