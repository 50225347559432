<template>
    <div>
        <!--
        <div v-if="store.state.loginId == '' && false">
            You must be logged into an account to view this screen.
        </div>-->
        <div class="max-w-screen-md">
            <Form title="Input Testing"
                fieldFlex="flex flex-row flex-wrap"
                :showReset="false"
                submitText="Update Account">
                <Field class="w-1/2" label="Email" :formValue="fv.email" type="text" format="email" />
                <Field class="w-1/2" label="Password" :formValue="fv.username" type="password" />
                <Field class="w-1/2" label="Color" :formValue="fv.color" type="color" />
                <Field class="w-1/2" label="Amount" :formValue="fv.amount" type="text" format="money" />
                <Field class="w-1/2" label="-Amount" :formValue="fv.amount2" type="text" format="money" :negative="true" />
                <Field class="w-1/2" label="Number (5)" :formValue="fv.number5" type="text" format="number" :digits="5" />
                <Field class="w-1/2" label="Rate" :formValue="fv.rate" type="text" format="rate" />
                <Field class="w-1/2" label="Date" :formValue="fv.date" type="text" format="date" />
                <Field class="w-1/2 md:w-1/4" label="Toggle A" :formValue="fv.toggle" type="toggle" />
                <Field class="w-1/2 md:w-1/4" label="Toggle B" :formValue="fv.toggle2" type="toggle" />
                <Field class="w-1/2 md:w-1/4" label="Toggle C" :formValue="fv.toggle3" type="toggle" />
                <Field class="w-1/2 md:w-1/4" label="Toggle D" :formValue="fv.toggle4" type="toggle" />
                <Field class="w-full" label="TextArea" :formValue="fv.area" type="textarea" />
                <Field class="w-1/2" label="Dropdown" :formValue="fv.drop" type="select" :options="[{value: 'a', label: 'A'},{value: 'b', label: 'B'},{value: 'c', label: 'C'}]" />
            </Form>
        </div>
    </div>
</template>

<script>

    import { ref } from 'vue'
    import { useStore } from 'vuex'

    import Form from '@/components/Form.vue'
    import Field from '@/components/Field.vue'

    export default {
      name: 'User Settings',
      components: {
          Form,
          Field
      },
      setup(){

        let store = useStore();

        /* Form field values */
        let fv = {}
        fv["email"] = ref("")
        fv["username"] = ref("")
        fv["color"] = ref("")
        fv["amount"] = ref("")
        fv["amount2"] = ref("")
        fv["number5"] = ref("")
        fv["rate"] = ref("")
        fv["date"] = ref("")
        fv["drop"] = ref("")
        fv["area"] = ref("")
        fv["toggle"] = ref("Y")
        fv["toggle2"] = ref("Y")
        fv["toggle3"] = ref("Y")
        fv["toggle4"] = ref("Y")

        let testData = {
            email: 'email@ami.com',
            username: 'ami',
            color: '#99ff33',
            amount: '10.25',
            amount2: '-9884.54',
            number5: '12345',
            rate: '0.125',
            date: '06/30/21',
            drop: 'c',
            area: 'Text',
            toggle: 'N',
            toggle2: 'Y',   
            toggle3: 'Y',    
            toggle4: 'N'   
        }

        function loadValues(data){
            for(var key in fv){
                fv[key].value = data[key]
            }            
        }

        loadValues(testData)

        return{
            fv,
            store
        }    
      }
    }
</script>