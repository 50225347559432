<template>
    <div class="w-full flex flex-row">
        <Form 
            title="Members" 
            :class="`mx-auto w-full flex-grow max-w-screen-lg`"
            :showSubmit="false"
            :showCancel="false">
            <!--Members Header-->
            <div class="w-full flex flex-row border-b justify-between p-1 sk-content-alt">
                <div :class="`${viewUnread ? 'mn3-submit' : 'opacity-70'} 
                    hidden p-1 cursor-pointer  hover:opacity-100 flex flex-row border rounded-sm mr-2 w-40 items-center justify-center whitespace-nowrap`"
                    @click="toggleViewUnread()"
                    title="View Members Awaiting a Reply">
                    <Icon  id="mail" class="h-6 w-6" />
                    <p class="ml-1 flex-grow text-center" v-if="viewUnread">Viewing Unread</p>
                    <p class="ml-1 flex-grow text-center" v-else>View Unread</p>
                </div>
                <div :class="`h-12 md:h-8 flex mr-2 md:ml-0 border rounded-sm pl-0 flex flex-row items-center`"> 
                    <div :class="`${accountNumLastSearch != '' ? 'mn3-submit' : ''} h-12 md:h-8 py-1 px-2`">
                        <Icon id="id" :class="`rounded-sm h-6 w-6 cursor-pointer`"
                            @click="accountNum.focus()" />
                    </div>
                    <input type="text" 
                        ref="accountNum"
                        class="ml-0 mn3-content rounded-sm p-2 border-l border-t border-b text-sm h-8 border-l" 
                        placeholder="Account # Search" 
                        v-model="accountNumVal"  
                        @keydown="accountNumKeydown"
                        @blur="blurAccountNum" />
                    <p :class="`${accountNumLastSearch != '' ? 'hover:opacity-100 opacity-80 cursor-pointer ' : 'opacity-20'} duration-200 p-2 text-xs`"
                        @click="accountNumVal = '';blurAccountNum();accountNum.focus()">X</p>
                </div>
                <div class="h-12 md:h-8 flex mr-2 md:ml-0 border rounded-sm pl-0 flex flex-row items-center">  
                    <div :class="`${accountNameLastSearch != '' ? 'mn3-submit' : ''} h-12 md:h-8 py-1 px-2`">
                        <Icon id="user-solid" :class="`rounded-sm h-6 w-6 cursor-pointer`"
                            @click="accountName.focus()" />
                    </div>                  
                    <input type="text" 
                        ref="accountName"
                        class="ml-0 mn3-content rounded-sm p-2 border-l border-t border-b text-sm h-8 border-l" 
                        placeholder="Username Search" 
                        v-model="accountNameVal"  
                        @keydown="accountNameKeydown"
                        @blur="blurAccountName" />
                    <p :class="`${accountNameVal != '' ? 'hover:opacity-100 opacity-80 cursor-pointer ' : 'opacity-20'} duration-200 p-2 text-xs`"
                        @click="accountNameVal = '';blurAccountName();accountName.focus()">X</p>
                </div>
                <div class="h-12 md:h-8 flex flex-grow ml-2 border rounded-sm pl-1 md:ml-0 flex flex-row items-center">                   
                    <Icon id="filter" class="h-6 w-6 cursor-pointer"
                        @click="quickFilter.focus()"  />
                    <input type="text" 
                        ref="quickFilter"
                        class="ml-1 mn3-content rounded-sm p-2 border text-sm w-full h-8 border-l" 
                        placeholder="Quick Filter by Email" 
                        v-model="quickFilterVal"  
                        @keydown="quickFilterKeydown" />
                </div>
                <div class="p-1 cursor-pointer opacity-70 hover:opacity-100"
                    title="Single Line Display"
                    @click="viewMode='small'">
                    <Icon  id="line" class="h-6 w-6" />
                </div>
                <div class="p-1 cursor-pointer opacity-70 hover:opacity-100"
                    title="Expanded Display"
                    @click="viewMode='large'">
                    <Icon  id="lines" class="h-6 w-6" />
                </div>
            </div>
            <!--Active search params-->
            <div v-if="accountNumLastSearch != '' || accountNameLastSearch != ''"
                class="flex flex-row items-center mn3-content-header justify-center p-1 text-sm font-bold uppercase border-b mb-1">
                <span>Search Applied -</span>
                <span v-if="accountNumLastSearch != ''"
                    @click="accountNumVal = '';blurAccountNum();"
                    class="mx-2 hover:line-through cursor-pointer">Account Number: {{accountNumLastSearch}} (x)</span>
                <span v-if="accountNameLastSearch != ''"
                    @click="accountNameVal = '';blurAccountName();"
                    class="mx-2 hover:line-through cursor-pointer">Username: {{accountNameLastSearch}} (x)</span>
            </div>
            <!--Viewing X of X header-->
            <div class="w-full mn3-content-header text-center p-1 text-sm uppercase flex flex-row justify-between items-center">
                <div @click="loadAddtMembers(false)"
                    :class="`${pageLinks != undefined &&pageLinks.first == undefined ? 'mn3-disabled opacity-50' : 'cursor-pointer'} mn3-button px-5`">First Page</div>
                <div>Viewing {{((page - 1) * membersPerPage) + 1}} - {{((page) * membersPerPage) > totalMembers ? totalMembers : ((page) * membersPerPage)}} of {{totalMembers}} Members</div>
                <div @click="loadAddtMembers(true)"
                    :class="`${pageLinks != undefined && pageLinks.next == undefined ? 'mn3-disabled opacity-50' : 'cursor-pointer'} mn3-button  px-5`">Next Page</div>
            </div>
            <!-- Condensed display header -->
            <div v-if="viewMode == 'small'"
                class="w-full mn3-content-header px-2 py-1 font-bold text-sm flex justify-between">
                <div class="w-2/5">Member</div>
                <div class="w-1/5 hidden">Phone</div>
                <div class="w-1/5">Last Login</div>
                <div class="w-1/5"></div>
            </div>
            <div v-for="memb in membersToShow" :key="memb.id"
                class="flex flex-col pt-4 border-b">
                <Notice v-if="loginNotice && loginNoticeId == memb.id" :notice="loginNotice" @close="loginNotice = ''" :showClose="true" title="Unable to Login" 
                    level="alert" class="mb-2" />
                <!-- Multi-Line Display-->
                    <div v-if="viewMode == 'large'" 
                        :class="`${memb.deleted ? 'hidden' : ''} ${focusedId != memb.id && focusedId != -1 ? 'opacity-60' : ' opacity-100'} duration-150 flex flex-row justify-between pb-4`">
                        <div class="flex flex-col px-2 w-3/4">

                            <div class="font-bold text-lg flex flex-row justify-start items-center">
                                <p class="">{{memb.attributes.username}} - {{memb.attributes.email}}</p>
                            </div>

                                <div class="font-mono ml-0 mt-1"><span class="font-bold">Last Login:</span> {{displayDate(memb.attributes.lastLogin)}}</div>
                                <div class="font-mono ml-0">
                                    <span class="uppercase" v-if="memb.info != undefined">{{memb.info.device}}</span> - <span class="capitalize" v-if="memb.info != undefined">{{memb.info.os}}/{{memb.info.browser}}</span> <span>({{memb.attributes.lastIp}})</span></div>
                            <div v-if="memb.alert != undefined"
                                class="mn3-alert text-sm p-1 rounded-sm flex-grow mr-2 mb-1 my-2 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="exclamation" class="h-6 w-6 mr-2" />
                                <p>{{memb.alert}}</p>
                            </div>
                            <div v-if="memb.notice != undefined"
                                class="mn3-content-alt text-sm p-1 rounded-sm flex-grow mr-2 my-2 mb-1 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="info" class="h-6 w-6 mr-2" />
                                <p>{{memb.notice}}</p>
                            </div>
                        </div>

                        <div class="flex flex-col justify-start mr-2 uppercase text-xs font-bold text-center">
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-login-as']" 
                                @click="exploreUser(memb.id)" 
                                class="cursor-pointer px-2 py-1 rounded mn3-submit mb-1 flex flex-row justify-between items-center">
                                <Icon id="user-solid" class="h-4 w-4 mr-2"/>
                                <p>Login as Member</p>
                            </div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-reset']" @click="focusedId = memb.id;focusedMode='resetPass'" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="key" class="h-4 w-4 mr-2"/>
                                <p>Reset Password</p>
                            </div>
                            <div @click="focusedId = memb.id;focusedMode='editInfo'" class="hidden cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="settings" class="h-4 w-4 mr-2"/>
                                <p>Edit Member Info</p>
                            </div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-mfa']" @click="getLoginCode(memb.id)" 
                                :class="`mn3-button cursor-pointer px-2 py-1 rounded mb-1 flex flex-row justify-between items-center`">
                                <Icon id="code-bracket" class="h-4 w-4 mr-2"/>
                                <div class="flex flex-row items-center">Login Code</div>
                            </div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-delete']" @click="focusedId = memb.id;focusedMode='delete';" 
                                :class="`mn3-alert cursor-pointer px-2 py-1 rounded mb-1 flex flex-row justify-between items-center`">
                                <Icon id="chat" class="h-4 w-4 mr-2"/>
                                <div class="flex flex-row items-center">Delete Account</div>
                            </div>
                            
                        </div>
                    </div>
                    <!-- Single Line Display -->
                    <div v-else
                        class="flex flex-row justify-between px-2 py-1 items-center">

                        <div class="">{{memb.attributes.username}} ({{memb.attributes.email}})</div>
                        <div class="flex-grow font-mono flex flex-row items-center justify-end mr-4 tracking-tighter">
                            <span v-if="memb.info != undefined" class="uppercase">{{memb.info.device}}</span>
                            <span v-if="memb.info != undefined" class="capitalize ml-2">{{memb.info.os}}/{{memb.info.browser}}</span>
                            <span class="mono">({{memb.attributes.lastIp}})</span>
                            <span class="px-1 mono">-</span>
                            <span class="mono text-right" style="width: 150px">{{displayDate(memb.attributes.lastLogin)}}</span>
                        </div>
                        <div class="text-right flex flex-row items-center justify-end text-xs">
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-login-as']" title="Login as Member"
                                class="cursor-pointer px-2 py-1 rounded-l mn3-submit mr-0 border-r"><Icon id="logout" class="h-5 w-5" /></div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-reset']" @click="focusedId = memb.id;focusedMode='resetPass'" 
                                title="Reset Member Password"
                                class="cursor-pointer px-2 py-1 mn3-button-min border-l Xborder-r mr-0"><Icon id="key" class="h-5 w-5" /></div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-mfa']"  @click="getLoginCode(memb.id)" 
                                title="Get Login Code"
                                class="cursor-pointer px-2 py-1 mn3-button-min border-l Xborder-r mr-0"><Icon id="code-bracket" class="h-5 w-5" /></div>
                            <div v-if="$store.state.permissions['admin'] || $store.state.permissions['member-delete']" @click="focusedId = memb.id;focusedMode='delete';" 
                                title="Delete Account"
                                :class="`mn3-cancel cursor-pointer rounded-r px-2 py-1 mr-0`"><Icon id="trash" class="h-5 w-5" /></div>
                            <div @click="focusedId = memb.id;focusedMode='editInfo'" 
                                title="Edit Member Info"
                                class="hidden cursor-pointer px-2 py-1 rounded-r mn3-button-min"><Icon id="settings" class="h-5 w-5" /></div>    
                        </div>

                    </div>
                    <transition name="fade" mode="out-in">
                        <div v-if="focusedId == memb.id"
                            class="p-2 rounded-sm">
                        <div class="w-full mn3-content rounded p-0 text-center">
                            <div v-if="focusedMode == ''" class="w-full flex flex-col">
                                <div class="flex justify-end">
                                    <p @click="focusedId = -1" class="mn3-cancel cursor-pointer rounded w-min px-2 py-1">X</p>
                                </div>
                            </div>
                            <!--Login Code Display-->
                            <div v-else-if="focusedMode == 'loginCode' && ($store.state.permissions['admin'] || $store.state.permissions['member-mfa'])" class="w-full flex flex-col mn3-highlight rounded-sm p-2">
                                <span class="font-bold text-lg">The login code for this member is</span>
                                <div class="rounded-sm text-2xl mono font-mono">{{loginCode}}</div>
                            </div>
                            <!--Delete Member-->
                            <div v-else-if="focusedMode == 'delete' && ($store.state.permissions['admin'] || $store.state.permissions['member-delete'])" class="w-full flex flex-col">
                                <Form v-if="deleteStatus == -1" :title="`Delete Account`"
                                    icon="trash"
                                    :showCancel="true"
                                    :submitText="`Delete Account '${memb.attributes.username}'`"
                                    @submit="deleteAccount(memb)"
                                    @cancel="focusedMode = '';focusedId=-1;">
                                    <div class="mn3-alert text-lg p-2 rounded-sm mx-2">
                                        <div class="flex flex-row items-center justify-center">
                                            <span>Are you sure you want to delete this account?</span>
                                        </div>
                                        <div class="my-2">{{memb.attributes.username}}, {{memb.attributes.email}}</div>
                                        <div class="font-bold text-2xl uppercase">This cannot be undone.</div>
                                    </div>
                                </Form>
                                <div v-else-if="deleteStatus == 204"
                                    class="mn3-content flex flex-row justify-between items-center">
                                    <div class="flex-grow flex flex-row items-center justify-center text-center">
                                        <Icon id="trash" class="h-8 w-8 mr-2" />
                                        <div>This account has been deleted.</div>
                                    </div>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2 mn3-cancel" @click="deleteStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                </div>
                                <div v-else>
                                    <p class="flex-grow text-center">
                                        There was an error deleting this account.
                                    </p>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2 mn3-cancel" @click="deleteStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                
                                </div>
                            </div>
                            <!--Reset Pass-->
                            <div v-else-if="focusedMode == 'resetPass' && ($store.state.permissions['admin'] || $store.state.permissions['member-reset'])" class="w-full flex flex-col">
                                <Form v-if="resetStatus == -1" :title="`Reset password for ${memb.attributes.username}`"
                                    :showCancel="true"
                                    :submitText="`Send Password Reset to ${memb.attributes.email}`"
                                    @submit="resetUserPassword(memb.id)"
                                    @cancel="focusedMode = '';focusedId=-1;">
                                    </Form>
                                <div v-else-if="resetStatus == 200"
                                    class="mn3-content flex flex-row justify-between items-center">
                                    <p class="flex-grow text-center">
                                        Instructions on how to reset their password have been sent to the user.
                                    </p>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2" @click="resetStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                </div>
                                <div v-else>
                                    <p class="flex-grow text-center">
                                        There was an error resetting the user's password.  Their password has not been reset.
                                    </p>
                                    <div class="font-bold p-1 cursor-pointer rounded-sm mr-2" @click="resetStatus =-1;focusedMode='';focusedId=-1;">X</div>
                                
                                </div>
                            </div>
                        
                            
                            <div v-else-if="focusedMode == 'editInfo'" class="w-full flex flex-col">
                        
                                <Form :title="`Edit Member Info for ${memb.name}`"
                                    :showCancel="true"
                                    submitText="Update"
                                    @cancel="focusedMode = '';focusedId=-1;">
                                        <div class="w-full p-5">
                                            <div class="relative z-0 w-full">
                                                <input
                                                type="text"
                                                id="email"
                                                :value="memb.email"
                                                placeholder=" "
                                                required
                                                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                                />
                                                <label for="email" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Member Email</label>
                                            </div>
                                        </div>
                                        <div class="w-full p-5">
                                            <div class="relative z-0 w-full">
                                                <input
                                                type="text"
                                                id="phone"
                                                :value="memb.phone"
                                                placeholder=" "
                                                required
                                                class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                                />
                                                <label for="phone" class="absolute duration-300 top-3 left-3 -z-1 origin-0 text-gray-500">Member phone</label>
                                            </div>
                                        </div>
                                    </Form>
                            </div>
                        </div>
                    </div>
                    </transition>


            </div>           
            <!--Viewing X of X header-->
            <div class="w-full mn3-content-header text-center p-1 text-sm uppercase flex flex-row justify-between items-center">
                <div @click="loadAddtMembers(false)"
                    :class="`${pageLinks != undefined && pageLinks.first == undefined ? 'mn3-disabled opacity-50' : 'cursor-pointer '} mn3-button px-5`">First Page</div>
                <div>Viewing {{((page - 1) * membersPerPage) + 1}} - {{((page) * membersPerPage) > totalMembers ? totalMembers : ((page) * membersPerPage)}} of {{totalMembers}} Members</div>
                <div @click="loadAddtMembers(true)"
                    :class="`${pageLinks != undefined && pageLinks.next == undefined ? 'mn3-disabled opacity-50' : 'cursor-pointer'} mn3-button  px-5`">Next Page</div>
            </div>
        </Form>

    </div>
</template>

<script>
    import { ref } from 'vue'
    import { UAParser } from 'ua-parser-js'

	import Chat from "@/components/Chat.vue";
	import Form from "@/components/Form.vue";
	import Icon from "@/components/Icon.vue";
	import Field from "@/components/Field.vue";
	import Notice from "@/components/Notice.vue";
    import { useRouter } from "vue-router"

	export default {
		components:{
            Chat,
			Form,
			Field,
            Icon,
            Notice
		},
		data(){
			return {
			};
		},
        computed: {
            /* View filtered results of this.membets */
            membersToShow(){
                if(this.quickFilterVal.length == 0)
                    return this.members;

                let needle = this.quickFilterVal;
                let found = [];
                for(var ii = 0; ii < this.members.length; ii++){
                    if(this.members[ii].attributes.email.indexOf(needle) != -1)
                        found.push(this.members[ii]);
                }

                return found;
            }
        },
        methods:{
            /* If value has changed, run search */         
            blurAccountName(){
                if(this.accountNameLastSearch != this.accountNameVal){                    
                    this.accountNameLastSearch = this.accountNameVal
                    this.fetchMembers()
                }
            },
            /* If value has changed, run search */         
            blurAccountNum(){
                if(this.accountNumLastSearch != this.accountNumVal){                    
                    this.accountNumLastSearch = this.accountNumVal
                    this.fetchMembers()
                }
            },
            async getLoginCode(membId){
                let split = membId.split('/');

                if(split.length >= 4){
                    let id = split[3];    
                    let fetched = await this.$fapi.get('members/' + id + '/mfa-code');
                    this.focusedId = membId;
                    this.focusedMode = 'loginCode';
                    this.loginCode = fetched.data.data.attributes.code;
                }else{
                    this.focusedId = membId;
                    this.focusedMode = 'loginCode';
                    this.loginCode = 'There was an error fetching the login code.';
                }


            },
            /* Fetch all members */
            async fetchMembers(){
                //Check for searching by accountnum
                let params = '';
                if(this.accountNumVal != '' || this.accountNameVal != ''){
                    params = '?'

                    if(this.accountNumVal != '')
                        params += 'filter[account]=' + this.accountNumVal

                    if(this.accountNameVal != ''){
                        if(params.length > 1)
                            params += '&'

                        params += 'filter[username]=' + this.accountNameVal
                    }
                }
                let fetched = await this.$fapi.get("members" + params);
                this.members = fetched.data.data
                this.pageLinks = fetched.data.links
                this.totalMembers = fetched.data.meta.total;
                if(fetched.data.meta.filteredTotal != undefined)
                    this.totalMembers = fetched.data.meta.filteredTotal;

                console.log('fetched: ', fetched.data.data)

                var parser = new UAParser();
                
                //Format useragent data for each record
                for(var ii = 0; ii < this.members.length; ii++){                    
                    this.members[ii].info = {};
                    parser.setUA(this.members[ii].attributes.lastUserAgent)
                    let ua = parser.getResult()
                    
                    this.members[ii].info['os'] = ua.os.name
                    this.members[ii].info['browser'] = ua.browser.name
                    this.members[ii].info['device'] = ((ua.os.name == 'Windows' || ua.os.name == 'Mac OS' || ua.os.name == 'Linux') ? 'pc' : 'mobile')
                }
            },
            displayDate(date){                       
                let utc = new Date(date);
                
                const offset = utc.getTimezoneOffset();
                utc.setHours(utc.getHours() - (offset / 60))

                return utc.toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short"
                });  
            },
            async loadAddtMembers(loadNext){
                
                if(this.pageLinks == undefined) return;

                let linkToUse = this.pageLinks.next;
                this.page++;

                if(!loadNext){
                    linkToUse = this.pageLinks.first;
                    this.page = 1;
                }

                if(linkToUse != undefined){
                    const response = await this.$fapi.get(linkToUse);
                    console.log('response: ', response);

                    this.pageLinks = response.data.links;
                    this.members = response.data.data;
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }
            },
            accountNameKeydown(evt){
                if(evt.code == 'Enter' || evt.code == 'NumpadEnter'){
                    this.blurAccountName()
                }
            },
            accountNumKeydown(evt){
                if(evt.code == 'Enter' || evt.code == 'NumpadEnter'){
                    this.blurAccountNum()
                }
            },
            /* Send api request to initiate member password reset */
            async deleteAccount(memb){
                console.log('deleteAccount');
                //Get member id # from full
                let membId = memb.id.split('/')[3]
                try{
                    let resp = await this.$fapi.delete('members/' +membId);
                    this.deleteStatus = resp.status
                    memb.deleted = true
                    console.log('deleteResp: ', resp)
                }catch(resp){
                    this.deleteStatus = resp.status
                    console.log('deleteResp.err: ', resp)
                }
            },
            /* Send api request to initiate member password reset */
            async resetUserPassword(membIdFull){
                //Get member id # from full
                let membId = membIdFull.split('/')[3]
                try{
                    let resp = await this.$fapi.post('members/' +membId + '/reset', {});
                    this.resetStatus = resp.status
                    console.log('resetPassResp: ', resp)
                }catch(resp){
                    this.resetStatus = resp.status
                    console.log('resetPassRespERR: ', resp)
                }
            },
            /* Send api request to initiate explore as membert */
            async exploreUser(membIdFull){
                //Get member id # from full
                let membId = membIdFull.split('/')[3]
                try{
                    let resp = await this.$fapi.post('members/' +membId + '/explore', {});
                    let url = resp.data.data.attributes.url;
                    window.open(url)
                    console.log('exploreResp: ', resp)
                }catch(resp){
                    console.log('exploreRespCatch: ', resp)
                    console.log('respResponse: ', resp.response);

                    let status = resp.response.status
                    let detail = resp.response.data.errors[0].detail;

                    if((status == '403' || status == 403) && (!detail || detail.length == 0))
                        detail = 'Member login forbidden';
                    
                    if(!detail || detail.length == 0)
                        detail = 'Unable to login as member';

                    this.loginNoticeId = membIdFull;
                    this.loginNotice = detail;
                }
            }
        },
		created(){
            document.title = 'Members'

		}, 
        mounted(){
            this.fetchMembers()
        },
        setup(){
            const accountName = ref(null)
            const accountNameLastSearch = ref('')
            const accountNameVal = ref("")
            const loginCode = ref('');
            
            const accountNum = ref(null)
            const accountNumLastSearch = ref('')
            const accountNumVal = ref("")
            
            const quickFilter = ref(null)

            const viewMode = ref("large")
            const focusedMode = ref("");
            const focusedId = ref(-1)
            const quickFilterVal = ref("")
            const viewUnread = ref(false)
            const loginNotice = ref('')
            const loginNoticeId = ref('')
            const members2 = ref([])
            const members = ref([])
            const pageLinks = ref([]) //Links for member pagination
            const resetStatus = ref(-1)
            const deleteStatus = ref(-1)
            const totalMembers = ref(0)
            const page = ref(1);
            const membersPerPage = ref(10) //Used in calc of 'viewing xx-xx', doesn't determine number loaded

            const activeConvo = ref(1);
            const convos = ref([
                {
                    id: 1,
                    title: 'Login issue 3',
                    lastActivity: '08/07/2022',
                    awaitingReply: true
                },
                {
                    id: 2,
                    title: 'Login issue 2',
                    lastActivity: '08/04/2022',
                    awaitingReply: false
                },
                {
                    id: 3,
                    title: 'Login issue',
                    lastActivity: '08/01/2022',
                    awaitingReply: true
                }
            ]);

            function toggleViewUnread(){
                viewUnread.value =  !viewUnread.value;
            }

            return { 
                quickFilterVal,
                focusedId,
                focusedMode,
                convos,
                deleteStatus,
                activeConvo,
                accountName,
                accountNameLastSearch,
                accountNameVal,
                accountNum,
                accountNumLastSearch,
                accountNumVal,
                loginCode,
                loginNotice,
                loginNoticeId,
                members,
                members2,
                membersPerPage,
                page,
                pageLinks,
                quickFilter,
                resetStatus,
                totalMembers,
                viewMode,
                viewUnread,
                toggleViewUnread

             }

        }
	}
	
</script>
