<!-- Footer: Displays at the bottom of the screen in desktop view -->
<template>
    <div :class="`${!this.$store.state.isLoggedIn ? 'w-full justify-center' : 'w-min justify-start'} whitespace-nowrap flex flex-row mt-10 text-xs fixed bottom-0`">
        <div v-if="this.$store.state.isLoggedIn" :class="`flex flex-row mn3-navbar ${hidden ? 'rounded-tr' : ''} uppercase pr-2 pl-2 z-10 ${footerPadding}`">
            <button @click="hidden = !hidden" :class="`mn3-navbar-option_forceHover py-1 px-3 rounded-sm`">
                <Icon :id="hidden ? 'chev-right' : 'chev-left'" class="h-4 w-4" />
            </button>
            <button v-if="this.$store.state.fi.alert && this.$store.state.noticeClosed"
                title="View sitewide notice"
                @click="this.$store.state.noticeClosed = false"
                :class="`${noticeClass} py-0 px-3 rounded-sm ml-2`">            
                <Icon id="info" class="h-5 w-5" />
            </button>
        </div>
        <transition name="slide-right">
            <div v-show="!hidden || !this.$store.state.isLoggedIn" :class="`${!this.$store.state.isLoggedIn ? 'w-full' : 'rounded-tr '} flex flex-row mn3-navbar justify-center uppercase pr-2 pl-2 ${footerPadding}`">
                <div class="flex flex-row items-center justify-start">                    
                    <button v-if="!this.$store.state.isLoggedIn && hasSitewideAlert && this.$store.state.noticeClosed"
                        title="View sitewide notice"
                        @click="this.$store.state.noticeClosed = false"
                        :class="`${noticeClass} py-0 px-3 rounded-sm mx-2`">            
                        <Icon id="info" class="h-5 w-5" />
                    </button>
                    <button @click="openContact()" class="uppercase p-1 rounded-sm mn3-navbar-option mr-5 hover:underline cursor-pointer flex flex-row items-center opacity-80 hover:opacity-100">
                        <Icon id="phone" class="h-4 w-4" />Contact</button>
                    <button @click="openBranch()" class="uppercase p-1 rounded-sm mn3-navbar-option mr-5 hover:underline cursor-pointer flex flex-row items-center opacity-80 hover:opacity-100">
                        <Icon id="branch" class="h-4 w-4" />Find Branch</button>
                    <button v-if="!this.$store.state.isLoggedIn" @click="openAccount()" class="uppercase p-1 rounded-sm mn3-navbar-option mr-5 hover:underline cursor-pointer flex flex-row items-center opacity-80 hover:opacity-100">
                        <Icon id="user-solid" class="h-4 w-4" />Register Account</button>
                </div>
            </div>
        </transition>
    </div><slot />
</template>

<script>
import { ref } from 'vue'

import Icon from "@/components/Icon.vue";

export default {
    name: "Footer",   
    components: {
        Icon
    },
    computed: {
        footerPadding(){
            if(this.$store.state.isIos){
                return ' pt-2 pb-6 ';
            }
            return ' p-1 ';
        },    
        hasSitewideAlert(){
            if(!this.$store.state.fi.alert || !this.$store.state.fi.alert.alert || this.$store.state.fi.alert.alert.length == 0)
                return false;

            return true;
        },    
        noticeClass(){
            if(!this.$store.state.fi.alert) return '';
            switch(this.$store.state.fi.alert.level){
                case 'news':
                default:
                    return 'mn3-approved';

                case 'alert':
                    return 'mn3-alert';

                case 'notice':
                    return 'mn3-highlight';
            }
        }
    },
    methods:{
        openContact(){
            window.open(this.$store.state.fi.contactUrl);
        },
        openBranch(){
            window.open(this.$store.state.fi.locationsUrl);
        },
        openAccount(){
            if(this.$store.state.fi.enrollUrl != undefined)
                window.open(this.$store.state.fi.enrollUrl);
            else
                this.$router.push('/pub/register')
        }     
    },
    setup(){      
        
        const hidden = ref(false);
        return{
            hidden
        }
    }
}

</script>
