<!-- Slideshow -->
<template>
    <div class="flex flex-col">           
        <!--Slideshow Icons-->
        <div v-if="showSlideSelect" class="flex flex-row items-center ml-5">
            <div v-for="i in slides.length" :key="i" 
                :title="slides[i-1].attributes.titleUse"
                @click="selectSlide(i-1)"
                :class="`${(i-1 == active) ? 'opacity-100' : 'opacity-50'} cursor-pointer mn3-content-alt rounded-full w-5 h-5 mr-2`">
                <Icon v-if="i-1 == active && timer == undefined" id="play" class="p-1 h-5 w-5" />    
            </div>
        </div> 
        <!--Slides-->
        <div :class="`h-40 overflow-hidden flex flex-row flex-wrap w-full`">    
            <div v-for="(l, i) in slides" :key="l.id"
                :class="`w-full`">
                <transition name="slideshow" mode="out-in">
                    <LinkTile v-if="i == active"
                        @click="slideClicked(l.attributes.url)"
                        :theme="l.attributes.style"
                        :title="l.attributes.title"
                        :description="l.attributes.description"
                        :icon="l.attributes.icon"
                        :size="3"
                        :height="'40'"
                        />
                </transition>            
            </div>
        </div>
        <!--Slide Timer Progress-->
        <div v-if="showProgress" class="w-full px-1">
            <div :class="`${timer == undefined ? 'opacity-0' : ''} duration-150 w-full rounded-b flex justify-start overflow-hidden h-2`">
                <div class="h-2 mn3-content-alt flex-shrink-0" 
                    :style="`transition-duration: 1100ms; transition-timing-function: linear; width: ${slideProgress}%`" />
                <div class="h-2 flex-grow mn3-content-alt opacity-50" />
            </div>                    
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

import Icon from "@/components/Icon.vue";
import LinkTile from "@/components/LinkTile.vue";

export default {
    name: "Slideshow",   
    components: {
        Icon,
        LinkTile
    },
    props:{
        showSlideSelect: {
            type: Boolean,
            default: true
        },
        showProgress: {
            type: Boolean,
            default: true
        },
        slides: {
            type: Object,
            default: []
        }
    },
    computed: {
    },
    mounted(){
        this.startSlideshow();
        this.startTime = Date.now();
    },  
    unmounted(){
        this.stopSlideshow();
    },
    methods:{   
        selectSlide(i){

            //If already active slide and paused, go to next slide + start slideshow again
            if(this.active == i && this.timer == undefined){
                
                this.active++;
                let count = this.slides.length;
                if(this.active >= count){
                    this.active = 0;
                }
                this.startSlideshow();
            }
            //Otherwise set to slide and stop slideshow
            else{
                this.active = i;

                //Reset time for next slide + make 3x as long
                this.stopSlideshow();
            }                        

        },
        slideClicked(url){
            if(url.indexOf('http://') != -1 || url.indexOf('https://') != -1){
                window.open(url);
            }
            else
                this.$router.push(url);
        },
        // Returns the width: xx%; rule for a slideshow progress bar
        slideshowProgress(){
            //Perc figure: (Now - group.start) / (group.delay * 1000)
            let perc = (Date.now() - this.startTime) / (this.delay * 10) * 1.3;
            this.slideProgress = perc;            

            return;
        },
        startSlideshow(){

            let root = this;
            //Start timer for going to next slide          
            root.timer = window.setInterval(function(){                
                root.startTime = Date.now();
                root.active++;
                let count = root.slides.length
                if(root.active >= count){
                    root.active = 0;
                }
            }, root.delay * 1000);

            //Start timer for updating progress bar
            if(this.$props.showProgress){
                window.setInterval(function(){      
                    root.slideshowProgress()
                }, 1000);
            }

        },
        stopSlideshow(){            
            clearInterval(this.timer);
            this.timer = undefined;
        }
    },
    setup(){      

        const active = ref(0);
        const delay = ref(5);
        const slideProgress = ref(0)
        const startTime = ref(0);


        return{
            active,
            delay,
            slideProgress,
            startTime
        }
    }
}

</script>
