<template>
    <div class="p-2">
        <input type="file" ref="fileInput" class="py-5" multiple @change="fileSelected"  />
        <div v-if="files.length" class="flex flex-col">
            <div class="font-bold">Selected Files:</div>
            <div v-for="(file, i) in files" :key="i" 
                :class="`${checkNameIssue(file.name) || checkSizeIssue(file.size) ? 'mn3-alert' : ''} flex flex-row gap-x-2 items-center px-2 py-2 rounded-sm`">
                <Button icon="trash" @click="removeFile(i)" />
                <div class="flex flex-col leading-4 flex-grow">
                    <div class="font-bold">{{file.name}}</div>
                    <div class="text-xs opacity-60">{{fileSize(file)}}</div>
                </div>
                <div class="flex flex-col">
                    <div v-if="checkNameIssue(file.name)"
                        class="text-sm ml-2 flex flex-row items-center"><Icon id="exclamation" class="h-4 w-4 mr-1" />{{checkNameIssue(file.name)}}</div>                    
                    <div v-if="checkSizeIssue(file.size)"
                        class="text-sm ml-2 flex flex-row items-center"><Icon id="exclamation" class="h-4 w-4 mr-1" />{{checkSizeIssue(file.size)}}</div>
                </div>
            </div>
        </div>
        <div class="text-xs italic my-2">Max Filesize: {{maxMb}}MB</div>
    </div>
</template>

<script>
import { ref } from 'vue'

import Button from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";

export default {
    name: "FileUpload",   
    components: {
        Button,
        Icon
    },
    props: {
        existingFiles: Object,
        maxMb: { type: Number, default: 15 }
    },  
    computed: {
        files(){
            return this.$refs.fileInput;
        }
    },
    methods:{  
        checkNameIssue(name){

            // Check if filename already exists
            for(var ii = 0; ii < this.existingFiles.length; ii++){
                if(this.existingFiles[ii].attributes.name == name){
                    return 'Folder already contains a file with this name'
                }
            }

            return false
        },
        checkSizeIssue(size){
            if(size > this.$props.maxMb * 1024 * 1024){
                return 'File size above limit'
            }
            return false
        },
        clear(){
            this.files = [];
            this.$refs.fileInput.value = '';
        },
        fileSelected(evt){
            let selectedFiles = Array.from(evt.target.files);
            this.files = this.files.concat(selectedFiles);        
        },
        fileSize(file){
            let size = file.size;
            size /= 1024;
            if(size < 1024) return size.toFixed(2) + ' KB';

            size /= 1024;
            if(size < 1024) return size.toFixed(2) + ' MB';
            
            size /= 1024; 
            return size.toFixed(2) + ' GB';
        },
        // Return files or return { error: true, detail: '' }
        getFiles(){
            let issues = [];
            //Check all files for filename and filesize issues
            for(var ii = 0; ii < this.files.length; ii++){
                let nameIssue = this.checkNameIssue(this.files[ii].name)
                let sizeIssue = this.checkSizeIssue(this.files[ii].size)
                if(nameIssue || sizeIssue){
                    issues.push(this.files[ii].name)                   
                }
            }

            if(issues.length > 0)
                return { error: true, detail: 'There was an issue with the selected files, check below for details.' } 

            return this.files;
        },
        removeFile(index){
            this.files.splice(index, 1);
        }
    },
    setup(){      
        
        const files = ref([])

        return{
            files
        }
    }
}
</script>
