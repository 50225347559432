<!-- Chat -->
<template>
            <div class="flex p-1 mt-1 border mn3-content justify-between items-center">
                <p class=" font-bold">Secure Chat with {{withName}}</p>
                <div class="mn3-cancel rounded-sm py-1 px-2 cursor-pointer" 
                    title="Close Chat"
                    @click="emit('close');">X</div>
            </div>
            <div class="pb-28 md:pb-0 overflow-x-auto md:overflow-y-scroll"
                 :style="chatHeight" ref="msgBox">
                <div :class="`${msgOuterClass(msg)} w-full flex`" v-for="msg in messages" :key="msg.key">
                    <div :class="`${msgInnerClass(msg)} max-w-2xl py-2 px-4 m-1 rounded`">
                        <div class="flex text-xs">
                            <p :class="`${msgFromClass(msg)} font-bold`" v-if="!msg.fromSelf" class="mr-2">{{chatWith}}</p>
                            <p :class="msgTimeClass(msg)">{{msg.time}}</p>
                        </div>
                        <p>{{msg.text}}</p>
                    </div>
                </div>
            </div>
            <div class="w-full flex-col items-end text-right border-t pt-2 fixed md:relative bottom-16 md:bottom-0 bg-gray-50">
                <input type="text" class="w-full p-3" placeholder="Type a new message" />
                <div class="flex justify-between p-2">
                    <Icon class="w-6 h-6 mr-2 cursor-pointer" id="attach" />
                    <Icon class="w-6 h-6 cursor-pointer" id="input" />
                    <div class="flex-grow" />
                    <div class="w-8 h-8">
                        <Icon @click="scrollToBottom" title="Send Message" id="send" class="cursor-pointer" />
                    </div>
                </div>
                
            </div>

</template>

<script>

    import { ref, computed } from 'vue'

    import Icon from '@/components/Icon.vue'
export default{
    props:{  
        messages: {
            type: Object,
            default: []
        },
        withName: String
    },
    components:{
        Icon
    },
    created(){
        document.title="Members";
        this.chatHeightUpdate++;
        window.addEventListener("resize", this.windowResize);

        // hide or show conversation select based on mobile or desktop view
        if(window.innerWidth >= 768){
            this.showConvos = true
        }else{
            this.showConvos = false
        }
    },
    methods:{

            windowResize(e){
                this.chatHeightUpdate++;
            },
msgInnerClass(msg){
                let classes = ' ';
   
                if(!msg.fromSelf)
                    classes += 'bg-white ';
                else
                    classes += 'bg-blue-100 ';

                return classes;
            },
msgFromClass(msg){
                let classes = ' ';

                if(this.prevMsg !== undefined)
                if(msg.fromSelf ||
                    (msg.fromSelf === this.prevMsg.fromSelf && msg.time === this.prevMsg.time))
                    classes += 'hidden ' 

                return classes;
            },

            // Called last, so updates this.prevMsg to msg
            msgTimeClass(msg){
                let classes = ' ';

                if(this.prevMsg !== undefined)
                    if(msg.time === this.prevMsg.time && msg.fromSelf === this.prevMsg.fromSelf)
                        classes += 'hidden '

                this.prevMsg = msg;
                return classes;
            },
            msgOuterClass(msg){ 
                let classes = ' ';

                if(!msg.fromSelf)
                    classes += 'justify-start ';
                else
                    classes += 'justify-end ';

                if(this.prevMsg !== undefined)
                if(msg.fromSelf != this.prevMsg.fromSelf){
                    classes += 'mt-5 '
                }

                return classes;
            }
    },
    setup( props, { emit } ){


            const chatHeightUpdate = ref(0)
            const chatHeight = computed({          
                get: () => {
                    chatHeightUpdate.value++;   
                    if(window.innerWidth >= 768)                 
                        return `max-height: ${window.innerHeight-270}px;`;          
                    else 
                        return '';          
                }
            });
            
        let prevMsg = undefined;

        return {
            emit,
            prevMsg,
            chatHeight,
            chatHeightUpdate
        }
    }
}

</script>