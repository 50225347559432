<!-- Chat -->
<template>
    <div class="w-full flex flex-col md:flex-row border rounded mn3-content">
        <!--Conversations List Full View-->
        <Conversations v-if="showConvos"
            class="hidden md:block"
            :conversations="conversations"
            :mode="mode"
            :moreToLoad="moreToLoad"
            :withName="withName"
            :showNewConversation="true"
            :activeConversationId="activeConversationId"
            @refreshConversations="this.$emit('refreshConversations')"
            @viewOpen="closeConversation();this.$emit('viewOpen');closeConversation();"
            @viewClosed="closeConversation();this.$emit('viewClosed')"
            @loadMoreConversations="this.$emit('loadMoreConversations')"
            @startNewConversation="startingNewChat = true;activeConversationId = 0;"
            @selectConversation="(e) => {selectConversation(e)}"  />
        <!--Conversations list mobile view, hidden if an active convo-->
        <Conversations v-if="activeConversationId == 0 && !startingNewChat"
            class="block md:hidden"
            :conversations="conversations"
            :mode="mode"
            :moreToLoad="moreToLoad"
            :withName="withName"
            :showNewConversation="mode == 'mapi'"
            :activeConversationId="activeConversationId"
            @refreshConversations="this.$emit('refreshConversations')"
            @viewOpen="closeConversation();this.$emit('viewOpen');closeConversation();"
            @viewClosed="closeConversation();this.$emit('viewClosed')"
            @loadMoreConversations="this.$emit('loadMoreConversations')"
            @startNewConversation="startingNewChat = true;activeConversationId = 0;"
            @selectConversation="(e) => {selectConversation(e)}" />
        
        <!--Active Area-->
        <div class="w-full flex flex-col mn3-content rounded-r">
        <transition name="fade" mode="out-in">
            <Conversation v-if="activeConversationId != 0 || startingNewChat"
                ref="ref_conversation"
                class="self-stretch"
                :conversation="activeConversation"
                :withName="withName"
                :thisName="thisName"
                :mode="mode"
                :startingNewChat="startingNewChat"
                @refreshConversations="() => { this.$emit('refreshConversations'); }"
                @postMessage="(e) => { postMessage(e) }"                
                @postNewConversation="(e) => { this.$emit('postNewConversation', e); }"
                @close="closeConversation()" />
            <div v-else
                class="p-2 italic w-full text-center text-lg py-10">
                {{ `Select ${showButtons ? 'or start a new' : 'a'} conversation using the conversations menu.` }}
            </div>
        </transition>
        </div>

    </div>
</template>

<script>

    import { ref, computed } from 'vue'

    import Conversations from '@/components/Conversations.vue'
    import Icon from '@/components/Icon.vue'
    import InputText from '@/components/InputText.vue'
    import Conversation from './Conversation.vue'
export default{
    props:{  
        conversations: {
            type: Object,
            default: []
        },
        showButtons: {
            type: Boolean,
            default: true
        },
        mode: {
            type: String,
            default: 'mapi'
        },
        moreToLoad: {
            type: Boolean,
            default: false
        },
        withName: String
    },
    components:{
        
    Conversation,
    Conversations,
    Icon,
    InputText
},
    created(){
        // hide or show conversation select based on mobile or desktop view
        if(window.innerWidth >= 768){
            this.showConvos = true
        }else{
            this.showConvos = false
        }

    },
    mounted(){
    },
    computed:{
        activeConversation(){
            if(this.activeConversationId == 0){
                return {};
            }else{
                return this.conversations[this.activeConversationId];
            }
        },
        thisName(){
            if(this.activeConversation.attributes == undefined) return '';
            return this.mode == 'mapi' ? this.activeConversation.attributes.membershipTitle : this.$store.state.fi.title
        },
        withName(){
            if(this.activeConversation.attributes == undefined) return '';
            return this.mode == 'fapi' ? this.activeConversation.attributes.membershipTitle : this.$store.state.fi.title
        }
    },
    methods:{
        //Closes open chat or new message form
        closeConversation(){
            if(this.startingNewChat){
                this.startingNewChat = false;
            }else{
                this.activeConversationId = 0;
            }
        },
        msgInnerClass(msg){
                let classes = ' ';
   
                if(!msg.fromSelf)
                    classes += 'bg-white ';
                else
                    classes += 'bg-blue-100 ';

                return classes;
            },
            msgFromClass(msg){
                let classes = ' ';

                if(this.prevMsg !== undefined)
                if(msg.fromSelf ||
                    (msg.fromSelf === this.prevMsg.fromSelf && msg.time === this.prevMsg.time))
                    classes += 'hidden ' 

                return classes;
            },

            // Called last, so updates this.prevMsg to msg
            msgTimeClass(msg){
                let classes = ' ';

                if(this.prevMsg !== undefined)
                    if(msg.time === this.prevMsg.time && msg.fromSelf === this.prevMsg.fromSelf)
                        classes += 'hidden '

                this.prevMsg = msg;
                return classes;
            },
            msgOuterClass(msg){ 
                let classes = ' ';

                if(!msg.fromSelf)
                    classes += 'justify-start ';
                else
                    classes += 'justify-end ';

                if(this.prevMsg !== undefined)
                if(msg.fromSelf != this.prevMsg.fromSelf){
                    classes += 'mt-5 '
                }

                return classes;
            },
            async postMessage(e){           

                //If already sending a message, return 
                if(this.ref_conversation.sendingMessage){
                    console.log('Message not sent, sending previous still');
                    return;
                }

                console.log('post_message: ', e);
                let eurl = this.activeConversation.relationships.events.data.id;
                console.log('eurl: ', this.mode, eurl);
           //Trim fis/#/ from the eurl
           let trimmed = '';
                    let split = eurl.split('/');
                    for(var ii = 2; ii < split.length; ii++){
                        if(trimmed.length > 0)
                            trimmed += '/';

                        trimmed += split[ii];
                    }

                //Disable the busy animation for this post
                this.$store.state.hideBusyAnim = true;
                this.ref_conversation.sendingMessage = true;
                if(this.mode == 'fapi'){       
                    await this.$fapi.post(trimmed, {
                        "data": {
                            "attributes": {
                                "body": e.body
                            }
                        }
                    });
                }else if(this.mode == 'mapi'){
                    await this.$mapi.post(trimmed, {
                        "data": {
                            "attributes": {
                            "body": e.body
                            }
                        }
                    });
                }
                this.$store.state.hideBusyAnim = false;

                //Reload messages
                await this.ref_conversation.loadConversationMessages();
                this.ref_conversation.sendingMessage = false;
                
            },
            async selectConversation(id){
                console.log('selectConversation : ', id);
                this.startingNewChat = false; 
                
                //Make sure not already active convo
                if(this.activeConversationId == id){ return; }

                console.log('selectConversation.a');

                //If already an active convo, set to 0 first to trigger animation
                if(this.activeConversationId != 0){
                    this.activeConversationId = 0;
                    await new Promise(resolve => setTimeout(resolve, 50));  
                }                       
                    
                console.log('selectConversation.b');
                
                //Set active convo
                this.activeConversationId = id;
            }
    },
    setup( props, { emit } ){

        const ref_conversation = ref(null);
            const chatHeightUpdate = ref(0)
            const chatHeight = computed({          
                get: () => {
                    chatHeightUpdate.value++;   
                    if(window.innerWidth >= 768)                 
                        return `max-height: ${window.innerHeight-350}px;`;          
                    else 
                        return '';          
                }
            });
            const startingNewChat = ref(false);
            const showConvos = ref(true);
            const activeConversationId = ref(0)
            
            const messages = ref([
                { key: 0, time: '10/07/21 10:04 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                { key: 1, time: '10/07/21 10:04 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                { key: 2, time: '10/07/21 10:05 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                { key: 3, time: '10/07/21 10:05 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 4, time: '10/07/21 10:06 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                { key: 5, time: '10/07/21 10:06 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                { key: 6, time: '10/07/21 10:06 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                { key: 7, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 8, time: '10/07/21 10:10 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: false  },
                { key: 9, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 14, time: '10/07/21 10:06 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                { key: 15, time: '10/07/21 10:06 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                { key: 16, time: '10/07/21 10:06 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                { key: 17, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 18, time: '10/07/21 10:10 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: false  },
                { key: 19, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 24, time: '10/07/21 10:06 AM', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dolor urna, convallis ac purus id, tempus tempor leo. Aenean id nisl ligula. Etiam ornare tempus tellus a bibendum.', fromSelf: false },
                { key: 25, time: '10/07/21 10:06 AM', text: 'Mauris maximus nisi nisi, in finibus lorem dignissim vehicula.', fromSelf: true  },
                { key: 26, time: '10/07/21 10:06 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: true  },
                { key: 27, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  },
                { key: 28, time: '10/07/21 10:10 AM', text: 'Praesent feugiat a urna in molestie. In elit magna, maximus eget pharetra tempus, pretium eu est. Vestibulum vehicula metus sit amet dui accumsan viverra. ', fromSelf: false  },
                { key: 29, time: '10/07/21 10:10 AM', text: 'Donec pulvinar rhoncus odio, eget sagittis nibh porta sed. ', fromSelf: false  }
            ]);
            
        let prevMsg = undefined;

        return {
            activeConversationId,
            emit,
            messages,
            prevMsg,
            chatHeight,
            chatHeightUpdate,
            ref_conversation,
            showConvos,
            startingNewChat
        }
    }
}

</script>