<template>
	<div class="w-full md:w-96">
		<transition name="down" mode="out-in">  
		</transition>
		<transition name="down" mode="out-in">
			<div v-if="store.state.isLoggedIn" class="bg-gray-50 border rounded p-3">
				<p>You are logged into account: {{store.state.userTitle}}</p>
				<div href="" @click.prevent="$authapi.logout" class="form-button rounded bg-red-800 text-white hover:bg-red-700 border cursor-pointer w-max px-2 py-1">Log Out</div>
			</div>
			<div v-else>
				<transition name="down" mode="out-in">
					<div class="flex flex-col">
                        <img
                            :src="$store.state.theme.mainLogoUrl"
                            class="w-auto rounded-sm mx-auto max-w-xs mb-5"
                            />
						<LoginComp 
                            ref="refLoginComp"
                            formTitle="Admin Login"
                            mode="admin" />
					</div>
				</transition>
			</div>
		</transition>
        <div v-if="store.state.isMobileDevice" class="text-xs text-center w-full box-border opacity-50 hover:opacity-80 duration-200 mt-8 p-1 border border-gray-400 rounded-sm">Installed Version: {{currentBuild}}</div>
	</div>
</template>
<script>
	import { ref } from 'vue'
	import { useStore } from 'vuex'
	
    import { BiometryType, NativeBiometric } from "@capgo/capacitor-native-biometric";
    
	import Form from '@/components/Form.vue'
	import Field from '@/components/Field.vue'
	import Icon from '@/components/Icon.vue'
	import LoginComp from '@/components/LoginComp.vue'
	
	export default {
	  name: 'Login',
	  components: {
	      Form,
	      Field,
	      Icon,
	      LoginComp
	    },
        computed:{
            loginCompStep(){
                if(this.refLoginComp == null){
                    return 'credentials';
                }else{
                    return this.refLoginComp.step;
                }
            }
        },
	    created(){
	        document.title="Login";     
	    },
	  setup(){
	               
        NativeBiometric.isAvailable().then(
            (result) =>{
                let isAvailable = result.isAvailable;
                let isFaceId = results.biometryType == BiometryType.FACE_ID;

                if(isAvailable){

                    //get users creds
                    NativeBiometric.getCredentials({
                        server: this.bioServer,
                    }).then((credentials) => {

                        //auth using bio before loggin in user
                        NativeBiometric.verifyIdentity({
                            reson: "Bio Login",
                            title: "Member Login"
                        }).then(
                            () => {
                                //auth good
                                console.log('Bio Auth Success')
                                console.log(credentials.username, credentials.password);
                            },
                            (error) =>{
                                //auth failed
                                console.log('Bio Auth Failed')
                            }
                        );

                    });
                }
            },
            (error) =>{
                console.log('Bio error: ', error);
            }
        )

	    let store = useStore();                             //store: Store ref
        let currentBuild = window.mn3_build_id
				if(!window.mn3_prod) currentBuild += "S";
        const refLoginComp = ref(null)                
        const bioServer = ref('com.mnolb.id.admin' + store.state.fi.id);


	    return{
            bioServer,
            currentBuild,
            refLoginComp,
	        store
	    }    
	  }
	}
</script>
<style>
	.down-enter-from{
	opacity: 0;
	transform: translateY(-50px);
	}
	.down-enter-active{
	transition: all 0.15s ease-in;
	z-index: -2;
	}
	.down-leave-to{
	opacity: 0;
	transform: translateY(-50px);
	}
	.down-leave-active{
	transition: all 0.15s ease-out;
	z-index: -2;
	}
</style>
