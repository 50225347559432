<template>
    <div class="flex flex-col">
        <!-- pass match -->
        <div v-if="isValidMatch" class="flex items-center py-1">
            <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                <Icon id="check" class="h-5 w-5" />                            
            </div>
            <span>Passwords match</span>
        </div>
        <div v-else class="flex items-center py-1">
            <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                <Icon id="x" class="h-5 w-5" />                            
            </div>
            <span>Passwords must match</span>                                            
        </div>         

        <!-- pass length -->
        <div v-if="isValidLength" class="flex items-center py-1">
            <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                <Icon id="check" class="h-5 w-5" />                            
            </div>
            <span>Minimum length met</span>
        </div>
        <div v-else class="flex items-center py-1">
            <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                <Icon id="x" class="h-5 w-5" />                            
            </div>
            <span>At least {{minLength}} characters must be used</span>                                            
        </div>
                        
        <!-- special char -->
        <div v-if="minSpecial > 0" class="flex flex-col">
            <div v-if="isValidSpecial" class="flex items-center py-1">
                <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                    <Icon id="check" class="h-5 w-5" />                            
                </div>
                <span>At least {{minSpecial}} special character used:</span>    
                <div class="font-mono ml-2 flex flex-row">
                    <span v-for="(char, i) in specialChars" :key="char" :class="i > 11 ? 'hidden' : ''">{{char}}</span>
                </div>              
            </div>
            <div v-else class="flex items-center py-1">
                <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                    <Icon id="x" class="h-5 w-5" />                            
                </div>
                <span>At least {{minSpecial}} special character must be used:</span>    
                <div class="font-mono ml-2 flex flex-row">
                    <span v-for="(char, i) in specialChars" :key="char" :class="i > 11 ? 'hidden' : ''">{{char}}</span>
                </div>                                 
            </div>
        </div>

        <!-- digits check -->
        <div v-if="minDigits > 0" class="flex flex-col">
            <div v-if="isValidDigits" class="flex items-center py-1">
                <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                    <Icon id="check" class="h-5 w-5" />                            
                </div>
                <span>At least {{minDigits}} number used</span>
            </div>
            <div v-else class="flex items-center py-1">
                <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                    <Icon id="x" class="h-5 w-5" />                            
                </div>
                <span>At least {{minDigits}} number must be used</span>                                            
            </div>        
        </div>                               
                        
        <!-- upper check -->
        <div v-if="minUpper > 0" class="flex flex-col">
            <div v-if="isValidUpper" class="flex items-center py-1">
                <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                    <Icon id="check" class="h-5 w-5" />                            
                </div>
                <span>At least {{minUpper}} upper case letter used</span>
            </div>
            <div v-else class="flex items-center py-1">
                <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                    <Icon id="x" class="h-5 w-5" />                            
                </div>
                <span>At least {{minUpper}} upper case letter must be used</span>                                            
            </div>       
        </div>

        <!-- lower check -->
        <div v-if="minLower > 0" class="flex flex-col">
            <div v-if="isValidLower" class="flex items-center py-1">
                <div class="bg-green-200 text-green-700 rounded-lg mr-2">
                    <Icon id="check" class="h-5 w-5" />                            
                </div>
                <span>At least {{minLower}} lower case letter used</span>
            </div>
            <div v-else class="flex items-center py-1">
                <div class="bg-red-200 text-red-700 rounded-lg mr-2">
                    <Icon id="x" class="h-5 w-5" />                            
                </div>
                <span>At least {{minLower}} lower case letter must be used</span>                                            
            </div>
        </div>
    </div>  
</template>

<script>

import Icon from '@/components/Icon.vue'

export default {
    components: {
        Icon
    },
    computed:{
        isValidLength(){
            return (this.$props.pass1.length >= this.$props.minLength);
        },
        isValidMatch(){
            return (this.$props.pass1 == this.$props.pass2 && this.$props.pass1.length >= 1);
        },
        isValidUpper(){
            let upperFound = 0;
            let str = this.$props.pass1 + ""    
            let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(upper.indexOf(char) != -1){
                    upperFound++;
                }
            }
            return upperFound >= this.$props.minUpper            
        },
        isValidLower(){
            let lowerFound = 0;
            let str = this.$props.pass1 + ""    
            let lower = 'abcdefghijklmnopqrstuvwxyz'

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(lower.indexOf(char) != -1){
                    lowerFound++;
                }
            }
            return lowerFound >= this.$props.minLower            
        },
        isValidDigits(){
            let numbersFound = 0;
            let str = this.$props.pass1 + ""                    
            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(!isNaN(char)){
                    numbersFound++;
                }
            }
            return numbersFound >= this.$props.minDigits
        },
        isValidSpecial(){
            let specialFound = 0;
            let str = this.$props.pass1 + ""    
            let special = this.$props.specialChars

            for (var i = 0; i < str.length; i++) {
                let char = str.charAt(i);
                if(special.indexOf(char) != -1){
                    specialFound++;
                }
            }
            return specialFound >= this.$props.minSpecial 
        },
    },
    methods: {        
        isValidPass(){
            return this.isValidPass && this.isValidLength && this.isValidMatch && this.isValidUpper && this.isValidLower && this.isValidSpecial;
        }
    },
    props:{
        pass1: {
            type: String,
            default: ''
        },
        pass2: {
            type: String,
            default: ''
        },
        minLength: {
            type: Number,
            default: 8
        },
        minUpper: {
            type: Number,
            default: 1
        },
        minLower: {
            type: Number,
            default: 0
        },
        minDigits: {
            type: Number,
            default: 1
        },
        minSpecial: {
            type: Number,
            default: 1
        },
        specialChars: {
            type: String,
            default: '!@#$%^&*()?_-'
        }
    },
    setup(){

        return {
        }
    }
}
</script>