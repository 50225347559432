<template>
    <div class="flex flex-col text-xl mn3-content p-3 mt-6 rounded-md">
        <div class="txl-2xl font-bold font-mono">404: Page Not Found</div>
        <div>The url entered could not be found.</div>
    </div>

</template>

<script>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    export default{
        name: 'Page Not Found',
        components: {
        },        
        created(){
            document.title = 'Page Not Found'
        },
        setup(){

            const router = useRouter();
            const buttonClasses = 'mn3-button cursor-pointer p-2 rounded-sm mb-2'

            return{
                buttonClasses,
                router,
            }

        }
    }
</script>