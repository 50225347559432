import { createStore } from 'vuex'


export default createStore({
  state: {
    initialLoadComplete: false, // If app has gotten past checking for/downloading update
	isLoading: true, // When true do not show main layout and show loading msg.
	isBusy: false, // When true indicate that something is going on in the background but still show content
	isPhoneDemo: false, // If build is a demo for phone, uses placeholder data instead of connecting to backend
	hideBusyAnim: false, // Hide the isBusy animation
	isLoggedIn: false, // Only allow public pages when this is false
	isLoggedInCuAdmin: false, // Can view /cuadmin/ screens
	isLoggedInAppAdmin: false, // can view /appadmin/ screens
	isLoggedInAny: false, // Logged into membnet, cuadmin, or app admin
    isMobileDevice: false, // Using a mobile device instead of desktop browser
    isMobileNavOpen: false, // If mobile menu is currently open
    isIos: false, // Using an ios device
    isAndroid: false, // Using an android device
	isUpdateAvailable: false, // Is there a new version available but not required.
	isUpdateRequired: false, // Is there a new version available but not required.
	memberId: null,
	membershipId: null, // Current membership
	memberships: [],
    noticeClosed: false,
    servicesEnabled: false,
    servicesName: "Services",
	showLogoutNotice: false,
	showInactiveNotice: false,
	userTitle: null,
    currentPage: "/",
	fi: {},
	theme: {},
    serverBundleId: 1,
    appBaseBuild: 0,
	screenCategory: "",
    updateStatus: null,
    permissions: {},
  },
  getters: {
  },
  mutations: {
	setIsLoggedIn(state, value){
		state.isLoggedIn = value;
	},
	setIsLoggedInCuAdmin(state, value){
		state.isLoggedInCuAdmin = value;
	},
	setIsLoggedInAppAdmin(state, value){
		state.isLoggedInAppAdmin = value;
	},
	setIsLoggedInAny(state, value){
		state.isLoggedInAny = value;
	},
	setIsMobileDevice(state, value){
		state.isMobileDevice = value;
	},
	setIsIos(state, value){
		state.isIos = value;
	},
	setIsAndroid(state, value){
		state.isAndroid = value;
	},
	setMemberId(state, value){
		state.memberId = value;
	},
	setMembershipId(state, value){
		state.membershipId = value;
	},
	setMemberships(state, value){
		state.memberships = value;
	},
	setPermissions(state, value){
		//convert to an object to make checking easy
		let p = {};
		for (var i = 0; i < value.length; i++) {
			p[value[i]] = true;
		}
		state.permissions = p;
	},
	setIsBusy(state, value){
		state.isBusy = value;
	},
	setUserTitle(state, value){
		state.userTitle = value;
	},
	setCurrentPage(state, value){
		state.currentpage = value;
	},
	setIsLoading(state, value){
		state.isLoading = value;
	},
	setIsUpdateAvailable(state, value){
		state.isUpdateAvailable = value;
	},
	setFi(state, value){
		state.fi = value;
	},
	setShowInactiveNotice(state, value){
		state.showInactiveNotice = value;
	},
	setShowLogoutNotice(state, value){
		state.showLogoutNotice = value;
	},
	setTheme(state, value){
		state.theme = value;
	},
	setScreenCategory(state, value){
		state.screenCategory = value;
	},
	setUpdateStatus(state, value){
		state.updateStatus = value;
	},
  },
  actions: {},
  modules: {}
})
