<!-- InputColor: A field input used to select a color -->
<template>
    <div>
        <div class="flex flex-col justify-between">
            <p class="mn3-content">{{label}}</p>
            <div class="flex justify-between mn3-input border p-1 items-center">
                <input type="text" 
                    ref="colorValue"
                    v-model="input"
                    maxlength="7"
                    class="mn3-input w-full"
                    @blur="updateColorValue;clearErrors"
                    @keyup="updateColorValue"
                    @keydown="clearErrors" />   
                <div 
                    title="Revert Change"
                    :class="`cursor-pointer w-6 h-5 rounded border mn3-button 
                        ${formValue.value === initialVal ? 'opacity-20' : ''}`"
                    @click="formValue.value = initialVal">
                    <Icon id="reset" />    
                </div>             
                <div 
                    title="Copy Value"
                    class="cursor-pointer ml-1 w-6 h-5 rounded border mn3-button "
                    @click="copyValue">
                    <Icon id="copy" />    
                </div>
            </div>
        </div>
         <div class="val val--input">
               <input type="color" :id="id"   
                    ref="colpick"
                    :tabindex="index"  
                    v-model="input"
                    @blur="clearErrors;emit('blur')"
                    @keydown="clearErrors"
                    :class="`mn3-input border w-full text-right md:text-left p-1`" />
                <div class="mn3-input w-full h-6 rounded-b border-l border-r border-b" :style="`background-color: ${input}`"></div>
            </div>
    </div>
</template>
<script>

import { ref, computed } from "vue";

import Icon from '@/components/Icon.vue'

export default{
    components:{
        Icon
    },
    props:{
        /* Properties without defaults */
        id: String,                     //id: ID assigned to the input
        index: Number,                  //index: Tabindex assigned to the input

        /* Properties with defaults */
        formValue: {                    //formValue: Used to store field input    
            type: Object,
            default: ref("")
        },
    },
    methods:{        
        focus(){
            console.log('InputColor doesnt support focus');
        },        
    },
    setup(props, { emit }){
        
        const val = ref(props.formValue);
        var input = computed({
                get: () => val.value,
                set: (v) => (val.value = v,emit('updated', formValue => Object.keys(formValue)[0], v))
            });
        const colorValue = ref(null);
        const initialVal = props.formValue.value;
        
        const copyValue = function(){
            emit('onError', 'Value Copied')
            document.execCommand("copy")
        }

        function clearErrors(){
            emit('onError', '')
        }

        return {
            clearErrors,
            colorValue,
            copyValue,
            initialVal,
            input
        }

    }
}

</script>