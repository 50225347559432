<template>
    <div class="w-full flex flex-row">
        <Form 
            :title="`${chatOpen ? 'Member Info' : 'FI List'}`" 
            :class="`mx-auto w-full flex-grow max-w-screen-lg`"
            :showSubmit="false"
            :showCancel="false">
            <!--Members Header-->
            <div class="w-full flex flex-row"
                v-if="!chatOpen">
                <div class="p-1 cursor-pointer opacity-70 hover:opacity-100"
                    @click="toggleViewUnread()"
                    title="View Members Awaiting a Reply">
                    <Icon  id="mail" class="h-6 w-6" />
                </div>
                <div 
                    class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                    <input type="text" 
                        class="mn3-content rounded-sm p-2 border text-sm w-full" 
                        placeholder="Quick Filter" 
                        v-model="quickFilterVal"  
                        @keydown="quickFilterKeydown" />
                </div>
            </div>
            <div v-for="memb in membersToShow" :key="memb.id"
                class="flex flex-col pt-4 border-b">
                
                <!-- Multi-Line Display-->
                    <div class="duration-150 flex flex-row justify-between pb-4">
                        <div class="px-2 py-1">
                            <img v-if="memb.id == 1" src="@/assets/img/icon-topmark.png" class="h-14 w-14 rounded mn3-content border" />
                            <img v-else-if="memb.id == 2" src="@/assets/img/icon-lcu.png" class="h-14 w-14 rounded mn3-content border" />
                            <img v-else src="@/assets/img/icon-mn3.png" class="h-14 w-14 rounded mn3-content border" />
                        </div>
                        <div class="flex flex-col px-2 flex-grow">
                            <div title="Open Web App" 
                                    class="font-bold text-xl flex flex-row justify-start items-center opacity-80 hover:opacity-100 duration-150">
                                <Icon id="export" 
                                    class="h-6 w-6 mr-2 cursor-pointer opacity-70"/>
                                    <p @click="focusedId = memb.id" class="cursor-pointer">{{memb.name}}</p>
                            </div>
                            <div class="flex flex-row justify-start">
                                <div class="font-mono">{{memb.phone}},</div>
                                <div class="font-mono ml-3">{{memb.email}}</div>
                            </div>
                            <div v-if="memb.alert != undefined"
                                class="mn3-alert text-sm p-1 rounded-sm flex-grow mr-2 mb-1 my-2 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="exclamation" class="h-6 w-6 mr-2" />
                                <p>{{memb.alert}}</p>
                            </div>
                            <div v-if="memb.notice != undefined"
                                class="mn3-content-alt text-sm p-1 rounded-sm flex-grow mr-2 my-2 mb-1 text-left pl-2 flex flex-row items-center justify-start">
                                <Icon id="info" class="h-6 w-6 mr-2" />
                                <p>{{memb.notice}}</p>
                            </div>
                        </div>

                        <div class="flex flex-col items-center w-28 mx-2 tracking-tighter flex-grow-0 flex-shrink-0">
                            <p class="font-bold text-3xl">{{numberWithCommas(memb.logins)}}</p>
                            <p class="text-center text-xs uppercase">Logins</p>
                            <p class="text-center text-xs uppercase opacity-70">(Last 30 Days)</p>
                        </div>

                        <div class="flex flex-col items-center w-28 mx-2 tracking-tighter flex-grow-0 flex-shrink-0">
                            <p class="font-bold text-3xl">{{numberWithCommas(memb.usersAndroid)}}</p>
                            <p class="text-center text-xs uppercase">Android Installs</p>
                        </div>
                        
                        <div class="flex flex-col items-center w-28 mx-2 tracking-tighter flex-grow-0 flex-shrink-0">
                            <p class="font-bold text-3xl">{{numberWithCommas(memb.usersIos)}}</p>
                            <p class="text-center text-xs uppercase">IOS Installs</p>
                        </div>

                        <div class="flex flex-grow-0 flex-col justify-start mx-2 uppercase text-xs font-bold text-center w-40">

                            <div @click="router.push('/admin')" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="pc" class="h-4 w-4 mr-2"/>
                                <p>Login as FI Admin</p>
                            </div>
                            <div @click="router.push('fisettings')" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="cog" class="h-4 w-4 mr-2"/>
                                <p>FI Settings</p>
                            </div>
                            <div @click="router.push('ficolor')" class="cursor-pointer px-2 py-1 rounded mn3-button mb-1 flex flex-row justify-between items-center">
                                <Icon id="settings" class="h-4 w-4 mr-2"/>
                                <p>FI Colors</p>
                            </div>
                            
                        </div>
                    </div>
                    

            </div>
        </Form>

        <transition name="fade">
            <div v-if="chatOpen" class="flex flex-col max-w-screen-lg w-full">
                <Chat 
                    @close="chatOpen = false;focusedId = -1;"
                    :messages="members[0].messages"
                    :withName="members[0].name" />
            </div>
        </transition>
    </div>
</template>

<script>
    import { ref } from 'vue'

	import Chat from "@/components/Chat.vue";
	import Form from "@/components/Form.vue";
	import Icon from "@/components/Icon.vue";
	import Field from "@/components/Field.vue";
    import { useRouter } from "vue-router"

	export default {
		components:{
            Chat,
			Form,
			Field,
            Icon
		},
		data(){
			return {
			};
		},
        computed: {
            membersToShow(){

                let allMemb = [];

                if(this.chatOpen){
                    return [this.members[0]];
                }

                if(this.viewUnread == true){
                    for(var ii = 0; ii < this.members.length; ii++){
                        let memb = this.members[ii];
                        if(memb.unread != undefined){
                            allMemb.push(memb);
                        }
                    }
                }else{
                    allMemb = this.members
                }

                if(this.quickFilterVal == '')
                    return allMemb;
                else{
                    this.focusedId = -1;
                    let needle = this.quickFilterVal.toLowerCase();
                    let membersToShow = [];

                    console.log('needle: ', needle)

                    for(var ii = 0; ii < allMemb.length; ii++){

                        let memb = allMemb[ii];
                        console.log('memb: ', memb);
                        if(memb.name.toLowerCase().indexOf(needle) != -1
                        ||memb.uname.toLowerCase().indexOf(needle) != -1
                        ||memb.email.toLowerCase().indexOf(needle) != -1
                        ||memb.phone.indexOf(needle) != -1
                        ||memb.phone.replace(/-/g, "").indexOf(needle) != -1)
                            membersToShow.push(memb);

                    }

                    return membersToShow;

                }
            }
        },
		created(){
            document.title = 'FI List'
		}, 

        setup(){
            const viewMode = ref("large")
            const focusedMode = ref("");
            const chatOpen = ref(false);
            const focusedId = ref(-1)
            const quickFilterVal = ref("")
            const viewUnread = ref(false)
            const router = useRouter();
            const members = ref([
                {
                    id: 1,
                    icon: 'icon-topmark.png',
                    name: 'TopMark FCU',
                    uname: 'com.mnolb.topmark',
                    email: 'contact@topmark.com',
                    phone: '262-727-8282',
                    logins: '1234',
                    usersAndroid: '100',
                    usersIos: '200',
                },
                {
                    id: 2,
                    icon: 'icon-lcu.png',
                    name: 'Latvian Credit Union',
                    uname: 'com.mnolb.latviamn',
                    email: 'contact@latviamn.com',
                    phone: '262-727-8282',
                    logins: '234',
                    usersAndroid: '25',
                    usersIos: '40',
                },
                {
                    id: 3,
                    icon: 'icon-mn3.png',
                    name: 'MN3FR3',
                    uname: 'com.mnolb.mn3fr3',
                    email: 'contact@mn3fr3.com',
                    phone: '262-727-8282',
                    logins: '89',
                    usersAndroid: '1',
                    usersIos: '2',
                },
            ])

            const activeConvo = ref(1);
            const convos = ref([
                {
                    id: 1,
                    title: 'Login issue 3',
                    lastActivity: '08/07/2022',
                    awaitingReply: true
                },
                {
                    id: 2,
                    title: 'Login issue 2',
                    lastActivity: '08/04/2022',
                    awaitingReply: false
                },
                {
                    id: 3,
                    title: 'Login issue',
                    lastActivity: '08/01/2022',
                    awaitingReply: true
                }
            ]);

            function loginAsUser(){
                window.open('')
            }

            function toggleViewUnread(){
                viewUnread.value =  !viewUnread.value;
            }

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

            return { 
                numberWithCommas,
                loginAsUser,
                chatOpen,
                quickFilterVal,
                focusedId,
                focusedMode,
                convos,
                activeConvo,
                members,
                viewMode,
                viewUnread,
                router,
                toggleViewUnread

             }

        }
	}
	
</script>
