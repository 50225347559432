<template>
    <div class="w-full flex flex-wrap">
        <div v-if="!loaded" class="italic w-full text-center">
            Loading Event Monitor 
        </div>
        <div v-else
            class="w-full">
            <iframe id="frame" :src="eventsUrl" :style="frameHeight" class="w-full md:rounded" />
        </div>
	</div>
</template>

<script>
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Icon from "@/components/Icon.vue";
    import { ref, computed } from 'vue'
    import { useRouter } from "vue-router"

	export default {
		components:{
            Icon,
			Form,
			Field
		},
		data(){
			return {
			};
		},

		created(){
            document.title = 'Event Monitor';
            this.frameHeightUpdate++;
            window.addEventListener("resize", this.windowResize);
            this.fetchOpenSearch();
		}, 
        methods: {       
            async fetchOpenSearch(){
                let fetched = await this.$fapi.get("osd-sso")
                console.log('fetched: ', fetched);
                this.eventsUrl = fetched.data.data.attributes.url
                this.loaded = true
            },             
            windowResize(e){
                this.frameHeightUpdate++;
            }
        },
        setup(){           
            const loaded = ref(false)
            const eventsUrl = ref('');
            const frameHeightUpdate = ref(0)
            const frameHeight = computed({          
                get: () => {
                    frameHeightUpdate.value++;      
                        
                    if(window.innerWidth >= 768)                 
                        return `height: ${window.innerHeight-225}px;`;      
                    else 
                        return `height: ${window.innerHeight-132}px;`;           
                }
            });

            return {        
                eventsUrl,            
                frameHeight,
                frameHeightUpdate,
                loaded
            }

        }
	}
	
</script>
