<template>
    <div class="w-full flex flex-col max-w-screen-md mx-auto">
        <BoardPackets :files="files" :folders="folders" />	
	</div>
</template>

<script>
	import BoardPackets from "@/components/BoardPackets.vue";
	import Button from "@/components/Button.vue";
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import FileUpload from "@/components/FileUpload.vue";
	import Icon from "@/components/Icon.vue";
    import { ref } from 'vue'

	export default {
		components:{
            BoardPackets,
            Button,
			Form,
			Field,
            FileUpload,
            Icon
		},
		data(){
			return {
			};
		},
        computed:{
            folders(){
                let folders = [];

                for(var ii = 0; ii < this.files.length; ii++){
                    if(this.files[ii].folder && !folders.includes(this.files[ii].folder))
                        folders.push(this.files[ii].folder);
                }

                return folders;
            }
        },
        methods: {
            formatDate(raw){
                let date = new Date(raw * 1000).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit"                
                });
                return date;
            },
            formatTime(raw){
                let date = new Date(raw * 1000).toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "2-digit"                
                });
                let time = new Date(raw * 1000).toLocaleTimeString("en-US", {   
                    hour: '2-digit',
                    minute: '2-digit'          
                });
                return date + ' ' + time;
            },
            async readFiles(){
                let fetched = await this.$fapi.get('boardpacket/files');
                console.log('fetched: ', fetched);
                let items = fetched.data.data;
                this.folders = [];
                this.files = [];

                for(var ii = 0; ii < items.length; ii++){
                    if(items[ii].type == 'BoardPacketFolder')
                        this.folders.push(items[ii].attributes.name);
                    else if(items[ii].type == 'BoardPacketFile'){
                        let item = items[ii];
                        item.folder = '';
                        this.files.push(item);
                    }
                }
                //Load files for all folders
                for(var ii = 0; ii < this.folders.length; ii++){
                    this.readFolderFiles(this.folders[ii])
                }
            },
            async readFolderFiles(folder){
                console.log('readFolderFiles: ', folder);
                let fetched = await this.$fapi.get('boardpacket/files/' + folder);
                console.log('fetched: ', fetched);
                let items = fetched.data.data;
                for(var ii = 0; ii < items.length; ii++){
                    if(items[ii].type == 'BoardPacketFile'){
                        let item = items[ii];
                        item.folder = folder;
                        this.files.push(item);
                    }
                }
            },
        },  
		created(){
            document.title = 'Board Packets'
            this.readFiles();
		}, 

        setup(){            
            const files = ref([])
            const folders = ref([])
            const viewFolder = ref('-1');

            return { 
                files,
                folders,
                viewFolder
            }

        }
	}
	
</script>
