<template>
    <div class="w-full max-w-screen-sm mx-auto w-full">
        <Form v-if="step == 'tos'"
        cancelText="Decline Terms"
        submitText="Continue"
        :showCancel="false"
        @submit="submitRegistration()">
            <div class="p-2 w-full">
                <div class="flex flex-col">
                    <div class="p-2 h-fit-content rounded-sm flex items-center">
                        <img
                            :src="$store.state.theme.mainLogoUrl"
                            class="w-auto rounded-sm mx-auto max-w-xs mb-2"
                            />
                    </div>
                    <div class="flex flex-col text-sm items-center flex-grow">                        
                        <p class="font-bold mb-2">Member.Net Digital Banking Terms and Conditions</p>
                        <div>
                            <p class="font-bold">{{$store.state.fi.title}}</p>
                            <p>{{$store.state.fi.address1}}, {{$store.state.fi.city}}, {{$store.state.fi.state}} {{$store.state.fi.zipCode}}</p>
                            <p>Phone: {{$store.state.fi.phone}}</p>
                        </div>
                    </div>
                </div>
                <div class="mn3-content rounded-sm text-xs my-2 mx-2 p-2 text-left">
                    Manage your {{$store.state.fi.title}} Member.Net Digital Banking simply and securely, any time and anywhere you have internet access. It takes just a few minutes to register.  Please accept the Terms and Conditions Agreement to continue the registration process.
                </div>
                <div class="flex w-full flex-wrap">                    
                    <transition name="fade">
                        <div v-if="showTermsAlert"
                            class="mn3-alert p-2 font-bold rounded w-full text-center mx-3">Terms and conditions must be accepted to register your account.</div>
                    </transition>
                    <div v-if="externalTerms" class="w-full px-3">
                        <a :href="$store.state.fi.termsUrl" target="_blank">
                            <div class="flex items-center mn3-content-hover border rounded w-full cursor-pointer p-2"><Icon id="export" class="w-5 h-5"/>View Terms and Conditions</div>
                        </a>
                    </div>
                    <div class="flex w-full flex-wrap" v-else>
                        <div v-for="term in terms" :key="term.header"
                            class="mn3-content rounded-sm text-xs my-2 p-2 border w-1/2">
                                <p class="font-bold">{{term.header}}</p>
                                <p class=" text-xs">{{term.body}}</p>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="terms-accept" class="normal-label flex justify-start mn3-content-hover items-center m-3 cursor-pointer opacity-80 hover:opacity-100 border rounded-sm p-2 box-border">
                            <input v-model="fv.accept" type="checkbox" class="w-6 h-6" id="terms-accept" />
                            <div class="ml-5" for="terms-accept">I Accept the Terms and Conditions</div>
                        </label>
                    </div>
                </div>
            </div>
        </Form>
        <Form 
            v-if="step == 'verify' || step == 'user'  || step == 'options' || step == 'questions'"
            :submitText="`${step == 4 ? 'Finish Registration' : 'Continue'}`"
            class="w-full"
            @submit="verifyCurrentStep()">
            <div class="px-5 flex-col">
                <div class="w-full my-5 rounded-sm p-2 text-center mn3-content border mb-5">
                    <img :src="$store.state.theme.mainLogoUrl"
                        class="w-auto rounded-sm mx-auto max-w-xs mb-5"
                    />
                </div>
                <div class="flex justify-between px-5 pt-5 items-center">
                    <div :class="`${step == 'verify' ? 'mn3-content-alt' : ''} rounded-full h-6 w-6 text-center flex-shrink-0`">1</div>
                    <div class="h-0.5 w-full bg-gray-400 flex-grow"></div>
                    <div :class="`${step == 'user' ? 'mn3-content-alt' : ''} rounded-full h-6 w-6 text-center flex-shrink-0`">2</div>
                    <div class="h-0.5 w-full bg-gray-400 flex-grow"></div>
                    <div :class="`${step == 'options' ? 'mn3-content-alt' : ''} rounded-full h-6 w-6 text-center flex-shrink-0`">3</div>
                </div>
                <div class="flex justify-between px-5 mb-10 mt-2 items-center text-xs">
                    <div :class="`${step == 'verify' ? 'font-bold' : ''} w-14 `">ACCOUNT VALIDATION</div>
                    <div :class="`${step == 'user' ? 'font-bold' : ''} w-16 text-center `">ACCOUNT SETUP</div>
                    <div :class="`${step == 'options' ? 'font-bold' : ''} w-14 text-right `">ACCOUNT OPTIONS</div>
                </div>
                <div v-if="error != ''" class="mn3-alert w-full text-center p-2 text-lg rounded">{{error}}</div>
                <div class="flex-col" v-if="step == 'verify'">
                    <Field 
                        label="Account Type" 
                        type="select" 
                        :formValue="fv.acctType"
                        :options="acctTypeOptions" />  
                    <Field 
                        v-if="enabledFields.account"
                        label="Member Account Number" 
                        type="text" 
                        :maxLength="24"
                        textFormat="number"
                        :formValue="fv.acctNum"/>   
                    <Field 
                        v-if="enabledFields.ssn"
                        label="Social Security Number" 
                        type="text" 
                        textFormat="ssn"
                        :maxLength="11"
                        :formValue="fv.ssn" />
                    <Field 
                        v-if="enabledFields.address"
                        label="Street Address" 
                        type="text" 
                        textFormat="text"
                        :maxLength="48"
                        :formValue="fv.addr" />

                    <div class="ml-3 mr-3">
                    <div v-if="enabledFields.dob && fv.acctType.value == 'personal'"
                        class="w-full flex flex-row items-center border-b-2 mn3-input-text">
                        <label for="dobMonth" class="flex-grow flex items-center justify-start">Date of Birth <Icon id="chev-right" class="h-6 w-6 ml-1" /></label>    
                        <Field 
                        label="" 
                        name="dobMonth"
                        type="select" 
                        :formValue="fv.dobMonth"
                        :options="dobMonthOptions" />    
                        <Field 
                        label="" 
                        type="select" 
                        :formValue="fv.dobDate"
                        :options="dobDateOptions" />      
                        <Field 
                        label="" 
                        type="select" 
                        :formValue="fv.dobYear"
                        :options="dobYearOptions" />                    
                    </div>
                    </div>
                </div>
                <div class="flex-col" v-if="step == 'user'">
                    <Field 
                        v-if="enabledFields.username"
                        label="Username" 
                        type="text" 
                        :maxLength="20"
                        desc="Must be 6-20 characters long. Do not use your email address."
                        :formValue="fv.username"/>
                    <Field 
                        v-if="enabledFields.email"
                        label="Email Address" 
                        type="text" 
                        :formValue="fv.email"/>  
                    <Field 
                        v-if="enabledFields.email"
                        label="Verify Email Address" 
                        type="text" 
                        :formValue="fv.email2"/>                        
                    <Field 
                        label="Password" 
                        type="password" 
                        :formValue="fv.pass1"/>
                    <Field 
                        label="Confirm Password" 
                        type="password" 
                        :formValue="fv.pass2"/>
                    <PasswordChecker ref="passwordChecker" 
                        :pass1="fv.pass1.value" 
                        :pass2="fv.pass2.value" 
                        :minLength="requirements.minLength"
                        :minUpper="requirements.minUpper"
                        :minLower="requirements.minLower"
                        :minDigits="requirements.minDigits"
                        :minSpecial="requirements.minSpecial"
                        :specialChars="requirements.specialChars"
                        class="mx-2" />                    
                </div>      
                <div class="flex-col" v-if="step == 'questions'">  
                    <Field 
                        v-if="enabledFields.question0"
                        label="First Security Question" 
                        type="select" 
                        :formValue="fv.sq0"
                        :options="questions0" />
                    <Field 
                        v-if="enabledFields.answer0"
                        label="First Answer" 
                        type="password" 
                        :formValue="fv.sa0"/>
                        
                    <Field 
                        v-if="enabledFields.question1"
                        class="mt-5"
                        label="Second Security Question" 
                        type="select" 
                        :formValue="fv.sq1"
                        :options="questions1" />
                    <Field 
                        v-if="enabledFields.answer1"
                        label="Second Answer" 
                        type="password" 
                        :formValue="fv.sa1"/>                        
                    <Field 
                        v-if="enabledFields.question2"
                        class="mt-5"
                        label="Third Security Question" 
                        type="select" 
                        :formValue="fv.sq2"
                        :options="questions2" />
                    <Field 
                        v-if="enabledFields.answer2"
                        label="Third Answer" 
                        type="password" 
                        :formValue="fv.sa2"/>                        
                </div>                          
                <div class="flex-col" v-if="step == 'options'">
                    <div v-if="enabledFields.statements" class="flex flex-col w-full">
                        <div class="text-lg font-bold">E-Statements</div>
                        <label for="estmts" class="normal-label flex justify-start mn3-content-hover items-center m-3 cursor-pointer opacity-80 hover:opacity-100 border rounded-sm p-2 box-border">
                            <input  ref="estmts" type="checkbox" class="w-6 h-6" id="estmts" checked="checked" />
                            <div class="ml-5" for="estmts">Enroll in E-Statements</div>
                        </label>
                    </div>
                    <div v-if="enabledFields.notices" class="flex flex-col w-full">
                        <div class="text-lg font-bold">E-Notices</div>
                        <label for="enotices" class="normal-label flex justify-start mn3-content-hover items-center m-3 cursor-pointer opacity-80 hover:opacity-100 border rounded-sm p-2 box-border">
                            <input ref="notices" type="checkbox" class="w-6 h-6" id="enotices" checked="checked"  />
                            <div class="ml-5" for="enotices">Enroll in E-Notices</div>
                        </label>
                    </div>
                    
                </div>    
            </div>
        </Form>               
        <div class="w-full mn3-content mb-5" v-if="step == 'done'">
            <div class="mx-auto uppercase p-5 rounded mn3-notice border my-5 w-3/4 text-center">
                <p class="text-3xl font-bold">Registration Complete</p>
            </div>            
            <div class="flex justify-around">
                <div class="mn3-button border text-center cursor-pointer p-2 rounded w-1/3" @click="$router.push('/accounts')">Proceed to Login</div>
            </div>
        </div>
        <div class="text-center text-sm bg-gray-50 p-2 rounded-sm border border-gray-200">
           If you are having trouble registering, please contact us at {{$store.state.fi.phone}}
        </div>
    </div>
</template>

<script>

    import { ref, computed } from 'vue'
    import { useStore } from 'vuex'

    import Form from '@/components/Form.vue'
    import Icon from '@/components/Icon.vue'
    import Field from '@/components/Field.vue'
    import PasswordChecker from '@/components/PasswordChecker.vue'

    export default {
        name: 'Register',
        components: {
            Form,
            Field,
            Icon,
            PasswordChecker
        },
        created(){
            document.title = 'Account Registration'

            //Debug: Check if setting external terms
            let external = this.$route.query.external
            if(external == 'true')
                this.externalTerms = true;
            else if(external == 'false')
                this.externalTerms = false;
        },
        computed:{
            acctTypeOptions(){
                let opts = [{value: 'personal', label: 'Personal Account'},
                        {value: 'business', label: 'Business Account'}];                        

                if(this.$store.state.fi.system == 'encompass')
                    opts.push({value: 'business', label: 'Trust'});

                return opts;
            },  
            dobMonthOptions() {
                return [
                    { value: '00', label: 'Month'},
                    { value: '01', label: 'Jan' },
                    { value: '02', label: 'Feb' },
                    { value: '03', label: 'Mar' },
                    { value: '04', label: 'Apr' },
                    { value: '05', label: 'May' },
                    { value: '06', label: 'Jun' },
                    { value: '07', label: 'Jul' },
                    { value: '08', label: 'Aug' },
                    { value: '09', label: 'Sep' },
                    { value: '10', label: 'Oct' },
                    { value: '11', label: 'Nov' },
                    { value: '12', label: 'Dec' }];
            },
            dobDateOptions(){
                let ret = [{ value: '00', label: 'Day'}];
                for(var ii = 1; ii <= 31; ii++){
                    ret.push({ value: ii, label: ii})
                }
                return ret;
            },
            dobYearOptions(){
                let ret = [{ value: '00', label: 'Year'}];
                for(var ii = 2023; ii >= 1900; ii--){
                    ret.push({ value: ii, label: ii})
                }
                return ret;
            },
        },
        methods: {  
            submitRegistration(){ 

                let holdSubmit = false;
                
                //Build data object based on current step
                let d = {};
                switch(this.step){
                    case 'tos':
                        d['agree'] = this.fv.accept
                        break;

                    case 'verify':
                        let dobDate = this.fv.dobDate.value.toString();
                        if(dobDate.length == 1)
                            dobDate = '0' + dobDate
                        let dobMonth = this.fv.dobMonth.value.toString();
                        if(dobMonth.length == 1)
                            dobMonth = '0' + dobMonth
                        
                        d['account'] = this.fv.acctNum.value
                        d['ssn'] = this.fv.ssn.value.replace(/-/g, '')
                        d['address'] = this.fv.addr.value
                        d['dob'] = this.fv.dobYear.value + '-' 
                            + dobMonth + '-'
                            + dobDate

                        //If ORG account, send ORG instead of date
                        if(this.fv.acctType.value == 'business'){
                            d['dob'] = 'ORG';
                        }
                        break;

                    case 'user':
                        d['username'] = this.fv.username.value
                        d['email'] = this.fv.email.value
                        d['password'] = this.fv.pass1.value
                        break;

                    case 'questions':
                        d['question0'] = this.fv.sq0.value
                        d['answer0'] = this.fv.sa0.value
                        d['question1'] = this.fv.sq1.value
                        d['answer1'] = this.fv.sa1.value
                        d['question2'] = this.fv.sq2.value
                        d['answer2'] = this.fv.sa2.value
                        break;
                        
                    case 'options':
                        d['statements'] = (this.estmts == undefined ? false : this.estmts.checked)
                        d['notices'] = (this.notices == undefined ? false : this.notices.checked)
                        break;
                }

                let send = {
                    step: this.step,
                    data: d
                };

                if(this.regState != ''){
                    send['state'] = this.regState
                }

                console.log('submitRegistartion.data: ', send)
                if(!holdSubmit)
                    this.$authapi.registerStep(send).then(this.onResponse).catch(this.onError);            
            },  
            onResponse(r){
                    console.log('onResponse', r.data);

                    //Check for an error
                    if(r.data.errors != undefined){
                        this.error = r.data.errors[0].detail
                    }

                    this.regState = r.data.state

                    switch(r.data.step){
                        case 'verify':
                            this.step = 'verify'
                            for(var key in r.data.fields){                             
                                let f = r.data.fields[key]
                                this.enabledFields[f.name] = f.required
                            }
                            break;
                        
                        case 'user':
                            this.step = 'user';
                            for(var ii = 0; ii < r.data.fields.length; ii++){                                
                                let f = r.data.fields[ii]
                                this.enabledFields[f.name] = f.required
                                
                                //Load password requirements
                                if(f.name == 'password')
                                    this.requirements = f.requirements
                            }
                            break;

                        case 'questions':
                            this.step = 'questions';
                            for(var ii = 0; ii < r.data.fields.length; ii++){                                
                                let f = r.data.fields[ii]
                                this.enabledFields[f.name] = f.required

                                //Load used questions
                                if(f.name == 'question0'){
                                    this.questions0 = []
                                    for(var key in f.questions){
                                        this.questions0.push({ label: f.questions[key], value: key})
                                    }
                                }
                                else if(f.name == 'question1'){
                                    this.questions1 = []
                                    for(var key in f.questions){
                                        this.questions1.push({ label: f.questions[key], value: key})
                                    }
                                }
                                else if(f.name == 'question2'){
                                    this.questions2 = []
                                    for(var key in f.questions){
                                        this.questions2.push({ label: f.questions[key], value: key})
                                    }
                                }

                            }
                            break;

                        case 'options':
                            this.step = 'options';
                            for(var ii = 0; ii < r.data.fields.length; ii++){                                
                                let f = r.data.fields[ii]
                                this.enabledFields[f.name] = f.required
                            }
                            break;

                            
                        case 'done':
                            this.step = 'done';
                            break;
                    }
            }, 
            onError(r){
                    console.log('onError', r);
                    this.error = r
            }, 
            /* Returns true if val has an '@' before a '.' */
            isValidEmail(val){
                if(val == undefined || val == null)
                    return false
                let indexA = val.indexOf('@')
                let indexB = val.lastIndexOf('.')
                if(indexA === -1 || indexB === -1 || indexB < indexA)
                    return false
                return true;
            },    
            /* Returns true if a 10 digit number entered */
            isValidPhone(val){
                if(val == undefined || val == null)
                    return false;
                if(isNaN(parseInt(val)) || val.length < 10){
                    return false;
                }
                return true;
            },
            verifyCurrentStep(){
                this.error = "";
                
                switch(this.step){
                    //Account Validation
                    case 'verify':
                        //Check Account Number
                        if(this.fv['acctNum'].value.length == 0)
                            this.error="Account Number must be entered"        
                        //Check SSN
                        else if(this.fv['ssn'].value.length < 9)
                            this.error="A 9 digit SSN is required"        
                        //Check DOB
                        else if(this.fv['acctType'].value == 'personal' && (this.fv['dobMonth'].value == '00'||this.fv['dobDate'].value == '00'||this.fv['dobYear'].value == '00'))
                            this.error="A valid date of birth is required"                        
                        else
                            this.submitRegistration()                       
                        break;
                        
                    //Account Info
                    case 'user':
                        //Check Username
                        if(this.fv['username'].value.length < 6 || this.fv['username'].value.length > 20)
                            this.error="Username must be 6 to 20 characters long"    
                        else if(this.fv['username'].value.indexOf('@') != -1)
                            this.error="Username cannot contain @, you cannot use your email as your username."        
                        //Check Email Address
                        else if(!this.isValidEmail(this.fv['email'].value)){
                            this.error="A valid email address is required"
                        }
                        //Check verify email
                        else if(this.fv['email'].value != this.fv['email2'].value){
                            this.error="Email addresses must match"
                        }
                        //Check Password
                        else if(!this.passwordChecker.isValidPass()){
                            this.error="A valid password is required"
                        }
                        else{
                            this.submitRegistration()  
                        }
                        break;
                    
                    //Account Options
                    case 'options':                        
                        this.submitRegistration() 
                        break;

                    //Account Security
                    case 'questions':

                        //Convert to lowercase for saving/comparing
                        let ans1 = this.fv['sa0'].value.toLowerCase();
                        let ans2 = this.fv['sa1'].value.toLowerCase();
                        let ans0 = this.fv['sa2'].value.toLowerCase();

                        //Make sure all questions selected
                        if(this.fv['sq0'].value == '' || this.fv['sq1'].value =='' || this.fv['sq2'].value == '' ){
                            this.error="Security questions must be selected."
                        }
                        //Make sure the same question isn't used twice
                        else if(this.fv['sq1'].value == this.fv['sq2'].value || this.fv['sq1'].value == this.fv['sq0'].value || this.fv['sq2'].value == this.fv['sq0'].value ){
                            this.error="The same security question cannot be used twice."
                        }
                        //Make sure all answeres entered
                        else if(ans1 == '' || ans2 == '' || ans0 == '' ){
                            this.error="Security answers must be entered."
                        }
                        //Make sure the same answer isn't used twice
                        else if(ans1 == ans2 || ans1 == ans0 || ans2 == ans0 ){
                            this.error="The same security answer cannot be used twice."
                        }
                        else{           
                            this.submitRegistration() 
                        }
                        break;
                }
            }
        },
        setup(){

            const passwordChecker = ref(null)
            let store = useStore();
            let step = ref('tos');
            const regState = ref('')
            const externalTerms = ref(true)             //Link to terms instead of displaying them in page
            const showTermsAlert = ref(false)           //Show the alert that terms must be accepcted
            const estmts = ref(null)
            const notices = ref(null)
            const requirements = ref({})
            const questions0 = ref()
            const questions1 = ref()
            const questions2 = ref()

            const enabledFields = ref({})

            const error = ref("")


            const terms = ref([                         //Terms displayed on the opening registration screen
                {header: 'Term Header A', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'},
                {header: 'Term Header B', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'},
                {header: 'Term Header C', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'},
                {header: 'Term Header D', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'},
                //{header: 'Term Header E', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'},
                //{header: 'Term Header F', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl elit, maximus et neque vitae, ullamcorper eleifend mauris. Vivamus ipsum enim, interdum vitae iaculis sit amet, blandit ac lacus. Nulla gravida nisi lacus, non faucibus nisl viverra et. Vivamus posuere, felis at tristique fringilla, leo dolor imperdiet nibh, at sodales turpis odio id mauris.'}
            ])

            /* Form field values */
            let fv = {}
            fv["acctNum"] = ref("")
            fv["acctType"] = ref("personal")
            fv["ssn"] = ref("")
            fv["dob"] = ref("")
            fv["addr"] = ref("")
            fv["city"] = ref("")
            fv["state"] = ref("")
            fv["zip"] = ref("")
            fv["username"] = ref("")
            fv["pass1"] = ref("")
            fv["pass2"] = ref("")
            fv["email"] = ref("")
            fv["email2"] = ref("")
            fv["phone"] = ref("")
            fv["sq0"] = ref("")
            fv["sa0"] = ref("")
            fv["sq1"] = ref("")
            fv["sa1"] = ref("")
            fv["sq2"] = ref("")
            fv["sa2"] = ref("")
            fv["accept"] = ref("N")
            fv["dobMonth"] = ref('00')
            fv["dobDate"] = ref('00')
            fv["dobYear"] = ref('00')

                
            return{
                error,
                fv,
                enabledFields,
                estmts,
                externalTerms,
                notices,
                passwordChecker,
                questions0,
                questions1,
                questions2,
                regState,
                requirements,
                showTermsAlert,
                step,
                store,
                terms
            }    
        }
    }
</script>
