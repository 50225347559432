<template>

    <div class="w-full flex justify-end text-right">
        <div class="fixed flex flex-row justify-end mn3-content rounded border z-50 top-2">
            <div class="mn3-button mn3-submit px-2 py-1 rounded-l uppercase cursor-pointer">Save</div>
            <div @click="returnToFiList" class="mn3-button mn3-cancel px-2 py-1 rounded-r uppercase cursor-pointer">Cancel</div>
        </div>
    </div>

    <div class="w-full mx-auto w-full flex mt-5">
        <div class="w-1/2 pr-4">
        <div class="text-xl font-bold mn3-body-text">FI Color Customization</div>
        <div class="w-full flex justify-between text-xs">
            <button
                v-for="mode in editModes" 
                :key="mode"
                :value="mode" 
                @click="editMode = mode"
                :class="`${(editMode == mode ? 'mn3-focused' : 'mn3-button')} border p-1 first:rounded-l mb-2 flex items-center flex-grow`">
                    <Icon class="h-5 w-5" :id="mode" />{{mode.toUpperCase()}}
            </button>
        </div>
            <button
                value="premade themes" 
                @click="editMode = 'theme'"
                :class="`${(editMode == 'theme' ? 'mn3-focused' : 'mn3-button')} border p-1 rounded-sm mr-1 mb-2 flex items-center`">
                    <Icon class="h-5 w-5" id="theme" />Premade Themes
            </button>
    

        <Form :title="`${editMode} Colors`"
            submitText="Update FI"
            :showCancel="true"
            :isLite="true"
						@submit="onSubmit"
            class="w-full mt-4">
            <div v-if="editMode == 'theme'">                
                <Field 
                    type="select"
                    label="Theme Preset"
                    :formValue="fv.themeSelect"
                    @change="loadTheme()"
                    :options="themeOptions"
                />
            </div>
            <div 
                v-if="editMode == 'navbar'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Navbar Colors</p>
                <div class="flex flex-wrap">
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.navbarBgColor"/>    

                    <Field 
                        class="w-1/2"
                        label="Hover BG" 
                        type="color" 
                        :formValue="fv.navbarBgHoverColor"/>

                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.navbarTextColor"/>                    

                    <Field 
                        class="w-1/2"
                        label="Hover Text" 
                        type="color" 
                        :formValue="fv.navbarTextHoverColor"/>
                        
                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.navbarBorderColor"/>
                        
                    <Field 
                        class="w-1/2"
                        label="Logo BG" 
                        type="color" 
                        :formValue="fv.navbarLogoBgColor"/>
                </div>
            </div>    
            
            <div 
                v-if="editMode == 'body'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Body BG</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="Fade-From" 
                        type="color" 
                        :formValue="fv.bodyBgTopColor"/>

                    <Field 
                        class="w-1/2"
                        label="Fade-To" 
                        type="color" 
                        :formValue="fv.bodyBgBottomColor"/>
                        
                        
                </div>
            </div>        
            
            <div 
                v-if="editMode == 'content'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Content Header Colors</p>
                <div class="flex flex-wrap">
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.contentHeaderBgColor"/>
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.contentHeaderTextColor"/>                        
                    <Field 
                        class="w-1/2"
                        label="Text Without BG" 
                        type="color" 
                        :formValue="fv.bodyTextColor"/>
                </div>
            </div>

            <div 
                v-if="editMode == 'content'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Content Colors</p>
                <div class="flex flex-wrap">
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.contentBgColor"/>
                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.contentBgHoverColor"/>
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.contentTextColor"/>
                    <Field 
                        class="w-1/2"
                        label="Text Hover" 
                        type="color" 
                        :formValue="fv.contentTextHoverColor"/>
                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.contentBorderColor"/>
                </div>
            </div>
            
            <div 
                v-if="editMode == 'content'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Higlighted Content Colors</p>
                <div class="flex flex-wrap">
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.contentHighlightBgColor"/>
                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.contentHighlightBgHoverColor"/>
                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.contentHighlightBorderColor"/>
                </div>
            </div>
            

            
            <div 
                v-if="editMode == 'button'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Standard Button Colors</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.buttonBgColor" />

                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.buttonBgHoverColor"/>       
                        
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.buttonTextColor"/> 

                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.buttonBorderColor"/>                        
                </div>
            </div>
            
            <div 
                v-if="editMode == 'button'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Focused Button Colors</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.buttonFocusedBgColor" />

                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.buttonFocusedBgHoverColor"/>       
                        
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.buttonFocusedTextColor"/> 

                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.buttonFocusedBorderColor"/>                        
                </div>
            </div>

            <div 
                v-if="editMode == 'submit'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Submit Button Colors</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.buttonSubmitBgColor" />

                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.buttonSubmitBgHoverColor"/>       
                        
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.buttonSubmitTextColor"/> 

                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.buttonSubmitBorderColor"/>                        
                </div>
            </div>

            
            <div 
                v-if="editMode == 'submit'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Cancel Button Colors</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.buttonCancelBgColor" />

                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.buttonCancelBgHoverColor"/>       
                        
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.buttonCancelTextColor"/> 

                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.buttonCancelBorderColor"/>                        
                </div>
            </div>
            
            <div 
                v-if="editMode == 'input'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Input Colors</p>
                <div class="flex flex-wrap">                        
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.inputBgColor" />

                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.inputBgHoverColor"/>       
                        
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.inputTextColor"/> 

                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.inputBorderColor"/>                        
                </div>
            </div>

            <div 
                v-if="editMode == 'input'"
                class="p-1 mb-2 border rounded-sm mn3-content">
                <p class="text-sm font-bold border-b">Disabled Colors</p>
                <div class="flex flex-wrap">
                    <Field 
                        class="w-1/2"
                        label="BG" 
                        type="color" 
                        :formValue="fv.inputDisabledBgColor"/>
                    <Field 
                        class="w-1/2"
                        label="BG Hover" 
                        type="color" 
                        :formValue="fv.bgDisabeldHover"/>
                    <Field 
                        class="w-1/2"
                        label="Text" 
                        type="color" 
                        :formValue="fv.inputDisabledTextColor"/>
                    <Field 
                        class="w-1/2"
                        label="Border" 
                        type="color" 
                        :formValue="fv.inputDisabledBorderColor"/>
                </div>
            </div>
            
            
        </Form>  
        
        </div>       
        <div class="w-1/2 border-black border h-full" 
            :style="updatedCustomCss">
            <!--navbar demo-->
            <TopBar :isDemo="true" />
            <div class="p-2" :style="bodyStyle">

                <!-- button demo -->
                <div class="mb-2 text-right">
                    <input type="button"
                        class="mn3-focused px-3 py-1 mr-1 rounded cursor-pointer shadow border text-xs font-bold uppercase"
                        value="Focused Button" />
                    <input type="button"
                        class="mn3-button px-3 py-1 mr-1  rounded cursor-pointer shadow border text-xs font-bold uppercase"
                        value="Button" />
                    <input type="button"
                        class="mn3-button px-3 py-1 mr-1 rounded cursor-pointer shadow border text-xs font-bold uppercase"
                        value="Button" />
                </div>

                <!--form demo-->
                <Form title="Form Demo"
                    :showCancel="true"
                    v-if="editMode == 'input' || editMode == 'submit' || editMode == 'content'||editMode == 'theme'"
                    fieldFlex="flex flex-row flex-wrap"
                    submitText="Demo Submit"
                    cancelText="Demo Cancel">
                    <Field class="w-full" :formValue="fv.demoTextA" label="Test Field 1" type="text" />
                    <Field class="w-full" :formValue="fv.demoSelect"  label="Test Field 2" type="select" :options="[{label:'OptionA', value: ''},{label:'OptionB', value: 'B'},{label:'OptionC', value: 'C'}]"/>
                    <Field class="w-full" :formValue="fv.demoTextB" label="Test Field 3" type="text" />
                    <Field class="w-1/2" :formValue="fv.demoToggleA" label="Toggle Test" type="toggle" />
                    <Field class="w-1/2" :formValue="fv.demoToggleB" label="Toggle Test" type="toggle"  />
                </Form>

                <Menu title="Content Header">
                    <MenuOption label="Highlighted Content" icon="phone" class="mn3-content-highlight" />
                    <MenuOption label="Content Demo" icon="phone" />
                    <MenuOption label="Content Demo2" icon="phone" />
                    <MenuOption label="Content Demo3" icon="phone" />
                    <MenuOption label="Content Demo4" icon="phone" />
                </Menu>

            </div>



        </div>
    </div>
</template>

<script>

    import { ref, computed } from 'vue'
    import { useRouter } from "vue-router"

    import Form from '@/components/Form.vue'
    import Icon from '@/components/Icon.vue'
    import Menu from '@/components/Menu.vue'
    import MenuOption from '@/components/MenuOption.vue'
    import Field from '@/components/Field.vue'
    import TopBar from '@/components/TopBar.vue'

    export default {
      name: 'FiColor',
      components: {
          Form,
          Field,
          Icon,
          Menu,
          MenuOption,
          TopBar
      },
      created(){
          document.title = 'FI Color Settings'
      },
			methods: {
				onSubmit(){
					//TODO: rename vars in JS to match API names?
					var attrs = {}
					//copy all values that end with Color
					for(var a in this.fv){
						if(!a.endsWith("Color")) continue;
						attrs[a] = this.fv[a].value;
					}
					var data = {"data": {"attributes": attrs}};
					this.$fapi.patch("theme", data).then(r=>{
						// update live theme
						this.$store.commit('setTheme', r.data.data.attributes);
					});
				},
				loadLiveTheme(){
					var t = this.$store.state.theme;
					for(var a in this.fv){
						if(!a.endsWith("Color")) continue;
						this.fv[a].value = t[a];
					}
				}
			},
			mounted(){
				this.loadLiveTheme();
			},
      setup(){

            const editMode = ref("theme");
            let editModes = ['navbar', 'body', 'content', 'input', 'button', 'submit'];            

            /* Form field values */
            let fv = {}

            fv["themeSelect"] = ref("themeLight")

            /* navbar */
            fv["navbarBgColor"] = ref("#")
            fv["navbarBgHoverColor"] = ref("#")
            fv["navbarTextColor"] = ref("#")
            fv["navbarTextHoverColor"] = ref("#")
            fv["navbarBorderColor"] = ref("#")
            fv["navbarLogoBgColor"] = ref("#")
            
            /* body */
            fv["bodyBgTopColor"] = ref("#")
            fv["bodyBgBottomColor"] = ref("#")
            fv["bodyTextColor"] = ref("#")

            /* content */
            fv["contentBgColor"] = ref("#")
            fv["contentBgHoverColor"] = ref("#")
            fv["contentTextColor"] = ref("#")
            fv["contentTextHoverColor"] = ref("#")
            fv["contentBorderColor"] = ref("#")
            
            /* highlighted content */
            fv["contentHighlightBgColor"] = ref("#")
            fv["contentHighlightBgHoverColor"] = ref("#")
            fv["contentHighlightBorderColor"] = ref("#")

            /* content header */
            fv["contentHeaderBgColor"] = ref("#")
            fv["contentHeaderTextColor"] = ref("#")     
            
            /* button */
            fv["buttonBgColor"] = ref("#")    
            fv["buttonBgHoverColor"] = ref("#")    
            fv["buttonTextColor"] = ref("#")      
            fv["buttonBorderColor"] = ref("#")

            /* submit */
            fv["buttonSubmitBgColor"] = ref("#")    
            fv["buttonSubmitBgHoverColor"] = ref("#")    
            fv["buttonSubmitTextColor"] = ref("#")      
            fv["buttonSubmitBorderColor"] = ref("#")

            /* cancel */
            fv["buttonCancelBgColor"] = ref("#")    
            fv["buttonCancelBgHoverColor"] = ref("#")    
            fv["buttonCancelTextColor"] = ref("#")      
            fv["buttonCancelBorderColor"] = ref("#")

            /* disabled */
            fv["inputDisabledBgColor"] = ref("#")    
            fv["inputDisabledBgHoverColor"] = ref("#")    
            fv["inputDisabledTextColor"] = ref("#")      
            fv["inputDisabledBorderColor"] = ref("#")

            /* focused */
            fv["buttonFocusedBgColor"] = ref("#")    
            fv["buttonFocusedBgHoverColor"] = ref("#")    
            fv["buttonFocusedTextColor"] = ref("#")      
            fv["buttonFocusedBorderColor"] = ref("#")

            /* input */
            fv["inputBgColor"] = ref("#")
            fv["inputBgHoverColor"] = ref("#")
            fv["inputTextColor"] = ref("#")
            fv["inputBorderColor"] = ref("#")

            /* Demo Values */
            fv["demoTextA"] = ref("")
            fv["demoTextB"] = ref("")
            fv["demoSelect"] = ref("")
            fv["demoToggleA"] = ref("Y")
            fv["demoToggleB"] = ref("N")
            

            const themeOptions = [
                {
                    label: '[Select Theme]',
                    value: ''
                },
                {
                    label: 'Dark',
                    value: 'themeDark'
                },
                {
                    label: 'Light',
                    value: 'themeLight'
                }
            ]

            const themes = {
                themeLight: {
                    //navbar
                    navbarBgColor: '#4ac97f',
                    navbarTextColor: '#eeeeee',
                    navbarBgHoverColor: '#427055',
                    navbarTextHoverColor: '#ffffff',
                    navbarBorderColor: '#59a177',
                    navbarLogoBgColor: '#4ac97f',
                    //body
                    bodyBgTopColor: '#fffdfd',
                    bodyBgBottomColor: '#ffefef',
                    //content
                    contentBgColor: '#fafafa',
                    contentBgHoverColor: '#fffee5',
                    contentTextColor: '#333333',
                    contentTextHoverColor: '#333333',
                    contentBorderColor: '#f2f2f2',
                    bodyTextColor: '#666666',                    
                    //content highlighted
                    contentHighlightBgColor: '#f5f6df',
                    contentHighlightBgHoverColor: '#f1f2c0',
                    contentHighlightBorderColor: '#d5d4b8',
                    //content header
                    contentHeaderBgColor: '#cccccc',
                    contentHeaderTextColor: '#222222',
                    //buttons
                    buttonBgColor: '#aaaaaa',
                    buttonBgHoverColor: '#cccccc',
                    buttonTextColor: '#ffffff',
                    buttonBorderColor: '#999999',
                    //submit
                    buttonSubmitBgColor: '#33dd33',
                    buttonSubmitBgHoverColor: '#33ee33',
                    buttonSubmitTextColor: '#ffffff',
                    buttonSubmitBorderColor: '#ccffcc',
                    //cancel
                    buttonCancelBgColor: '#dd3333',
                    buttonCancelBgHoverColor: '#f25454',
                    buttonCancelTextColor: '#ffffff',
                    buttonCancelBorderColor: '#ffcccc',
                    //disabled
                    inputDisabledBgColor: '#cccccc',
                    inputDisabledBgHoverColor: '#dddddd',
                    inputDisabledTextColor: '#666666',
                    inputDisabledBorderColor: '#333333',
                    //focused
                    buttonFocusedBgColor: '#4ac97f',
                    buttonFocusedBgHoverColor: '#dd3333',
                    buttonFocusedTextColor: '#ffffff',
                    buttonFocusedBorderColor: '#4ac97f',
                    //input
                    inputBgColor: '#fefefe',
                    inputBgHoverColor: '#ffffff',
                    inputTextColor: '#883333',
                    inputBorderColor: '#883333'
                },
                themeDark : {
                    //navbar
                    navbarBgColor: '#222222',
                    navbarBgHoverColor: '#dbdbdb',
                    navbarTextColor: '#f5f5f5',
                    navbarTextHoverColor: '#2e2e2e',
                    navbarBorderColor: '#262626',
                    navbarLogoBgColor: '#eeeeee',
                    //body
                    bodyBgTopColor: '#303030',
                    bodyBgBottomColor: '#2b2b2b',
                    //content
                    contentBgColor: '#4d4d4d',
                    contentBgHoverColor: '#616161',
                    contentTextColor: '#eaeaea',
                    contentTextHoverColor: '#ffffff',
                    contentBorderColor: '#212121',                
                    //content highlighted
                    contentHighlightBgColor: '#4d4d4d',
                    contentHighlightBgHoverColor: '#616161',
                    contentHighlightBorderColor: '#212121',
                    //content header
                    contentHeaderBgColor: '#383838',
                    contentHeaderTextColor: '#fafafa',
                    bodyTextColor: '#fafafa',
                    //buttons
                    buttonBgColor: '#dbdbdb',
                    buttonBgHoverColor: '#cccccc',
                    buttonTextColor: '#2e2e2e',
                    buttonBorderColor: '#262626',
                    //submit
                    buttonSubmitBgColor: '#4f8751',
                    buttonSubmitBgHoverColor: '#6db670',
                    buttonSubmitTextColor: '#e5ffe5',
                    buttonSubmitBorderColor: '#ccffcc',
                    //cancel
                    buttonCancelBgColor: '#874f4f',
                    buttonCancelBgHoverColor: '#b66d6d',
                    buttonCancelTextColor: '#ffe5e5',
                    buttonCancelBorderColor: '#ffcccc',
                    //disabled
                    inputDisabledBgColor: '#333333',
                    inputDisabledBgHoverColor: '#dddddd',
                    inputDisabledTextColor: '#ababab',
                    inputDisabledBorderColor: '#333333',
                    //focused
                    buttonFocusedBgColor: '#6e7e8c',
                    buttonFocusedBgHoverColor: '#8a9dad',
                    buttonFocusedTextColor: '#ffffff',
                    buttonFocusedBorderColor: '#d6d6d6',
                    //input
                    inputBgColor: '#4d4d4d',
                    inputBgHoverColor: '#707070',
                    inputTextColor: '#fafafa',
                    inputBorderColor: '#aaaaaa'
                }
            }

            function loadTheme(){     
                let theme = themes[fv["themeSelect"].value] 
                 
                for (var key in theme) {
                    fv[key].value = theme[key];
                }
            }

            /* Updated CSS var decs to apply to the demo area */
            const updatedCustomCss = computed({
                get:() =>{
                 return {
                     /* navbar */
                    '--bg-navbar': fv["navbarBgColor"].value,
                    '--text-navbar': fv["navbarTextColor"].value,
                    '--bg-navbar-hover': fv["navbarBgHoverColor"].value,
                    '--text-navbar-hover': fv["navbarTextHoverColor"].value,
                    '--border-navbar': fv["navbarBorderColor"].value,
                    '--bg-logo': fv["navbarLogoBgColor"].value,

                    /* body */
                    '--bg-fade-from': fv["bodyBgTopColor"].value,
                    '--bg-fade-to': fv["bodyBgBottomColor"].value,
                    '--body-text': fv["bodyTextColor"].value,

                    /* content */
                    '--bg-content': fv["contentBgColor"].value,
                    '--bg-content-hover': fv["contentBgHoverColor"].value,
                    '--text-content': fv["contentTextColor"].value,
                    '--text-content-hover': fv["contentTextHoverColor"].value,
                    '--border-content': fv["contentBorderColor"].value,
                    
                    /* content highlight */
                    '--bg-content-highlight': fv["contentHighlightBgColor"].value,
                    '--bg-content-highlight-hover': fv["contentHighlightBgHoverColor"].value,
                    '--border-content-highlight': fv["contentHighlightBorderColor"].value,

                    /* content header */
                    '--bg-content-header': fv["contentHeaderBgColor"].value,
                    '--text-content-header': fv["contentHeaderTextColor"].value,

                    /* button */
                    '--bg-button': fv["buttonBgColor"].value,
                    '--bg-button-hover': fv["buttonBgHoverColor"].value,
                    '--text-button': fv["buttonTextColor"].value,
                    '--border-button': fv["buttonBorderColor"].value,

                    /* submit */
                    '--bg-submit': fv["buttonSubmitBgColor"].value,
                    '--bg-submit-hover': fv["buttonSubmitBgHoverColor"].value,
                    '--text-submit': fv["buttonSubmitTextColor"].value,
                    '--border-submit': fv["buttonSubmitBorderColor"].value,
                    
                    /* cancel */
                    '--bg-cancel': fv["buttonCancelBgColor"].value,
                    '--bg-cancel-hover': fv["buttonCancelBgHoverColor"].value,
                    '--text-cancel': fv["buttonCancelTextColor"].value,
                    '--border-cancel': fv["buttonCancelBorderColor"].value,

                    /* disabled */
                    '--bg-disabled': fv["inputDisabledBgColor"].value,
                    '--bg-disabled-hover': fv["inputDisabledBgHoverColor"].value,
                    '--text-disabled': fv["inputDisabledTextColor"].value,
                    '--border-disabled': fv["inputDisabledBorderColor"].value,
                    
                    /* focused */
                    '--bg-focused': fv["buttonFocusedBgColor"].value,
                    '--bg-focused-hover': fv["buttonFocusedBgHoverColor"].value,
                    '--text-focused': fv["buttonFocusedTextColor"].value,
                    '--border-focused': fv["buttonFocusedBorderColor"].value,

                    /* input */
                    '--bg-input': fv["inputBgColor"].value,
                    '--bg-input-hover': fv["inputBgHoverColor"].value,
                    '--text-input': fv["inputTextColor"].value,
                    '--border-input': fv["inputBorderColor"].value


                 }
                }
            });

            const bodyStyle = computed({          
                get: () => {                    
                    return `
	                    background-image: linear-gradient(${fv.bodyBgTopColor.value}, ${fv.bodyBgBottomColor.value})
                        `                    
                }
            });
            
            const navbarOptionClasses = ' font-bold text-sm px-3 hover:relative hover:bottom-1 cursor-pointer h-16 pt-6 hover:pt-4 transition duration-150 ease-in-out leading-4';
            const contentClass = ' border-b p-2';
            const router = useRouter();
            
            function returnToFiList(){
                router.push('filist');
            }
        return{
            editMode,
            editModes,
            fv,
            loadTheme,
            router,
            navbarOptionClasses,
            contentClass,
            bodyStyle,
            updatedCustomCss,
            themeOptions,
            returnToFiList
            
        }    
      }
    }
</script>
