<template>
    <div class="w-full flex flex-col lg:flex-row justify-between">
        <div class="flex flex-col w-full lg:w-1/2">

            <Form title="MN Admin Settings"
                submitText="Update Contact Info">
                <Field type="text" 
                    label="Credit Union Name" />
                <Field type="select"
                    label="Country"
                    :options="[]"
                />
                <Field type="text" 
                    label="Address" />
                <Field type="text" 
                    label="City" />
                <Field type="text" 
                    label="State" />
                <Field type="text" 
                    label="Zip Code" />
                <Field type="text" 
                    label="Phone Number" />
                <Field type="text" 
                    label="Hompage URL" />
            </Form>
        </div>
        <div class="flex flex-col w-full lg:w-1/2">
             <Form title="Member Alert Message"
                submitText="Update">
                <Field type="textarea" />
            </Form>
            
            <Form title="Restrict Transfers from Certain Suffixes"
                submitText="Restrict Suffix">
                <Field type="textarea" />
                <Field type="select"
                    :options="[]"
                />
                <Field type="toggle" label="Allow Deposits" />
            </Form>
            
        </div>
            


		
	</div>
</template>

<script>
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
    import { ref } from 'vue'
    import { useRouter } from "vue-router"

	export default {
		components:{
			Form,
			Field
		},
		data(){
			return {
			};
		},

		created(){
		}, 

        setup(){
            

			/* Form field values */
			let fv = {}

            return { 
				fv
            }

        }
	}
	
</script>
